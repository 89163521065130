import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import { changeHypertextProtocol, getUserName } from "../Doc/Service/docHelper"
import "../../../components/Drive/Doc/customDoc.css"
import AxiosInstance from "../../../services/axiosInstance"
import { installationFormat } from "../Service/driveListHelper"

const InstallationOptions = ({
  ownerId,
  driveId,
  previewUrl,
  compressUrl,
  publicBaseURLSelection,
  slug,
  showInstallationPanel,
  type,
}) => {
  const [customUrlSwitch, setCustomUrlSwitch] = useState(compressUrl),
    [customUrlLoad, setCustomUrlLoad] = useState(true),
    [updateCustomUrl, setUpdateCustomUrl] = useState(false),
    [customUrl, setCustomUrl] = useState([]),
    [customUrlHost, setCustomUrlHost] = useState(process.env.GATSBY_PREVIEW_URL),
    [customLinkOption, setCustomLinkOption] = useState(
      `${changeHypertextProtocol(process.env.GATSBY_PREVIEW_URL)}`
    ),
    [customLink, setCustomLink] = useState(previewUrl),
    [urlCopy, setUrlCopy] = useState("Copy"),
    [urlId, setUrlId] = useState(slug),
    [slugErrorMessage, setSlugErrorMessage] = useState("")

  useEffect(() => {
    setCustomUrlSwitch(compressUrl)
    setCustomLinkOption(publicBaseURLSelection)
    setUrlId(slug)
    if (publicBaseURLSelection != null) {
      setCustomUrlHost(publicBaseURLSelection)
      setUpdateCustomUrl(true)
      handleUrl()
    } else {
      setCustomLink(previewUrl)
    }
  }, [compressUrl, publicBaseURLSelection, slug])

  useEffect(() => {
    if (customUrlSwitch === true && customUrlLoad === true) {
      setCustomUrlLoad(false)
      if (publicBaseURLSelection == "" || publicBaseURLSelection == null) {
        setCustomUrlHost(changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}`))
        setUpdateCustomUrl(true)
        handleUrl()
      }
      AxiosInstance.get("/settings/general-setting/unique-data")
        .then(response => {
          setCustomUrl(response.data.uniqueData)
        })
        .catch(error => {
          console.log(error)
        })
    }

    if (updateCustomUrl) {
      setUpdateCustomUrl(false)
      handleUrl()
    }
  })

  const handleUrl = () => {
    if (customUrlHost === changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}`)) {
      setCustomLink(`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/${urlId}`)
    } else {
      setCustomLink(`${customUrlHost}/${urlId}`)
    }
    setCustomLinkOption(customUrlHost)
  }

  const handleUrlId = () => {
    if (urlId != "" && urlId != null) {
      AxiosInstance.post(`/drive/save-slug/${driveId}`, {
        slug: urlId,
      })
        .then(response => {
          if (response.data.status == false) {
            setSlugErrorMessage(response.data.message)
          } else {
            setSlugErrorMessage("")
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <div className={`col-lg-12 mb-3${!showInstallationPanel ? " d-none" : ""}`} id="installation_panel">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="panel panel-beige">
          <Accordion.Header data-bs-target="#installation_container" aria-controls="installation_container">
            <span className="panel-heading-icon">
              <i className="bi bi-megaphone-fill" />
            </span>
            <span className="panel-heading-title">{installationFormat(type, ownerId, driveId)}</span>
          </Accordion.Header>
          <Accordion.Body id="installation_container">
            <strong>
              Link your website to your {type}{" "}
              <span>
                <OverlayTrigger placement="top" overlay={<Tooltip>Link your website to your {type}</Tooltip>}>
                  <i className="bi bi-question-circle-fill" />
                </OverlayTrigger>
              </span>
            </strong>

            <div className="customURLContainer py-5 text-center">
              <div className={`IO_OriginalURL d-inline-block${customUrlSwitch ? " d-none" : ""}`}>
                <a className="dashedLinked" href={previewUrl} target="_blank">
                  {previewUrl}
                </a>

                <a href={previewUrl} target="_blank" className="btnIOPreviewCustomURLView IO_New_URL">
                  <span>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Preview</Tooltip>}>
                      <i className="bi bi-box-arrow-up-right" />
                    </OverlayTrigger>
                  </span>
                </a>
                <button
                  type="button"
                  className="btnIOCopyCustomURL"
                  data-bs-toggle="tooltip"
                  onClick={() => {
                    navigator.clipboard.writeText(previewUrl)
                    setUrlCopy("Copied")
                    setTimeout(() => {
                      setUrlCopy("Copy")
                    }, 1200)
                  }}
                >
                  <span>
                    <OverlayTrigger placement="top" overlay={<Tooltip>{urlCopy}</Tooltip>}>
                      <i className="bi bi-files" />
                    </OverlayTrigger>
                  </span>
                </button>

                <input type="text" id="btnIOPreviewDocURL" defaultValue={previewUrl} />
              </div>
              <div className={`IO_url_edit${!customUrlSwitch ? " d-none" : ""}`}>
                <div className="alert alert-danger mb-3 display-none emptyWebsiteHashError">
                  <i className="bi bi-exclamation-triangle-fill" /> Heads up! This will save as your
                  mapped-domain root view.
                </div>
                <div className="input-group mb-1">
                  <span className="input-group-text">https://</span>
                  <select
                    name="public_base_url_selection"
                    id="publicBaseURLSelection"
                    className="form-select"
                    value={changeHypertextProtocol(customLinkOption)}
                    onChange={e => {
                      setCustomUrlHost(e.target.value)
                      setUpdateCustomUrl(true)
                      handleUrl()
                    }}
                    key={customLinkOption}
                  >
                    <option
                      value={changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}`)}
                    >
                      {" "}
                      {changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}`)}{" "}
                    </option>
                    {customUrl.length > 0 &&
                      customUrl
                        .filter(url => url.type == "URLMappedTo")
                        .map((urlList, indexUrl) => {
                          return (
                            <option key={indexUrl} value={changeHypertextProtocol(urlList.value)}>
                              {changeHypertextProtocol(urlList.value)}
                            </option>
                          )
                        })}
                  </select>
                  <span className="input-group-text">/</span>
                  <span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Change to anything you like. Must be unique.</Tooltip>}
                    >
                      <input
                        type="text"
                        name="link_your_website_hash"
                        id="link_your_website_hash"
                        className="form-control"
                        defaultValue={urlId}
                        onChange={e => {
                          setUrlId(e.target.value)
                          setUpdateCustomUrl(true)
                          handleUrl()
                        }}
                        onBlur={handleUrlId}
                        data-bs-toggle="tooltip"
                      />
                    </OverlayTrigger>
                  </span>
                </div>
                <a
                  href={`https://${changeHypertextProtocol(customLink)}`}
                  target="_blank"
                  className="btnIOPreviewCustomURLText IO_New_URL dashedLinked fs-5"
                >
                  {`https://${changeHypertextProtocol(customLink)}`}
                </a>
                <a
                  href={`https://${changeHypertextProtocol(customLink)}`}
                  target="_blank"
                  className="btnIOPreviewCustomURLView IO_New_URL"
                  data-bs-toggle="tooltip"
                >
                  <span>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Preview</Tooltip>}>
                      <i className="bi bi-box-arrow-up-right" />
                    </OverlayTrigger>
                  </span>
                </a>
                <button
                  type="button"
                  className="btnIOCopyCustomURL"
                  data-bs-toggle="tooltip"
                  onClick={() => {
                    navigator.clipboard.writeText(`https://${changeHypertextProtocol(customLink)}`)
                    setUrlCopy("Copied")
                    setTimeout(() => {
                      setUrlCopy("Copy")
                    }, 1200)
                  }}
                >
                  <span>
                    <OverlayTrigger placement="top" overlay={<Tooltip>{urlCopy}</Tooltip>}>
                      <i className="bi bi-files" />
                    </OverlayTrigger>
                  </span>
                </button>
                <input
                  type="text"
                  id="btnIOPreviewCustomURLCopy"
                  defaultValue="https://portal.swiftcrm.com/username/doc/{DocId}"
                />
              </div>
              <div className="d-inline-block ms-2 compressUrlBox">
                <div className="toggleSwitch">
                  <input
                    type="checkbox"
                    value={customUrlSwitch}
                    name="compressUrl"
                    id="compressUrl"
                    checked={customUrlSwitch}
                    onChange={e => {
                      if (customUrlSwitch == true) {
                        setCustomUrlSwitch(false)
                        // setUrlId(slug)
                      } else {
                        setCustomUrlSwitch(true)
                        setCustomUrlLoad(true)
                      }
                      handleUrl()
                    }}
                  />
                  <label>Custom URL?</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 pt-5 IOShareContainer">
                <div className="colHeading">
                  <strong>
                    <i className="bi bi-share-fill" /> Share
                  </strong>
                </div>
                <div className="dh-block-body">
                  <div className="row">
                    <div className="col-lg-12 text-center shareAction colAction">
                      <ul>
                        <li className="black-bg">
                          <a href="#" className="btnGlobalURLQRCode" data-bs-toggle="tooltip">
                            <span>
                              <OverlayTrigger placement="top" overlay={<Tooltip>Generate QR Code</Tooltip>}>
                                <i className="bi bi-qr-code" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>
                        <li className="paper-plane-bg">
                          <a
                            data-bs-toggle="tooltip"
                            href="mailto:?subject=You would like this&amp;body=Hello%0A%0AYou%20will%20like%20this%3A%0Ahttps://click.SwiftCRM.com/username/AutoGenButAllowInputOverride"
                            target="_blank"
                          >
                            <span>
                              <OverlayTrigger placement="top" overlay={<Tooltip>Email This Page</Tooltip>}>
                                <i className="bi bi-envelope-fill" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>
                        <li className="fb-bg">
                          <a
                            data-bs-toggle="tooltip"
                            href="https://www.facebook.com/sharer/sharer.php?u=https://click.SwiftCRM.com/username/AutoGenButAllowInputOverride"
                            target="_blank"
                          >
                            <span>
                              <OverlayTrigger placement="top" overlay={<Tooltip>Share On Facebook</Tooltip>}>
                                <i className="bi bi-facebook" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>
                        <li className="twitter-bg">
                          <a
                            data-bs-toggle="tooltip"
                            href="https://twitter.com/share?url=https://click.SwiftCRM.com/username/AutoGenButAllowInputOverride&amp;text=Hello%0A%0AYou%20will%20like%20this%3A%0A"
                            target="_blank"
                          >
                            <span>
                              <OverlayTrigger placement="top" overlay={<Tooltip>Tweet This Page</Tooltip>}>
                                <i className="bi bi-twitter-x" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>
                        <li className="pinterest-bg">
                          <a
                            data-bs-toggle="tooltip"
                            href="https://www.pinterest.com/pin/create/button/?url=https://click.SwiftCRM.com/username/AutoGenButAllowInputOverride&amp;media=https://swiftcrm.com/images/user_/"
                            target="_blank"
                          >
                            <span>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Share This Page on Pinterest</Tooltip>}
                              >
                                <i className="bi bi-pinterest" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>

                        <li className="instgram-bg">
                          <a href="https://www.Instagram.com/" target="_blank">
                            <span>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Share This Page on Instagram</Tooltip>}
                              >
                                <i className="bi bi-instagram" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>

                        <li className="thread-bg">
                          <a href="https://www.threads.net/" target="_blank">
                            <span>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Share This Page on Thread</Tooltip>}
                              >
                                <i className="bi bi-threads" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>

                        <li className="linkedin-bg">
                          <a
                            data-bs-toggle="tooltip"
                            href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://click.SwiftCRM.com/username/AutoGenButAllowInputOverride&amp;title=You%20will%20like%20this&amp;summary=Hello%0A%0AYou%20will%20like%20this%3A%0A%0A"
                            target="_blank"
                          >
                            <span>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Share This Page on LinkedIn</Tooltip>}
                              >
                                <i className="bi bi-linkedin" />
                              </OverlayTrigger>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 pt-3 GetCodeContainer d-none">
                <div className="colHeading">
                  <strong>
                    <i className="bi bi-code-slash" /> Get Code
                  </strong>
                </div>
                <div className="dh-block-body">
                  {/*<textarea id="promoteDesc" cols="30" rows="4"*/}
                  {/*          name="promoteDesc" className="form-control">e-Sign this doc at &lt;a href="https://portal.swiftcrm.com/username/{driveType}/{driveId}" target="_new"&gt;SwiftCloud&lt;/a&gt;</textarea>*/}
                </div>
              </div>

              <div className="col-lg-12 pt-3 GetEmailCodeContainer d-none">
                <div className="colHeading">
                  <strong>
                    <i className="bi bi-envelope-fill" /> Email code
                  </strong>
                </div>
                <div className="dh-block-body">
                  <div className="mb-2 row">
                    <label htmlFor="staticEmail" className="col-sm-3 col-form-label">
                      Email
                    </label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="mb-2 row">
                    <label htmlFor="textarea" className="col-sm-3 col-form-label">
                      Comment
                    </label>
                    <div className="col-sm-9">
                      {/*<textarea rows="5"
                        placeholder="I've generated this form on SwiftCRM.com - please install to my website. Directions are linked below - thanks!"
                        className="form-control GetEmailCodeComment">The asset is at
                        https://portal.swiftcrm.com/username/doc/{selectedDocId}
                        Helpful installation options and a guide are at
                        https://crm.swiftcrm.com/support/install</textarea>*/}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-secondary">
                        <i className="bi bi-send-fill" /> Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default InstallationOptions
