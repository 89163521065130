import React, { useEffect, useRef, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"
import Header from "@editorjs/header"
import List from "@editorjs/list"
import Layout from "../../../components/Layout/layout"
import Seo from "../../seo"
import "bootstrap-tagsinput/src/bootstrap-tagsinput.css"
import "bootstrap-select/dist/css/bootstrap-select.css"
import "../../../css/datepicker.css"
import "../../../css/bootcomplete.css"
import "../../../css/swiftcloud_global.css"
import "../../../css/fileinput.css"
import "../../../css/chat-dock.css"
import "../../../css/edocs.css"
import { Accordion, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import UserExperienceFlow from "./UserExperienceFlow"
import DataHandlingAutomation from "./DataHandlingAutomation"
import InstallationOptions from "./InstallationOptions"
import MoreOptions from "./MoreOptions"
import {
  setAdditionalOptionsArray,
  setAutomationOptionsArray,
  getUserName,
  changeHypertextProtocol,
} from "./Service/docHelper"
import { Link } from "gatsby"
import AxiosInstance from "../../../services/axiosInstance"
import { getSwitchUserEmail } from "../../../services/auth"
import phone from "./CustomEditorPlugin/Phone.js"
import editorJsHTML from "editorjs-html"
import PanelToggle from "../Item/panelToggle.js"
import ShareContactDetailModal from "./ShareContactDetailModal.js"

const Detail = ({ selectedDocId }) => {
  const editorJs = useRef()

  const [loadDocDetails, setLoadDocDetails] = useState(true),
    [content, setContent] = useState(""),
    [docData, setDocData] = useState(""),
    [driveId, serDriveId] = useState(""),
    [driveType, setDriveType] = useState(""),
    [docName, setDocName] = useState(""),
    [ownerId, setOwnerId] = useState(""),
    [parentDoc, setParentDoc] = useState(""),
    [fileUrl, setFileUrl] = useState(""),
    [forwardUrl, setForwardUrl] = useState(""),
    [otherOption, setOtherOption] = useState(""),
    [selectedOption, setSelectedOption] = useState(""),
    [inPersonMode, setInPersonMode] = useState(""),
    [forwardDomain, setForwardDomain] = useState(""),
    [additionalOptions, setAdditionalOptions] = useState([]),
    [inPersonModeFileType, setInPersonModeFileType] = useState(""),
    [inPersonModeFileId, setInPersonModeFileId] = useState(""),
    [inPersonModeFileURL, setInPersonModeFileURL] = useState(""),
    [onPageDownloadFile, setOnPageDownloadFile] = useState(""),
    [toECommerceItem, setToECommerceItem] = useState(""),
    [DHAtuomationData, setDHAtuomationData] = useState([]),
    [initialAutomationOption, setInitialAutomationOption] = useState(""),
    [initialIncomingOpt, setInitialIncomingOpt] = useState(""),
    [initialFolder, setInitialFolder] = useState(""),
    [initialAsA, setInitialAsA] = useState(""),
    [docType, setDocType] = useState(""),
    [esignMode, setEsignMode] = useState(""),
    [pdfGeneration, setPDFGeneration] = useState(""),
    [signAuth, setSignAuth] = useState(""),
    [docPrivacy, setDocPrivacy] = useState(""),
    [whoCanModify, setWhoCanModify] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [displayInFeedTag, setDisplayInFeedTag] = useState([]),
    [displayInFeedTempTags, setDisplayInFeedTempTags] = useState([]),
    [contactTag, setContactTag] = useState([]),
    [contactTempTags, setContactTempTags] = useState([]),
    [privacyPassword, setPrivacyPassword] = useState(""),
    [whoCanCopy, setWhoCanCopy] = useState(""),
    [copyPassword, setCopyPassword] = useState(""),
    [copyPayment, setCopyPayment] = useState(""),
    [updateData, setUpdateData] = useState(""),
    [emailVerification, setEmailVerification] = useState(""),
    [sendSMSVerification, setSendSMSVerification] = useState(""),
    [signInFG, setSignInFG] = useState(""),
    [uploadGOVId, setUploadGOVId] = useState(""),
    [geoLocation, setGEOLocation] = useState(""),
    [photoSignor, setPhotoSignor] = useState(""),
    [language, setLanguage] = useState(""),
    [docPrint, setDocPrint] = useState(""),
    [fontSize, setFontSize] = useState(""),
    [docComments, setDocComments] = useState(""),
    [esignSaveData, setEsignSaveData] = useState(""),
    [appendDoc, setAppendDoc] = useState(""),
    [appendUpload, setAppendUpload] = useState(""),
    [applyStamp, setApplyStamp] = useState(""),
    [commentVisibility, setCommentVisibility] = useState(""),
    [outputPdfFileType, setOutputPdfFileType] = useState(""),
    [saveLoading, setSaveLoading] = useState(false),
    [previewUrl, setPreviewUrl] = useState(""),
    [compressUrl, setCompressUrl] = useState(false),
    [publicBaseURLSelection, setPublicBaseURLSelection] = useState(""),
    [slug, setSlug] = useState(""),
    [historyShow, setHistoryShow] = useState(false),
    [primaryEmail, setPrimaryEmail] = useState(""),
    [showSession, setShowSession] = useState(false),
    [featureImg, setFeatureImg] = useState(""),
    [seoDescription, setSeoDescription] = useState(""),
    [showLegacyEditor, setShowLegacyEditor] = useState(false),
    [jsonContent, setJsonContent] = useState(""),
    [isGetDocDetail, setIsGetDocDetail] = useState(false),
    [toScheduler, setToScheduler] = useState(""),
    [showModal, setShowModal] = useState(false)

  const [states, setStates] = useState({
    driveType: "doc",
    showForThemPanel: false,
    showDhaPanel: false,
    showInstallationPanel: false,
    showMoreDetailsPanel: false,
  })

  const fillDocData = docDetail => {
    setDocData(docDetail)
    setDocName(docDetail.docName)
    setOwnerId(docDetail.driveAll.ownerID)
    setParentDoc(docDetail.parentDoc)
    setContent(docDetail.content)
    serDriveId(docDetail.driveAll.id)
    setDriveType(docDetail.driveAll.type)
    setOtherOption(docDetail.driveForm.ueFlow.other_option ?? "")
    setFileUrl(docDetail.driveAll.fileURL)
    setSelectedOption(docDetail.driveForm.ueFlow.selected_option ?? "")
    setForwardDomain(docDetail.driveForm.ueFlow.forward_domain ?? "")
    setForwardUrl(docDetail.driveForm.ueFlow.forward_url ?? "")
    setAdditionalOptions(docDetail.driveForm.ueFlow.additional_options ?? "")
    setInPersonMode(docDetail.driveForm.ueFlow.in_person_mode ?? "")
    setInPersonModeFileType(docDetail.driveForm.ueFlow.in_person_mode_file_type ?? "")
    setInPersonModeFileId(docDetail.driveForm.ueFlow.in_person_mode_file_id ?? "")
    setInPersonModeFileURL(docDetail.driveForm.ueFlow.in_person_mode_to_url ?? "")
    setOnPageDownloadFile(docDetail.driveForm.ueFlow.on_page_download_file ?? "")
    setToECommerceItem(docDetail.driveForm.ueFlow.to_ecommerce_item ?? "")
    setDHAtuomationData(docDetail.driveForm.dhAutomation.automation_option ?? "")
    setInitialAutomationOption(docDetail.driveForm.dhAutomation.initial_automation_option ?? "")
    setInitialIncomingOpt(docDetail.driveForm.dhAutomation.initial_incoming_opt ?? "")
    setInitialAsA(docDetail.driveForm.dhAutomation.initial_as_a ?? "")
    setInitialFolder(docDetail.driveForm.dhAutomation.initial_folder ?? "")
    setDocType(docDetail.driveForm.moreOptions.doc_type ?? "")
    setFeatureImg(docDetail.driveForm.moreOptions.feature_img ?? "")
    setSeoDescription(docDetail.driveForm.moreOptions.seo_description ?? "")
    setEsignMode(docDetail.driveForm.moreOptions.esign_mode ?? "")
    setTempTags(docDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setTags(docDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setPDFGeneration(docDetail.driveForm.moreOptions.pdf_generation ?? "")
    setSignAuth(docDetail.driveForm.moreOptions.authentication ?? "")
    setDocPrivacy(docDetail.driveForm.moreOptions.doc_privacy ?? "")
    setWhoCanModify(docDetail.driveForm.moreOptions.who_can_modify ?? "")
    setPrivacyPassword(docDetail.driveForm.moreOptions.privacy_password ?? "")
    setWhoCanCopy(docDetail.driveForm.moreOptions.who_can_copy ?? "")
    setCopyPassword(docDetail.driveForm.moreOptions.copy_password ?? "")
    setCopyPayment(docDetail.driveForm.moreOptions.copy_payment ?? "")
    setUpdateData(docDetail.driveForm.moreOptions.update_data ?? "")
    setEmailVerification(docDetail.driveForm.moreOptions.email_verification ?? "")
    setSendSMSVerification(docDetail.driveForm.moreOptions.send_sms_verification ?? "")
    setSignInFG(docDetail.driveForm.moreOptions.sign_in_fg ?? "")
    setUploadGOVId(docDetail.driveForm.moreOptions.upload_gov_id ?? "")
    setGEOLocation(docDetail.driveForm.moreOptions.geo_location ?? "")
    setPhotoSignor(docDetail.driveForm.moreOptions.photo_signor ?? "")
    setLanguage(docDetail.driveForm.moreOptions.language ?? "")
    setDocPrint(docDetail.driveForm.moreOptions.doc_print ?? "")
    setFontSize(docDetail.driveForm.moreOptions.font_size ?? "")
    setDocComments(docDetail.driveForm.moreOptions.doc_comments ?? "")
    setEsignSaveData(docDetail.driveForm.moreOptions.esign_save_data ?? "")
    setAppendDoc(docDetail.driveForm.moreOptions.append_doc ?? "")
    setAppendUpload(docDetail.driveForm.moreOptions.append_upload ?? "")
    setApplyStamp(docDetail.driveForm.moreOptions.apply_stamp ?? "")
    setCommentVisibility(docDetail.driveForm.moreOptions.comments_visibility ?? "")
    setOutputPdfFileType(docDetail.driveForm.moreOptions.output_pdf_file_type ?? "")
    setCompressUrl(docDetail.driveForm.ezLinkDetail.compressUrl == "true")
    setPublicBaseURLSelection(docDetail.driveForm.ezLinkDetail.publicBaseURLSelection ?? "")
    setSlug(docDetail.driveAll.slug ?? "")
    setShowLegacyEditor(docDetail.driveForm.moreOptions.doc_type === "eSignDocV2Legacy")
    setJsonContent(docDetail.jsonContent)
    setToScheduler(docDetail.driveForm.ueFlow.to_scheduler ?? "")
  }
  const editorRef = useRef(null)
  const legacyEditorRef = useRef(null)
  const submitDoc = async e => {
    e.preventDefault()

    var contentData = content
    var jsonContentData = jsonContent
    if (docType !== "eSignDoc") {
      if (legacyEditorRef.current) {
        contentData =
          legacyEditorRef.current.contentWindow.document.body.getElementsByTagName("textarea")[0].value
      }
    } else {
      await editorJs.current
        .save()
        .then(outputData => {
          setContent(outputData)
          console.log("Article data: ", outputData)
        })
        .catch(error => {
          console.log("Saving failed: ", error)
        })

      if (typeof contentData == "object") {
        const edjsParser = editorJsHTML()
        const htmlContent = edjsParser.parse(content)

        var htmlContentArray = []
        if (htmlContent) {
          htmlContentArray = htmlContent.map(item => {
            return item.replace(" - undefined", "")
          })
        }
        contentData = htmlContentArray.join("")
        jsonContentData = JSON.stringify(content)
      }
    }

    if (!contentData) {
      jsonContentData = ""
    }

    setContent(contentData)
    setSaveLoading(true)

    const formData = new FormData(e.target)
    let data = {
      docName: formData.get("docName"),
      content: contentData,
      jsonContent: jsonContentData,
      ownerID: formData.get("ownerID"),
      parentDoc: formData.get("parentDoc"),
      form: {
        ueFlow: {
          other_option: formData.get("otherOption"),
          selected_option: formData.get("selectedOption"),
          forward_domain: formData.get("forward_domain"),
          forward_url: formData.get("forward_url"),
          in_person_mode: formData.get("in_person_mode"),
          in_person_mode_file_type: formData.get("in_person_mode_file_type"),
          in_person_mode_file_id: formData.get("in_person_mode_file_id"),
          in_person_mode_to_url: formData.get("in_person_mode_to_url"),
          on_page_download_file: formData.get("on_page_download_file"),
          to_ecommerce_item: formData.get("to_ecommerce_item"),
          to_scheduler: formData.get("to_scheduler"),

          additional_options: setAdditionalOptionsArray(formData),
        },
        dhAutomation: {
          initial_automation_option: formData.get("initial_automation_option"),
          initial_incoming_opt: formData.get("initial_incoming_opt"),
          initial_folder: formData.get("initial_folder"),
          initial_as_a: formData.get("initial_as_a"),
          automation_option: setAutomationOptionsArray(formData, displayInFeedTempTags, contactTempTags),
        },
        moreOptions: {
          doc_type: formData.get("docType"),
          esign_mode: formData.get("esign_mode"),
          pdf_generation: formData.get("pdf_generation"),
          authentication: formData.get("authentication"),
          doc_private_tags: tempTags,
          doc_privacy: docPrivacy,
          who_can_modify: whoCanModify,
          privacy_password: formData.get("privacy_password"),
          who_can_copy: whoCanCopy,
          copy_password: formData.get("copy_password"),
          copy_payment: formData.get("copy_payment"),
          update_data: formData.get("update_data"),
          email_verification: formData.get("email_verification"),
          send_sms_verification: formData.get("send_sms_verification"),
          sign_in_fg: formData.get("sign_in_fg"),
          upload_gov_id: formData.get("upload_gov_id"),
          geo_location: formData.get("geo_location"),
          photo_signor: formData.get("photo_signor"),
          language: formData.get("language"),
          doc_print: formData.get("doc_print"),
          font_size: formData.get("font_size"),
          doc_comments: formData.get("doc_comments"),
          esign_save_data: formData.get("esign_save_data"),
          append_doc: formData.get("append_doc"),
          append_upload: formData.get("append_upload"),
          apply_stamp: formData.get("apply_stamp"),
          comments_visibility: formData.get("comments_visibility"),
          output_pdf_file_type: formData.get("output_pdf_file_type"),
          feature_img: featureImg,
          seo_description: formData.get("seo_description"),
        },
        ezLinkDetail: {
          compressUrl: formData.get("compressUrl"),
          publicBaseURLSelection: formData.get("public_base_url_selection"),
        },
      },
    }
    saveDocData(data)
  }

  const getDocData = async selectedDocId => {
    await AxiosInstance.get(`/drive/doc/detail/${selectedDocId}`).then(response => {
      fillDocData(response.data.doc)

      if (response.data.doc.driveForm.moreOptions?.doc_type == "eSignDoc") {
        initEditor(response.data.doc.jsonContent ?? [])
      }

      setIsGetDocDetail(true)
    })
  }

  const getDocEditor = async selectedDocId => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    }
    const switchUserEmail = getSwitchUserEmail()
    if (switchUserEmail) {
      headers["x-switch-user"] = switchUserEmail
    }
    const res = await fetch(`${process.env.GATSBY_BACKEND_API_URL}/editor/show/${selectedDocId}`, {
      method: "GET",
      headers: headers,
    })

    const blob = await res.blob()
    const urlObject = URL.createObjectURL(blob)

    if (legacyEditorRef.current) {
      legacyEditorRef.current.setAttribute('src', urlObject);
    }
  }

  const getSettingsData = async () => {
    AxiosInstance.get("/settings/personal-setting/unique-data").then(response => {
      setPrimaryEmail(
        response.data.uniqueData.filter(data => {
          if (data.type === "Primary") {
            return data
          }
        })
      )
    })
  }

  useEffect(() => {
    if (loadDocDetails) {
      setLoadDocDetails(false)
      getDocData(selectedDocId)
      getSettingsData()
    }

    setPreviewUrl(
      compressUrl === true
        ? checkUrl()
        : `${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/${driveType}/${fileUrl}`
    )
  })
  const DEFAULT_INITIAL_DATA = {
    time: new Date().getTime(),
    blocks: [
      {
        type: "paragraph",
        data: {
          text: "Make something awesome! Type / for more controls...",
        },
      },
    ],
  }

  const initEditor = async content => {
    const parsedContent = content ? JSON.parse(content) : ""

    const script = document.createElement("script")
    script.src = "https://cdn.jsdelivr.net/npm/@editorjs/editorjs@latest"
    script.async = true

    await new Promise((resolve, reject) => {
      script.onload = resolve
      script.onerror = reject
      document.body.appendChild(script)
    })

    if (window.EditorJS) {
      const editor = new window.EditorJS({
        holder: "editorjs",
        onReady: () => {
          editorJs.current = editor
        },
        placeholder: "Make something awesome! Type / for more controls...",
        tools: {
          header: Header,
          list: List,
          quote: phone,
        },
        autofocus: true,
        data: parsedContent ? parsedContent : DEFAULT_INITIAL_DATA,
        onChange: async () => {
          let content = await editor.save()
          setContent(content)
        },
      })
    } else {
      console.error("EditorJS is not available.")
    }
  }

  const saveDocData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/doc/edit/${selectedDocId}`, data)
        .then(function (response) {
          setSaveLoading(false)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }

  const checkUrl = () => {
    if (publicBaseURLSelection === changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}`)) {
      return `https://${publicBaseURLSelection}/${getUserName()}/${slug}`
    } else {
      return `https://${publicBaseURLSelection}/${slug}`
    }
  }

  useEffect(() => {
    if (docType === "eSignDocV2Legacy") {
      getDocEditor(selectedDocId)
    }

    if (isGetDocDetail && docType === "eSignDoc") {
      initEditor(jsonContent)
    }
  }, [docType])

  return (
    <Layout>
      {docData && <Seo title={docName} />}
      <Form onSubmit={submitDoc} id={"docForm"}>
        <div id="right-section" className="h-100">
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row g-0">
                <div className="col-lg-8 col-sm-12 mb-3">
                  <div className="pageTitle_Editor">
                    <Link to="/drive" className="goBack">
                      <i className="bi bi-arrow-90deg-left" />
                    </Link>
                    <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                      <i className="bi bi-file-earmark-fill" />
                    </button>
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Title / Summary"
                      name="docName"
                      defaultValue={docName}
                    />
                    <Form.Control type="hidden" defaultValue={ownerId} name="ownerID" />
                    <Form.Control type="hidden" defaultValue={parentDoc} name="parentDoc" />
                  </div>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-lg-12 mb-3">
                  <div className="col-lg-12 position-relative">
                    <div className="eDocTabsContainer">
                      <ul className="nav nav-tabs swiftCloudTabs " role="tablist">
                        <li className="nav-item" role="presentation">
                          <a className="nav-link active tabDocEdit">
                            <i className="bi bi-pencil-fill" /> <span>Edit</span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a href={previewUrl} className="nav-link tabDocView" target="_blank">
                            <i className="bi bi-file-earmark-text-fill" /> <span>Preview</span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link btnDocPublished" href="#">
                            <i className="bi bi-globe" /> <span>Published</span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link tabDocSession"
                            href="#"
                            data-bs-toggle="tooltip"
                            title=""
                            onClick={() => setShowSession(!showSession)}
                          >
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip>
                                  Pre-fill a doc for the other party to sign, or view partly completed eDocs
                                  that are not yet finalized.
                                </Tooltip>
                              }
                            >
                              <>
                                <i className="bi bi-person-fill" /> <span>Session(s)</span>
                              </>
                            </OverlayTrigger>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row g-0 driveDocSection">
                    {/*session(s)*/}
                    {showSession && (
                      <div className="col-lg-12 mb-3" id="session_panel">
                        <Accordion defaultActiveKey="0" alwaysOpen>
                          <Accordion.Item eventKey="1" className="panel panel-grey">
                            <Accordion.Header>
                              <span className="panel-heading-icon">
                                <i className="bi bi-person-fill" />
                              </span>
                              <span className="panel-heading-title">User Session(s)</span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="float-end">
                                    <ul
                                      className="nav nav-tabs swiftCloudTabs d-none d-md-flex"
                                      role="tablist"
                                    >
                                      <li className="nav-item">
                                        <button className="btn btn-primary">
                                          <i className="bi bi-person-plus-fill" />
                                          New Session
                                        </button>
                                      </li>
                                      <li className="nav-item nav-item-trash" role="presentation">
                                        <a
                                          className="nav-link"
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          data-placement="top"
                                          title="Trash"
                                        >
                                          <i className="bi bi-trash-fill" />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <table
                                    id="tblDocSession"
                                    className="table dataTable"
                                    cellSpacing="0"
                                    width="100%"
                                  >
                                    <thead>
                                      <tr>
                                        <th width="12%">Last Edited</th>
                                        <th width="20%">Name(s)</th>
                                        <th width="50%" className="d-none d-md-table-cell">
                                          Session Data Excerpt
                                        </th>
                                        <th width="10%" className="d-none d-md-table-cell">
                                          Status
                                        </th>
                                        <th width="5%" className="text-end d-none d-lg-table-cell"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <time className="timeago" dateTime="2022-05-01"></time>
                                        </td>
                                        <td className="session_user">
                                          <a href="#">
                                            <img src="images/bachchan-amitabh-image.jpg" />
                                            Amitabh Bachchan
                                          </a>
                                        </td>
                                        <td className="d-none d-md-table-cell">
                                          Lorem Ipsum is simply dummy text of the printing and typesetting
                                          industry
                                        </td>
                                        <td className="d-none d-md-table-cell">Pending</td>
                                        <td className="text-end tableColAction d-none d-lg-table-cell">
                                          <a
                                            href="#"
                                            className="btn btn-delete"
                                            data-bs-toggle="tooltip"
                                            title="Delete"
                                          >
                                            <i className="bi bi-trash-fill" />
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    )}

                    {docType === "eSignDocV2Legacy" && (
                      <iframe ref={legacyEditorRef} width="300" height="550" />
                    )}
                    {docType === "eSignDoc" && <div id="editorjs" />}
                    {docType !== "eSignDocV2Legacy" && docType !== "eSignDoc" && (
                      <Editor
                        apiKey={process.env.GATSBY_TINY_MCE_API_KEY}
                        value={content}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                          branding: false,
                          height: 500,
                          menubar: true,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "lists",
                            "link",
                            "image",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo| styles | " +
                            "bold italic alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "link image media | code",
                          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setContent(editor.getContent())
                        }}
                      />
                    )}

                    <div className="mb-3" />
                    {![
                      "dashboardFullscreen",
                      "faq",
                      "review",
                      "mailMergeDoc",
                      "CVResume",
                      "regularDoc",
                      "press",
                      "blog",
                      "emailNewsletter",
                    ].includes(docType) && (
                      <>
                        <UserExperienceFlow
                          otherOption={otherOption}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          forwardDomain={forwardDomain}
                          setForwardDomain={setForwardDomain}
                          forwardUrl={forwardUrl}
                          setForwardUrl={setForwardUrl}
                          additionalOptions={additionalOptions}
                          setAdditionalOptions={setAdditionalOptions}
                          inPersonMode={inPersonMode}
                          setInPersonMode={setInPersonMode}
                          inPersonModeFileType={inPersonModeFileType}
                          setInPersonModeFileType={setInPersonModeFileType}
                          inPersonModeFileId={inPersonModeFileId}
                          inPersonModeFileURL={inPersonModeFileURL}
                          onPageDownloadFile={onPageDownloadFile}
                          toECommerceItem={toECommerceItem}
                          setOtherOption={setOtherOption}
                          selectedDocId={selectedDocId}
                          primaryEmail={primaryEmail}
                          showForThemPanel={states.showForThemPanel}
                          setToECommerceItem={setToECommerceItem}
                          toScheduler={toScheduler}
                          setToScheduler={setToScheduler}
                        />

                        <DataHandlingAutomation
                          DHAtuomationData={DHAtuomationData}
                          setDHAtuomationData={setDHAtuomationData}
                          initialAutomationOption={initialAutomationOption}
                          initialIncomingOpt={initialIncomingOpt}
                          initialFolder={initialFolder}
                          initialAsA={initialAsA}
                          setInitialAsA={setInitialAsA}
                          contactTag={contactTag}
                          setContactTag={setContactTag}
                          contactTempTags={contactTempTags}
                          setContactTempTags={setContactTempTags}
                          displayInFeedTag={displayInFeedTag}
                          setDisplayInFeedTag={setDisplayInFeedTag}
                          displayInFeedTempTags={displayInFeedTempTags}
                          setDisplayInFeedTempTags={setDisplayInFeedTempTags}
                          selectedDocId={selectedDocId}
                          primaryEmail={primaryEmail}
                          setPrimaryEmail={setPrimaryEmail}
                          driveType={driveType}
                          setInitialIncomingOpt={setInitialIncomingOpt}
                          setInitialFolder={setInitialFolder}
                          showDhaPanel={states.showDhaPanel}
                        />
                      </>
                    )}

                    {/*Installation Options*/}
                    <InstallationOptions
                      driveType={driveType}
                      ownerId={ownerId}
                      driveId={driveId}
                      previewUrl={previewUrl}
                      fileUrl={fileUrl}
                      compressUrl={compressUrl}
                      publicBaseURLSelection={publicBaseURLSelection}
                      slug={slug}
                      setSlug={setSlug}
                      showInstallationPanel={states.showInstallationPanel}
                    />

                    {/*More Options*/}
                    <MoreOptions
                      driveType={driveType}
                      docType={docType}
                      setDocType={setDocType}
                      esignMode={esignMode}
                      setEsignMode={setEsignMode}
                      tags={tags}
                      setTags={setTags}
                      tempTags={tempTags}
                      setTempTags={setTempTags}
                      pdfGeneration={pdfGeneration}
                      setPDFGeneration={setPDFGeneration}
                      signAuth={signAuth}
                      setSignAuth={setSignAuth}
                      docPrivacy={docPrivacy}
                      setDocPrivacy={setDocPrivacy}
                      whoCanModify={whoCanModify}
                      setWhoCanModify={setWhoCanModify}
                      privacyPassword={privacyPassword}
                      whoCanCopy={whoCanCopy}
                      setWhoCanCopy={setWhoCanCopy}
                      copyPassword={copyPassword}
                      setCopyPassword={setCopyPassword}
                      copyPayment={copyPayment}
                      setCopyPayment={setCopyPayment}
                      updateData={updateData}
                      emailVerification={emailVerification}
                      setEmailVerification={setEmailVerification}
                      sendSMSVerification={sendSMSVerification}
                      setSendSMSVerification={setSendSMSVerification}
                      signInFG={signInFG}
                      setSignInFG={setSignInFG}
                      uploadGOVId={uploadGOVId}
                      setUploadGOVId={setUploadGOVId}
                      geoLocation={geoLocation}
                      setGEOLocation={setGEOLocation}
                      photoSignor={photoSignor}
                      setPhotoSignor={setPhotoSignor}
                      language={language}
                      docPrint={docPrint}
                      fontSize={fontSize}
                      docComments={docComments}
                      setDocComments={setDocComments}
                      esignSaveData={esignSaveData}
                      setEsignSaveData={setEsignSaveData}
                      appendDoc={appendDoc}
                      setAppendDoc={setAppendDoc}
                      appendUpload={appendUpload}
                      setAppendUpload={setAppendUpload}
                      applyStamp={applyStamp}
                      setApplyStamp={setApplyStamp}
                      commentVisibility={commentVisibility}
                      setCommentVisibility={setCommentVisibility}
                      outputPdfFileType={outputPdfFileType}
                      previewUrl={previewUrl}
                      featureImg={featureImg}
                      setFeatureImg={setFeatureImg}
                      seoDescription={seoDescription}
                      setShowLegacyEditor={setShowLegacyEditor}
                      showMoreDetailsPanel={states.showMoreDetailsPanel}
                    />

                    <ShareContactDetailModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                      previewUrl={previewUrl}
                      docName={docName}
                    />

                    <PanelToggle states={states} setStates={setStates} docType={docType} />

                    <div className="col-lg-12 text-center footer-action">
                      <div className="btn-group btnDropdownWithSeparater">
                        <button type="submit" className="btn btn-primary btn-lg">
                          <i className="bi bi-check2" /> Save &nbsp;
                          <i className={saveLoading ? "spinner-border spinner-border-sm" : ""} />
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-files" /> Save as Copy
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-subtract" />
                              Clone a Template
                            </a>
                          </li>
                        </ul>
                      </div>{" "}
                      <a className="btn btn-secondary btn-lg" href={previewUrl} target="_blank">
                        <i className="bi bi-box-arrow-up-right" /> Preview
                      </a>{" "}
                      <button type="button" className="btn btn-default btn-lg">
                        <i className="bi bi-cloud-arrow-up-fill" /> Publish
                      </button>{" "}
                      <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="btn btn-magic btn-lg"
                      >
                        <i className="bi bi-send-fill" /> Send
                      </button>{" "}
                      {/*SOCIALIZER POPUP*/}
                      <button
                        type="button"
                        className="btn btn-default btn-lg btnOpenChatDock"
                        data-bs-toggle="tooltip"
                        onClick={() => setHistoryShow(true)}
                      >
                        <span>
                          <OverlayTrigger placement="top" overlay={<Tooltip>History</Tooltip>}>
                            <i className="bi bi-arrow-counterclockwise" />
                          </OverlayTrigger>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {historyShow === true && (
          <div className="SCChatDockNonSocialAssetOnly">
            <div className="ChatDockHeader">
              <div className="user_info">
                <span className="ChatDockHeaderAssetIcon">
                  <i className="bi bi-file-earmark"></i>
                </span>
                DOCTITLEHERE
              </div>
              <div className="ChatDockInputOptContainer">
                <div className="dropdown ChatDockInputOpt">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-sticky-fill"></i>
                    Add Note
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" data-type="history">
                        <i className="bi bi-arrow-counterclockwise"></i> History
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" data-type="Private_note">
                        <i className="bi bi-sticky-fill"></i> Add Note
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" data-type="GenerateReport" data-value="">
                        <i className="bi bi-graph-up"></i> Generate Report
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <a
                className="btnCloseChatDock"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Close"
                aria-label="Close"
                onClick={() => setHistoryShow(false)}
              >
                <i className="bi bi-x-lg"></i>
              </a>
            </div>
            <div className="ChatDockHistoryContainer mCustomScrollbar _mCS_1">
              <div className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside" id="mCSB_1" tabindex="0">
                <div className="mCSB_container" id="mCSB_1_container" dir="ltr">
                  <ul className="chat">
                    <li className="other">
                      <div className="chat-msg">
                        <div className="avatar">
                          <OverlayTrigger placement="top" overlay={<Tooltip>SwiftCloud</Tooltip>}>
                            <a
                              className="d-block"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title=""
                              data-bs-original-title="SwiftCloud"
                              aria-label="SwiftCloud"
                            >
                              <img src="images/swift_cloud_flag.jpg" className="mCS_img_loaded" />
                            </a>
                          </OverlayTrigger>
                        </div>
                        <div className="msg">
                          <p>
                            <strong>Welcome to SwiftCloud!</strong>
                          </p>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text ever since the 1500s
                          </p>
                        </div>
                      </div>
                      <span className="msg-by-time">
                        <i className="bi bi-chat-dots-fill"></i>
                        June 10, 2022
                        <span className="chat-msg-time-detail">
                          (Friday) - Chat by
                          <a>@SwiftCloud</a>
                          at
                          <time>5:48 pm</time>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  id="mCSB_1_scrollbar_vertical"
                  className="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical"
                >
                  <div className="mCSB_draggerContainer">
                    <div className="mCSB_dragger" id="mCSB_1_dragger_vertical" onContextMenu={"return false"}>
                      <div className="mCSB_dragger_bar" style={{ lineHeight: "30px" }}>
                        <div className="mCSB_draggerRail"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ChatDockFooterContainer">
                <div className="ChatDockOpt ChatDockPrivateNoteOpt">
                  <div className="mb-2">
                    <textarea className="form-control" placeholder="Add Note" rows="3"></textarea>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                      <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Video Mail</Tooltip>}>
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-camera-video-fill"></i>
                          </button>
                        </OverlayTrigger>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item">
                              <i className="bi bi-play-fill"></i>
                              Select Existing / Template
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item btnChatDockVideoRecording">
                              <i className="bi bi-record-fill"></i>
                              Record New
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item btnChatDockVideoMailUpload">
                              <i className="bi bi-cloud-arrow-up-fill"></i>
                              Upload
                            </a>
                          </li>
                        </ul>
                      </div>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Add URL</Tooltip>}>
                        <a>
                          <i className="bi bi-link-45deg"></i>
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Insert Image</Tooltip>}>
                        <a>
                          <i className="bi bi-camera-fill"></i>
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Upload file</Tooltip>}>
                        <a className="chatNoteImg">
                          <i className="bi bi-paperclip"></i>
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Inject Emoji</Tooltip>}>
                        <a id="ChatDockPrivateNoteEmoji">
                          <i className="bi bi-emoji-smile"></i>
                        </a>
                      </OverlayTrigger>
                      <input
                        type="file"
                        accept="image/*"
                        className="chatNoteImgFile"
                        style={{ visibility: "hidden", position: "absolute" }}
                      />
                    </div>
                    <div className="col-sm-4 text-end">
                      <button type="button" className="btn btn-primary btnChatDockSendPrivateNote">
                        <i className="bi bi-check2"></i>
                        Add Note
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </Layout>
  )
}
export default Detail
