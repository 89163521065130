import React, { useEffect, useState, useRef } from "react"
import ItemPrice from "./itemPrice"
import ItemPresentation from "./itemPresentation"
import { getEvents } from "../../Global/apiHelpers"
import { Editor } from "@tinymce/tinymce-react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { getUserCurrency } from "../../../services/globalHelpers"

if (typeof window !== "undefined") {
  require("tinymce/tinymce")
  require("tinymce/icons/default")
  require("tinymce/themes/silver")
  require("tinymce/plugins/link")
  require("tinymce/plugins/image")
  require("tinymce/plugins/advlist")
  require("tinymce/plugins/autolink")
  require("tinymce/plugins/lists")
  require("tinymce/plugins/preview")
  require("tinymce/plugins/searchreplace")
  require("tinymce/plugins/table")
  require("tinymce/plugins/wordcount")
  require("tinymce/models/dom/model")
  require("tinymce/plugins/media")
}

const Detail = ({ states, setStates, setFileToUpload, setGalleryFiles, setToDeleteFiles }) => {
  const [events, setEvents] = useState([])
  const [updateFields, setUpdateFields] = useState({})
  const [showOrderBump, setShowOrderBump] = useState(false)

  const currencyData = getUserCurrency()
  const editorRef = useRef(null)

  const updateData = (e, fieldName) => {
    var inputValue = e.target.value
    if (inputValue === " " && fieldName === "orderBumpHeadline") {
      inputValue = ""
    }
    setStates({ ...states, [fieldName]: inputValue })
  }

  const updateStripePrice = (value, fieldName) => {
    var arrayData = [
      {
        [fieldName]: value,
      },
    ]
    setStates({ ...states, prices: arrayData })
  }

  const updatePaymentType = paymentType => {
    const updatedFieldValue = {
      showSchedulerContainer: false,
      showCalendarContainer: false,
      showAssetsContainer: false,
      showPlaybookContainer: false,
      showProductdetailsContainer: true,
      showBtnOtherServicePreview: true,
      showBtnStripeOnlyPreview: false,
      showForThemPanel: true,
      paymentInstallment: "OneTimePayment",
      hostedHkuClassName: "",
      integratedStripeFieldClassName: "hostedSKUHidden",
      allowSplitInstallment: true,
      hostedHkuClassNameItemPrice: "",
      stripeRecurringPrice: true,
    }
    var skuProductPrice = states.prices
    var skuPaymentTime = states.stripePaymentPeriod

    switch (paymentType) {
      case "Event Ticket (All at 1 Time)":
      case "Access Ticket (Small Group or Individual)":
        updatedFieldValue.allowSplitInstallment = false
        updatedFieldValue.showCalendarContainer = true
        updatedFieldValue.showProductdetailsContainer = false
        updatedFieldValue.showForThemPanel = false
        break

      case "Pre-Priced Packaged Services":
        updatedFieldValue.showProductdetailsContainer = false
        updatedFieldValue.showForThemPanel = false
        updatedFieldValue.showPlaybookContainer = true
        break

      case "Stripe 1-Time":
      case "Stripe Recurring":
        updatedFieldValue.hostedHkuClassName = "hostedSKUHidden"
        updatedFieldValue.integratedStripeFieldClassName = ""
        updatedFieldValue.showBtnStripeOnlyPreview = true
        updatedFieldValue.showBtnOtherServicePreview = false
        updatedFieldValue.hostedHkuClassNameItemPrice = "hostedSKUHidden"
        skuProductPrice = skuProductPrice || [{ singlePrice: "99.99" }]
        skuPaymentTime = skuPaymentTime || "Month"
        break

      case "Subscription (Recurring Rebill Date)":
      case "Auction Recurring Subscription":
      case "Lease / Rental (Per Scheduler + Custom Asset)":
      case "Membership (Due on 1st Monthly) (No Asset)":
      case "Hourly Service or Rental (Per Scheduler + Custom Asset)":
        updatedFieldValue.integratedStripeFieldClassName = ""
        updatedFieldValue.hostedHkuClassNameItemPrice = "hostedSKUHidden"
        skuProductPrice = skuProductPrice || [{ singlePrice: "99.99" }]
        skuPaymentTime = skuPaymentTime || "Month"
        break

      case "Stripe Variable Billing":
        updatedFieldValue.hostedHkuClassName = "hostedSKUHidden"
        updatedFieldValue.integratedStripeFieldClassName = ""
        updatedFieldValue.showBtnStripeOnlyPreview = true
        updatedFieldValue.showBtnOtherServicePreview = false
        updatedFieldValue.hostedHkuClassNameItemPrice = "hostedSKUHidden"
        updatedFieldValue.stripeRecurringPrice = false
        skuProductPrice = skuProductPrice || [{ singlePrice: "99.99" }]
        skuPaymentTime = skuPaymentTime || "Month"
        break
    }

    setUpdateFields({ ...updateFields, ...updatedFieldValue })
    setStates({ ...states, paymentType, prices: skuProductPrice, stripePaymentPeriod: skuPaymentTime })
  }

  const addItemPrice = e => {
    e.preventDefault()
    setStates({ ...states, prices: [...states.prices, {}] })
  }

  useEffect(() => {
    updatePaymentType(states.paymentType)
  }, [states.paymentType])

  const loadInitialData = async () => {
    setEvents(await getEvents())
  }

  useEffect(() => {
    loadInitialData()
  }, [])

  useEffect(() => {
    if (states?.orderBumpHeadline || states?.orderBumpPrice) {
      setShowOrderBump(true)
    }

    const paymentTypeArray = ["Stripe 1-Time", "Stripe Recurring", "Stripe Variable Billing"]
    const valueExists = paymentTypeArray.includes(states.paymentType)
    if (valueExists) {
      setShowOrderBump(false)
    }
  }, [states])

  const handleShowHideOrderBump = (e, action) => {
    e.preventDefault()
    setShowOrderBump(action)

    if (action === false) {
      setStates({ ...states, orderBumpHeadline: "", orderBumpDesc: "", orderBumpPrice: "" })
    }
  }

  return (
    <div className="col-lg-12 mb-3">
      <div className="row">
        <div className="col-lg-8 col-md-6 mb-4 mb-md-0">
          <div id="editor_panel">
            <div className="accordion" id="editorPanelAccordion">
              <div className="accordion-item">
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="doc_data">
                        <div className={`col-lg-12 productDetailsContainer hostedSKU`}>
                          <div className="mb-3 row align-items-center">
                            {/* <label className="col-sm-4 col-form-label"></label> */}
                            <div className="col-sm-8 form-group productOptChoice">
                              <div className="form-check-inline mb-0">
                                <input
                                  type="radio"
                                  name="productDetailOpt"
                                  id="imgProductDetailOpt"
                                  value="image"
                                  checked={states?.productDetailOpt === "image"}
                                  onChange={e => updateData(e, "productDetailOpt")}
                                  className="css-checkbox"
                                />{" "}
                                <label
                                  htmlFor="imgProductDetailOpt"
                                  className="css-radio-label radGroup2 mb-0"
                                >
                                  Image
                                </label>
                              </div>
                              <div className="form-check-inline mb-0">
                                <input
                                  type="radio"
                                  name="productDetailOpt"
                                  id="videoProductDetailOpt"
                                  value="video"
                                  className="css-checkbox"
                                  onChange={e => updateData(e, "productDetailOpt")}
                                  checked={states?.productDetailOpt === "video"}
                                />{" "}
                                <label
                                  htmlFor="videoProductDetailOpt"
                                  className="css-radio-label radGroup2 mb-0"
                                >
                                  Video
                                </label>
                              </div>
                              <div className="form-check-inline mb-0">
                                <input
                                  type="radio"
                                  name="productDetailOpt"
                                  id="galleryProductDetailOpt"
                                  value="gallery"
                                  className="css-checkbox"
                                  onChange={e => updateData(e, "productDetailOpt")}
                                  checked={states?.productDetailOpt === "gallery"}
                                />{" "}
                                <label
                                  htmlFor="galleryProductDetailOpt"
                                  className="css-radio-label radGroup2 mb-0"
                                >
                                  Gallery
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 row align-items-center">
                            <div className="form-group row productDetailOptShow">
                              {/* <label className="col-sm-4 col-form-label"></label> */}
                              <ItemPresentation
                                states={states}
                                setStates={setStates}
                                updateData={updateData}
                                setGalleryFiles={setGalleryFiles}
                                setFileToUpload={setFileToUpload}
                                setToDeleteFiles={setToDeleteFiles}
                              />
                            </div>
                          </div>
                        </div>

                        <Editor
                          textareaName="content"
                          value={states.note}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={{
                            skin: false,
                            branding: false,
                            content_css: false,
                            height: 500,
                            promotion: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "preview",
                              "searchreplace",
                              "table",
                              "lists",
                              "link",
                              "wordcount",
                              "media",
                            ],
                            toolbar:
                              "undo redo| styles | " +
                              "bold italic alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | image media |",
                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                          onEditorChange={(newValue, editor) => {
                            setStates({ ...states, note: editor.getContent() })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div id="sku_panel">
            <div className="accordion" id="skuPanelAccordion">
              <div className="accordion-item panel panel-white">
                <div className="accordion-header">
                  <div
                    className="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#sku_container"
                    aria-expanded="false"
                    aria-controls="sku_container"
                  >
                    <span className="panel-heading-icon">
                      <i className="bi bi-cart-fill"></i>
                    </span>
                    <span className="panel-heading-title">SKU Product / Service Details</span>
                  </div>
                </div>
                <div
                  id="sku_container"
                  className="accordion-collapse collapse show"
                  aria-labelledby="sku_container"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <select
                          name="paymentType"
                          className="form-select paymentTypeSelect"
                          value={states?.paymentType || "Pre-Priced Service"}
                          onChange={e => updatePaymentType(e.target.value)}
                        >
                          <optgroup label="== SERVICES ==">
                            <option value="Pre-Priced Service">Service Package</option>
                            <option value="Subscription (Recurring Rebill Date)">
                              Subscription (Recurring Rebill Date)
                            </option>
                            <option value="Service (Exclusive Per Scheduler)">
                              Service (Exclusive Per Scheduler)
                            </option>
                            <option value="eCourse">e-Learning Course</option>
                            <option value="Retainer Deposit">Retainer Deposit</option>
                          </optgroup>
                          <optgroup label="== MANAGED ON STRIPE ==">
                            <option value="Stripe 1-Time">Stripe 1-Time</option>
                            <option value="Stripe Recurring">Stripe Recurring</option>
                            <option value="Stripe Variable Billing">Stripe Variable Billing</option>
                          </optgroup>
                          <optgroup label="== AUCTIONS ==">
                            <option value="Auction 1-Time">Auction 1-Time</option>
                            <option value="Auction Recurring Subscription">
                              Auction Recurring Subscription
                            </option>
                          </optgroup>
                          <optgroup label="== EVENT TICKETING ==">
                            <option value="Event Ticket (All at 1 Time)">Event Ticket (All at 1 Time)</option>
                            <option value="Access Ticket (Small Group or Individual)">
                              Access Ticket (Small Group or Individual)
                            </option>
                          </optgroup>
                          <optgroup label="== LEASE / RENTAL / MEMBERSHIP ==">
                            <option value="Lease / Rental (Per Scheduler + Custom Asset)">
                              Lease / Rental (Per Scheduler + Custom Asset)
                            </option>
                            <option value="Membership (Due on 1st Monthly) (No Asset)">
                              Membership (Due on 1st Monthly) (No Asset)
                            </option>
                            <option value="Hourly Service or Rental (Per Scheduler + Custom Asset)">
                              Hourly Service or Rental (Per Scheduler + Custom Asset)
                            </option>
                            <option value="Hourly Service or Rental (No Asset)">
                              Hourly Service or Rental (No Asset)
                            </option>
                          </optgroup>
                          <optgroup label="== PHYSICAL ITEM ==">
                            <option value="Digital Item (e-Delivery)">Digital Item (e-Delivery)</option>
                            <option value="Physical Item No Shipping">
                              Physical Item (Retail / No Shipping)
                            </option>
                            <option value="Physical Item with Shipping">Physical Item (with Shipping)</option>
                            <option value="Recurring Subscription">
                              Recurring Subscription (with Shipping)
                            </option>
                          </optgroup>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className={`col-lg-12 mt-3 itemBoxContainer hostedSKU ${updateFields.hostedHkuClassNameItemPrice}`}
                      >
                        {states.prices.map((price, index) => {
                          return (
                            <ItemPrice
                              key={index}
                              states={states}
                              setStates={setStates}
                              index={index}
                              currencyData={currencyData}
                            />
                          )
                        })}
                      </div>

                      <div
                        className={`col-lg-12 mt-3 mb-3 integratedStripeField ${updateFields.integratedStripeFieldClassName}`}
                      >
                        <div className="mb-3 row align-items-center">
                          <label className="col-sm-4 col-form-label">Product ID</label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              name="stripe_product_id"
                              className="form-control"
                              value={states?.stripeProductId || "prod_XXXXXXXXXXXX"}
                              onChange={e => updateData(e, "stripeProductId")}
                            />
                          </div>
                        </div>

                        {updateFields.stripeRecurringPrice && (
                          <div className="mb-3 row align-items-center">
                            <label className="col-sm-4 col-form-label">Price:</label>
                            <div className="col-sm-8">
                              <div className="input-group stripeOneTimePricing d-none">
                                <span className="input-group-text">
                                  <i className={currencyData.icon} />
                                </span>
                                <input
                                  type="number"
                                  name="singlePrice"
                                  min={0}
                                  step="any"
                                  className="form-control"
                                  value={states?.prices[0]?.singlePrice || "99.99"}
                                  onChange={e => updateStripePrice(e.target.value, "singlePrice")}
                                />
                              </div>
                              <div className="input-group stripeSubscriptionPricing">
                                <span className="input-group-text">
                                  <i className={currencyData.icon} />
                                </span>

                                <input
                                  type="number"
                                  name="singlePrice"
                                  min={0}
                                  step="any"
                                  className="form-control"
                                  value={states?.prices[0]?.singlePrice || "99.99"}
                                  onChange={e => updateStripePrice(e.target.value, "singlePrice")}
                                />

                                <select
                                  name="splitPaymentPeriod"
                                  className="form-select"
                                  value={states?.stripePaymentPeriod || "Month"}
                                  onChange={e => updateData(e, "stripePaymentPeriod")}
                                >
                                  <option value="Hour">Per Hour</option>
                                  <option value="Day">Per Day</option>
                                  <option value="Week">Per Week</option>
                                  <option value="Month">Per Month</option>
                                  <option value="Quarter">Per Quarter</option>
                                  <option value="Year">Per Year</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row align-items-center">
                          <label className="col-sm-4 col-form-label">Stripe Product URL:</label>
                          <div className="col-sm-8">
                            <input
                              type="url"
                              name="stripe_product_url"
                              className="form-control"
                              placeholder="https://"
                              value={states?.stripeProductUrl || ""}
                              onChange={e => updateData(e, "stripeProductUrl")}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center mt-2">
                          <label className="col-sm-4 col-form-label"></label>
                          <div className="col-sm-8">
                            <a
                              href="https://dashboard.stripe.com/products?action=true"
                              target="_blank"
                              className="fs-5 fw-bold"
                            >
                              Manage Products
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className={`col-lg-12 orderBumpContainer${!showOrderBump ? " d-none" : ""}`}>
                        <div className="orderBumpInfoContainer">
                          <button
                            class="btnDeleteItemFeed"
                            onClick={e => handleShowHideOrderBump(e, false)}
                            type="button"
                          >
                            <i class="bi bi-x-lg"></i>
                          </button>
                          <div className="mb-2">
                            <p className="fw-bold fs-5 mb-0">Order Bump</p>
                            <label className="col-sm-4 col-form-label">Headline</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Headline"
                              name="orderBumpHeadline"
                              id="orderBumpHeadline"
                              value={states?.orderBumpHeadline || ""}
                              onChange={e => updateData(e, "orderBumpHeadline")}
                            />
                          </div>
                          <div className="mb-2">
                            <label className="col-sm-4 col-form-label">Description</label>
                            <Editor
                              textareaName="orderBumpDesc"
                              value={states?.orderBumpDesc || ""}
                              onInit={(evt, editor) => (editorRef.current = editor)}
                              init={{
                                skin: false,
                                branding: false,
                                content_css: false,
                                height: 500,
                                promotion: false,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "preview",
                                  "searchreplace",
                                  "table",
                                  "lists",
                                  "link",
                                  "wordcount",
                                  "media",
                                ],
                                toolbar:
                                  "undo redo| styles | " +
                                  "bold italic alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | image media |",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              }}
                              onEditorChange={(newValue, editor) => {
                                setStates({ ...states, orderBumpDesc: editor.getContent() })
                              }}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="col-sm-4 col-form-label">Price</label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className={currencyData.icon} />
                              </span>
                              <input
                                type="number"
                                name="orderBumpPrice"
                                id="orderBumpPrice"
                                min={0}
                                step="any"
                                className="form-control"
                                value={states?.orderBumpPrice || ""}
                                onChange={e => updateData(e, "orderBumpPrice")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`col-lg-12 mb-3 text-center hostedSKU ${updateFields.hostedHkuClassName}`}
                      >
                        <OverlayTrigger placement="top" overlay={<Tooltip>Add New</Tooltip>}>
                          <button
                            className="btnAddNewGreenDashed btnAddNewProductFeed"
                            onClick={addItemPrice}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </button>
                        </OverlayTrigger>{" "}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Add Order Bump</Tooltip>}>
                          <button
                            className="btnAddNewGreenDashed btnAddNewOrderBump"
                            onClick={e => {
                              e.preventDefault()
                              setShowOrderBump(!showOrderBump)
                            }}
                          >
                            <i className="bi bi-cart-plus-fill" />
                          </button>
                        </OverlayTrigger>
                      </div>
                      {updateFields?.showSchedulerContainer && (
                        <div
                          className={`col-lg-12 schedulerContainer hostedSKU ${updateFields.hostedHkuClassName}`}
                        >
                          <div className="mb-3 row align-items-center">
                            <label className="col-sm-4 col-form-label">Scheduler</label>
                            <div className="col-sm-8">
                              <select className="form-select">
                                <option>== SCHEDULERS ==</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {updateFields?.showCalendarContainer && (
                        <div
                          className={`col-lg-12 calendarContainer hostedSKU ${updateFields.hostedHkuClassName}`}
                        >
                          <div className="mb-3 row align-items-center">
                            <label className="col-sm-4 col-form-label">Calendar Event</label>
                            <div className="col-sm-8">
                              <select
                                className="form-select"
                                value={states?.calendarEvent || ""}
                                onChange={e => updateData(e, "calendarEvent")}
                              >
                                <option>== CALENDAR EVENTS ==</option>
                                {events.map((event, index) => {
                                  return (
                                    <option key={index} value={event.id}>
                                      {event.title}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {updateFields?.showAssetsContainer && (
                        <div
                          className={`col-lg-12 assetsContainer hostedSKU ${updateFields.hostedHkuClassName}`}
                        >
                          <div className="mb-3 row align-items-center">
                            <label className="col-sm-4 col-form-label">Asset Name</label>
                            <div className="col-sm-8">
                              <select className="form-select">
                                <option>== CUSTOM ASSETS ==</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {updateFields?.showPlaybookContainer && (
                        <div
                          className={`col-lg-12 playbookContainer hostedSKU ${updateFields.hostedHkuClassName}`}
                        >
                          <div className="mb-3 row align-items-center">
                            <label className="col-sm-4 col-form-label">Playbook / Task Template</label>
                            <div className="col-sm-8">
                              <select className="form-select">
                                <option>== PLAYBOOKS ==</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      {updateFields?.showProductdetailsContainer && (
                        <>
                          <div
                            className={`col-lg-12 productDetailsContainer hostedSKU ${updateFields.hostedHkuClassName}`}
                          >
                            {/*<div className="mb-3 row align-items-center">*/}
                            {/*  <label className="col-sm-4 col-form-label">*/}
                            {/*    Details / Product Description: (Visible before sale on checkout page)*/}
                            {/*  </label>*/}
                            {/*  <div className="col-sm-8">*/}
                            {/*    <textarea*/}
                            {/*      id="productDesc"*/}
                            {/*      className="form-control"*/}
                            {/*      cols="30"*/}
                            {/*      rows="10"*/}
                            {/*      onChange={e => updateData(e, "description")}*/}
                            {/*      value={states?.description || ""}*/}
                            {/*    ></textarea>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div className="mb-3 row align-items-center">
                              <label className="col-sm-4 col-form-label">
                                More Info URL{" "}
                                <span
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="Optional: Product sales page or full details about this product or service."
                                  aria-label="Optional: Product sales page or full details about this product or service."
                                >
                                  <i className="bi bi-question-circle-fill"></i>
                                </span>
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="url"
                                  name="productSalesPage"
                                  id="productSalesPage"
                                  placeholder="https://"
                                  value={states?.productSalesPage || ""}
                                  className="form-control"
                                  onChange={e => updateData(e, "productSalesPage")}
                                />
                              </div>
                            </div>
                            {/*<div className="mb-3 row align-items-center">*/}
                            {/*  <label className="col-sm-4 col-form-label">Terms</label>*/}
                            {/*  <div className="col-sm-8">*/}
                            {/*    <textarea*/}
                            {/*      id="termsDesc"*/}
                            {/*      className="form-control"*/}
                            {/*      cols="30"*/}
                            {/*      rows="10"*/}
                            {/*      value={states?.termsDesc || ""}*/}
                            {/*      onChange={e => updateData(e, "termsDesc")}*/}
                            {/*    ></textarea>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
