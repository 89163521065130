import * as React from "react"
import { useState, useEffect } from "react"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import { navigate } from "gatsby"

const ShareContactDetailModal = ({ showModal, setShowModal, previewUrl, docName }) => {
  const [existingUserDefaultId, setExistingUserDefaultId] = useState(""),
    [existingContactId, setExistingContactId] = useState(""),
    [userSearchResult, setUserSearchResult] = useState([]),
    [existingUserDefaultName, setExistingUserDefaultName] = useState(""),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [existingUserDefaultImage, setExistingUserDefaultImage] = useState(""),
    [settingRole, setSettingRole] = useState([]),
    [defaultSelectedRole, setDefaultSelectedRole] = useState(""),
    [isShowShareToggle, setIsShowShareToggle] = useState(false),
    [historyType, setHistoryType] = useState(""),
    [isContact, setIsContact] = useState(true),
    [contactGroupData, setContactGroupData] = useState([])

  const handleShareContact = () => {
    const baseUrl = isContact ? `/contacts/detail/` : `/contacts/groups/detail/`

    const fullUrl = `${baseUrl}${existingContactId}?historyType=${encodeURIComponent(
      historyType
    )}&previewUrl=${previewUrl}`

    navigate(fullUrl)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const contactSearchData = e => {
    setIsShowShareToggle(false)
    let searchUser = e.target.value
    if (searchUser.length < 2) {
      setUserSearchResult([])
      setExistingContactId("")
      return false
    }
    setIsShowShareToggle(true)
    AxiosInstance.get(`/contact/`, {
      params: {
        page: 1,
        search: searchUser ?? "",
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
    })
  }

  const contactGroupSearchData = () => {
    AxiosInstance.get(`/contact/group/list-all`).then(response => {
      setContactGroupData(response.data.groups)
    })
  }

  const businessRoleTable = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        if (response.data.items.length > 0) {
          setSettingRole(response.data.items)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    businessRoleTable()
    contactGroupSearchData()
  }, [])

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      id="requestEsignModal"
      dialogClassName="modal-lg modal-dialog-centered"
    >
      <Modal.Header>
        <h2 className="mb-0">{`Share ${docName}`}</h2>
        <button type="button" className="btn-close" onClick={handleClose} />
      </Modal.Header>

      <Modal.Body className="p-0">
        <div className="row g-0">
          <div className="col-lg-12">
            <div className="row g-0 stepProcess">
              <div className="col-lg-12 p-0">
                <ul className="nav nav-wizard">
                  <li className="SaleStatus_Pending statusAddData" data-step={3}>
                    <OverlayTrigger placement="top" overlay={<Tooltip>File(s)</Tooltip>}>
                      <a href="#" data-bs-toggle="tooltip">
                        <i className="bi bi-files" />
                        <span>File(s)</span>
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li className="SaleStatus_NotStarted" data-step={1}>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Recipient(s)</Tooltip>}>
                      <a href="#" data-bs-toggle="tooltip">
                        <i className="bi bi-people-fill" />
                        <span>Recipient(s)</span>
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li className="SaleStatus_NotStarted" data-step={2}>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit & Send Message</Tooltip>}>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Edit & Send Message"
                      >
                        <i className="bi bi-send-fill" />
                        <span>Share Link</span>
                      </a>
                    </OverlayTrigger>
                  </li>
                </ul>
              </div>
              <div className="col-lg-12 p-3">
                <div className="row align-items-center mb-2">
                  <div className="col-md-6">
                    <div className="row row-cols-sm-auto g-2 align-items-center">
                      <div className="col-12">Share with...</div>
                      <div className="col-12">
                        <input
                          type="radio"
                          name="eSignShareWith"
                          id="eSignShareWithPerson"
                          defaultValue="Person"
                          defaultChecked="checked"
                          className="css-checkbox"
                          onClick={() => {
                            setIsContact(true)
                            setIsShowShareToggle(false)
                          }}
                        />
                        <label htmlFor="eSignShareWithPerson" className="css-radio-label me-3">
                          Person
                        </label>
                        <input
                          type="radio"
                          name="eSignShareWith"
                          id="eSignShareWithGroup"
                          defaultValue="Group"
                          className="css-checkbox"
                          onClick={() => {
                            setIsContact(false)
                            setIsShowShareToggle(false)
                          }}
                        />
                        <label htmlFor="eSignShareWithGroup" className="css-radio-label">
                          Group
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row row-cols-sm-auto g-2 align-items-center justify-content-end SigningInRow d-none">
                      <div className="col-12">Signing in</div>
                      <div className="col-12">
                        <select className="form-select signing-in">
                          <option value="specific">Specific</option>
                          <option value="any">Any</option>
                        </select>
                      </div>
                      <div className="col-12">order</div>
                    </div>
                  </div>
                </div>
                <div
                  className={`row g-0 align-items-center mb-2 eSignShareWithPersonRow socialSearchModal${!isContact ? " d-none" : ""
                    }`}
                >
                  <div className="col-lg-12">
                    <div className="guestUserRow BC_Multiple_User">
                      <div className="row">
                        <div className="col-md-6">
                          {/* <div className="bc-wrapper">
                                                            <input
                                                                type="hidden"
                                                                id="undefinedId"
                                                                name="undefinedId"
                                                                defaultValue=""
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control eSignShareWithPersonSocialSearch"
                                                                placeholder="Email or Username"
                                                                autoComplete="off"
                                                            />
                                                            <div className="bc-menu list-group" />
                                                        </div> */}

                          <div className="bc-wrapper">
                            <input
                              type="hidden"
                              id="socialAutoSearchId"
                              name="socialAutoSearchId"
                              defaultValue={existingUserDefaultId}
                            />

                            <input
                              type="text"
                              className={`form-control ${existingContactId ? "userSelectedInput" : ""} `}
                              name="socialAutoSearch"
                              placeholder="Email or Username"
                              autoComplete="off"
                              onKeyUp={contactSearchData}
                              data-query={existingUserDefaultName}
                              value={existingUserDefaultName}
                              onChange={e => setExistingUserDefaultName(e.target.value)}
                            />

                            {userSearchResult.length > 0 && (
                              <div className="bc-menu list-group" style={{ display: "block" }}>
                                {userSearchResult.map((searchUserData, searchUserIndex) => {
                                  return (
                                    <a
                                      // href="#"
                                      className="list-group-item"
                                      data-id={searchUserData.id}
                                      data-label={searchUserData.name}
                                      key={searchUserIndex}
                                      onClick={() => {
                                        const defaultImageUrl = `${process.env.GATSBY_WRANGLER_URL}/${searchUserData.profilePic
                                            ? searchUserData.profilePic
                                            : uploadedFilePath
                                          }`
                                        setExistingContactId(searchUserData.id)
                                        setExistingUserDefaultName(searchUserData.name)
                                        setExistingUserDefaultImage(defaultImageUrl)
                                        setUserSearchResult([])
                                        setExistingUserDefaultId(searchUserData.id)
                                      }}
                                    >
                                      <img
                                        className="bc-user-avatar"
                                        src={`${process.env.GATSBY_WRANGLER_URL}/${searchUserData.profilePic
                                            ? searchUserData.profilePic
                                            : uploadedFilePath
                                          }`}
                                      />
                                      {searchUserData.name}
                                    </a>
                                  )
                                })}
                              </div>
                            )}

                            {existingContactId && (
                              <img
                                className="selectedUserAvatar"
                                src={existingUserDefaultImage}
                                alt={existingUserDefaultName}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            value={defaultSelectedRole}
                            onChange={e => {
                              setDefaultSelectedRole(e.target.value)
                            }}
                          >
                            {settingRole.length > 0 &&
                              settingRole.map((roleData, roleIndex) => {
                                return (
                                  <option key={roleIndex} value={roleData.id}>
                                    {roleData.role}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="my-3 row align-items-center bootcomplete_add_new_user_field d-none">
                        <div className="col-lg-12 text-center">
                          <div className="SubContactAvatar">
                            <input
                              type="file"
                              accept="image/*"
                              className="subContactAvatarFile"
                              style={{ visibility: "hidden", position: "absolute" }}
                            />
                            <a
                              href="#"
                              className="btn btn-default btnEditSubContactAvatar"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Select avatar"
                              aria-label="Select avatar"
                            >
                              <i className="bi bi-pencil-fill" />
                            </a>
                            <img src="images/avatar.gif" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 row align-items-center bootcomplete_add_new_user_field d-none">
                        <div className="col-sm-12">
                          <div className="input-group">
                            <div className="dropdown nameOrCompanyDropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-person-fill" /> Name
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-person-fill" /> Name
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-building-fill" /> Company Name
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      {/* REWORD LABEL TO "ROLE i.e. Spouse" IF HUMAN */}
                      <div className="mb-3 row align-items-center bootcomplete_add_new_user_field d-none">
                        <label className="col-sm-3 col-form-label text-end">Role</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="mb-3 row align-items-center bootcomplete_add_new_user_field d-none">
                        <label className="col-sm-3 col-form-label text-end">Email</label>
                        <div className="col-sm-9">
                          <input type="email" className="form-control" />
                        </div>
                      </div>
                      <div className="row align-items-center bootcomplete_add_new_user_field d-none">
                        <label className="col-sm-3 col-form-label text-end">Phone</label>
                        <div className="col-sm-9">
                          <input type="tel" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`row g-0 align-items-center mb-2 eSignShareWithGroupRow${isContact ? " d-none" : ""
                    }`}
                >
                  <div className="col-lg-12">
                    <div className="guestUserRow">
                      <div className="row">
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            onChange={e => {
                              if (e.target.value) {
                                setExistingContactId(e.target.value)
                                setIsShowShareToggle(true)
                              } else {
                                setIsShowShareToggle(false)
                              }
                            }}
                          >
                            <option value={""}>== List of Groups ==</option>
                            {contactGroupData.map((groupValue, index) => (
                              <option key={index} value={groupValue.id}>
                                {groupValue.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            value={defaultSelectedRole}
                            onChange={e => {
                              setDefaultSelectedRole(e.target.value)
                            }}
                          >
                            {settingRole.length > 0 &&
                              settingRole.map((roleData, roleIndex) => {
                                return (
                                  <option key={roleIndex} value={roleData.id}>
                                    {roleData.role}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0 align-items-center eSignShareStartSessionRow d-none"></div>
                <div
                  className={`row g-0 align-items-center eSignShareURLRow${!isShowShareToggle ? " d-none" : ""
                    }`}
                >
                  <div className="row row-cols-sm-auto g-1 align-items-center">
                    {/* <div className="col-12">
                      <div className="toggleSwitch">
                        <input
                          type="checkbox"
                          name="eSignShareURL"
                          id="eSignShareURL"
                          defaultValue={1}
                          defaultChecked=""
                        />
                        <label />
                      </div>
                    </div> */}
                    <div className="col-12">Share URL Now</div>
                    <div className="col-12 eSignShareURLOpt">via</div>
                    <div className="col-12 eSignShareURLOpt">
                      <select
                        className="form-select eSignShareWithUrlOpts"
                        onChange={e => setHistoryType(e.target.value)}
                      >
                        <option value="email">Email</option>
                        <option value="SMS">SMS Text Message</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-2 eSignShareWithCopyRow d-none">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control eSignShareWithCopyInput"
                        readOnly=""
                        defaultValue="https://MAPPEDURLHERE.com/{customername}"
                      />
                      <button
                        type="button"
                        className="btn btn-secondary btneSignShareWithEdit"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Edit"
                        aria-label="Edit"
                      >
                        <i className="bi bi-pencil-fill" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btneSignShareWithCopy"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Copy"
                        aria-label="Copy"
                      >
                        <i className="bi bi-files" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btneSignShareWithSave d-none"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Save"
                        aria-label="Save"
                      >
                        <i className="bi bi-check2" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row g-0 align-items-center socialCustomizeURL d-none">
          <div className="col-lg-12 mb-2 socialCustomizeURLContainer">
            <div className="justify-content-start me-auto">
              <div className="input-group">
                <input
                  type="text"
                  id="btnCopySocialLinkInput"
                  className="form-control"
                  placeholder="https://"
                  defaultValue="https://click.swiftcrm.com/{username}/customurlhere"
                />
                <button type="button" className="btn btn-primary">
                  <i className="bi bi-check2" /> Save URL <i className="bi bi-link-45deg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 align-items-center">
          <div className="col-sm-6">&nbsp;</div>
          <div className="col-sm-6 text-end">
            <button
              type="button"
              onClick={handleShareContact}
              className="btn btn-primary btn-lg btnShareNewDoc btnShareSession btnSaveSend sendLinkFromDoc"
            >
              <i className="bi bi-check2" /> Save &amp; Send Link <i className="bi bi-send-fill" />
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ShareContactDetailModal
