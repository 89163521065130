import React from "react"
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import {
  ALL_PRIVACY,
  PRIVACY_NOT_SHARED,
  PRIVACY_PASSWORD_PROTECTED,
  PRIVACY_PAY_WALLED,
  PRIVACY_PRIVATE,
  PRIVACY_UNLISTED,
} from "../Doc/Service/moreOprtionsHelper"

const Privacy = ({ docPrivacy, setDocPrivacy, privacyPassword, previewUrl }) => {
  const filterDocPrivacy = privacy => {
    let result = ALL_PRIVACY.filter(p => {
      return p.value === privacy
    })

    return result.length
      ? result[0]
      : {
          value: PRIVACY_NOT_SHARED,
          label: "Me Only; not shared",
          icon: "bi bi-incognito",
        }
  }
  const defaultPrivacy = filterDocPrivacy(docPrivacy)

  return (
    <div className="dh-block-body">
      <strong>
        <i className="bi bi-eyeglasses" /> Who can view and use this?
      </strong>
      <div className="row row-cols-sm-auto g-2 align-items-center">
        <div className="col-12">
          <Dropdown className="socialPermission">
            <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
              <>
                <i className={defaultPrivacy.icon} /> {defaultPrivacy.label}
              </>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {ALL_PRIVACY.map((AllPrivacy, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    value={AllPrivacy.value}
                    href="#"
                    onClick={() => {
                      setDocPrivacy(AllPrivacy.value)
                    }}
                  >
                    <i className={AllPrivacy.icon} /> {AllPrivacy.label}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {docPrivacy === PRIVACY_PRIVATE && (
          <div className="col-12 socialPermissionOpts socialPermissionInvitationOnly">
            <OverlayTrigger placement="top" overlay={<Tooltip>Invite User</Tooltip>}>
              <button
                type="button"
                className="btn btn-sm btnInviteUser doc-privacy-opt doc-privacy-opt-private mb-1 mb-sm-0"
              >
                <span>
                  <i className="bi bi-person-plus-fill" />
                </span>
              </button>
            </OverlayTrigger>
          </div>
        )}
        {docPrivacy === PRIVACY_PASSWORD_PROTECTED && (
          <div className="col-12 socialPermissionOpts socialPermissionPasswordProtected">
            <div className="input-group">
              <span className="input-group-text">Access Password:</span>
              <Form.Control
                type="text"
                name="privacy_password"
                className="form-control"
                defaultValue={privacyPassword}
              />
            </div>
          </div>
        )}
        {docPrivacy === PRIVACY_UNLISTED && docPrivacy !== "" && (
          <div className="col-12 socialPermissionOpts socialPermissionAnyonewithURL">
            <a href={previewUrl} target="_blank">
              <i className="bi bi-link-45deg" /> {previewUrl}
            </a>
          </div>
        )}
      </div>

      {docPrivacy === PRIVACY_PAY_WALLED && (
        <div className="socialPermissionOpts socialPermissionPaywalled mb-4">
          <div className="mb-3">
            Membership Paywalled, Lead Captured, or Class Required
            <div className="input-group">
              <span className="input-group-text">To access, viewer must</span>
              <select className="form-select paywallToAccess">
                <option value="beCaptured">be captured (Any Contact)</option>
                <option value="beTaggedWith">be tagged with...</option>
              </select>
            </div>
          </div>
          <div className="mb-3 paywallToAccessTaggedWithContainer d-none">
            <div className="bootstrap-tagsinput">
              <input type="text" placeholder="" />
              <button className="btn btn-sm btn-primary btnSaveTag">
                <i className="fa fa-save" />
              </button>
            </div>
            <input type="text" name="paywallToAccessTaggedWith" className="paywallToAccessTaggedWith" />
          </div>
          <div className="mb-3">
            Signup Page: If viewer is not yet allowed, send them to...{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Signup page to capture them as a lead, or for them to pay, or to sign up for a class, etc.
                </Tooltip>
              }
            >
              <i className="bi bi-question-circle-fill" />
            </OverlayTrigger>
            <div className="input-group">
              <span className="input-group-text">Membership Page:</span>
              <select className="form-select">
                <option>== List of Docs ==</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Privacy
