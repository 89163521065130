import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Accordion, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import Layout from "../../Layout/layout"
import Seo from "../../seo"
import Privacy from "../privacy"
import "./settings.css"
import UniqueRows from "./UniqRows/uniqueRows"
import AxiosInstance from "../../../services/axiosInstance"
import PrimaryRow from "./primaryRow"
import WebSiteWorkRow from "./webSiteWorkRow"

const Settings = () => {
  const [settingsData, setSettingsData] = useState([])
  const [primaryEmails, setPrimaryEmails] = useState([])
  const [webSiteWork, setWebSiteWork] = useState([])

  const handleRedirect = page => {
    return navigate(page)
  }

  const getSettingsData = async () => {
    AxiosInstance.get("/settings/personal-setting/unique-data").then(response => {
      setSettingsData(response.data.uniqueData)
      setPrimaryEmails(
        response.data.uniqueData.filter(data => {
          if (data.type === "Primary") {
            return data
          }
        })
      )
      setWebSiteWork(
        response.data.uniqueData.filter(data => {
          if (data.type === "WebsiteWork") {
            return data
          }
        })
      )
    })
  }

  useEffect(() => {
    getSettingsData()
  }, [])

  return (
    <Layout>
      <Seo title="Settings" />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <Form>
            <div className="col" id="addressbook">
              <UniqueRows />
              <div className="col-lg-12  mb-3 contactDetailsContainer">
                <div className="overflow-hidden px-3 px-md-0">
                  {settingsData.map((settingData, index) => {
                    return (
                      <React.Fragment key={index}>
                        {settingData.type === "Primary" && <PrimaryRow settingData={settingData} />}
                        {settingData.type === "WebsiteWork" && <WebSiteWorkRow settingData={settingData} />}
                      </React.Fragment>
                    )
                  })}
                  {primaryEmails.length === 0 && (
                    <PrimaryRow settingData={{ privacy: "hidden", value: "" }} />
                  )}
                  {webSiteWork.length === 0 && (
                    <WebSiteWorkRow settingData={{ privacy: "hidden", value: "" }} />
                  )}

                  {/*<div className="row adbook-user-other-info align-items-center py-3 border-bottom">
                        <div className="col-md-4 text-md-end text-start">
                          <label>
                            <i className="bi bi-robot" data-bs-toggle="tooltip" data-bs-title="Found by AImo" />{" "}
                            Twitter
                          </label>
                        </div>
                        <div className="col-md-2 text-center privacy-col">
                          <Privacy />
                        </div>
                        <div className="col-md-4 inputbox">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            defaultValue="https://twitter.com/SrBachchan"
                          />
                        </div>
                        <div className="col-md-2">
                          <a
                            href="#"
                            className="btn btnAddNewSuggested"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Accept &amp; Add"
                            aria-label="Accept &amp; Add"
                          >
                            <i className="bi bi-check2" />
                          </a>
                        </div>
                      </div> */}

                  <div className="row align-items-center py-3 border-bottom">
                    <div className="col-md-4" />
                    <div className="col-md-2 text-center">
                      <OverlayTrigger placement="top" overlay={<Tooltip>Add New</Tooltip>}>
                        <a
                          href="#"
                          className="btnAddNewUserDetail"
                          data-bs-toggle="tooltip"
                          data-bs-title="Add New"
                        >
                          <i className="bi bi-plus-lg" />
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="col-md-6" />
                  </div>
                  <div className="row adbook-user-other-info align-items-center py-3 border-bottom addNewDetailsContainer d-none">
                    <div className="col-md-4 text-md-end text-start">
                      <div className="dropdown CDInfoType">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-phone" /> Mobile
                        </button>
                        <ul className="dropdown-menu scrollable-menu">
                          <li>
                            <strong className="dropdown-item-text">== PHONES ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Mobile" href="#">
                              <i className="bi bi-phone" /> Mobile
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="MainNo" href="#">
                              <i className="bi bi-telephone-fill" /> Main
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="PersonalNo" href="#">
                              <i className="bi bi-telephone-fill" /> Personal
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="BusinessNo" href="#">
                              <i className="bi bi-telephone-fill" /> Business
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="iPhoneNo" href="#">
                              <i className="bi bi-phone" /> iPhone
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="FaxNo" href="#">
                              <i className="bi bi-printer" /> Fax
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== EMAILS ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Primarymail" href="#">
                              <i className="bi bi-envelope-fill" /> Primary
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="WorkMail" href="#">
                              <i className="bi bi-envelope-fill" /> Work
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="PersonalMail" href="#">
                              <i className="bi bi-envelope-fill" /> Personal
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="PayPalMail" href="#">
                              <i className="bi bi-envelope-fill" /> PayPal
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== ADDRESSES ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="HomeAddress" href="#">
                              <i className="bi bi-house-door-fill" /> Home
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="MailingAddress" href="#">
                              <i className="bi bi-house-door-fill" /> Mailing
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="BillingAddress" href="#">
                              <i className="bi bi-house-door-fill" /> Billing
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="WorkAddress" href="#">
                              <i className="bi bi-house-door-fill" /> Work
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="OtherAddress" href="#">
                              <i className="bi bi-house-door-fill" /> Other
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== MESSENGERS ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Skype" href="#">
                              <i className="bi bi-skype" /> Skype
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Google Chat" href="#">
                              <i className="bi bi-google" /> Google Chat
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Telegram" href="#">
                              <i className="bi bi-telegram" /> Telegram
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Whatsapp" href="#">
                              <i className="bi bi-whatsapp" /> Whatsapp
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== CALENDAR ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Birthday" href="#">
                              <i className="bi bi-calendar-date-fill" /> Birthday
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Anniversary" href="#">
                              <i className="bi bi-bell-fill" /> Anniversary
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Other Date" href="#">
                              <i className="bi bi-bell-fill" /> Other Date
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== EMPLOYMENT ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="CompanyName" href="#">
                              <i className="bi bi-building-fill" /> Company Name
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="ContactatCompany" href="#">
                              <i className="bi bi-person-fill" /> Contact at Company
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Job Title" href="#">
                              <i className="bi bi-mortarboard-fill" /> Job Title
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Link to CV / Resume" href="#">
                              <i className="bi bi-file-earmark-pdf-fill" /> Link to CV / Resume
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Former Employer URL" href="#">
                              <i className="bi bi-link-45deg" /> Former Employer URL
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Portfolio / Work Example" href="#">
                              <i className="bi bi-briefcase-fill" /> Portfolio / Work Example
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Employer" href="#">
                              <i className="bi bi-person-fill" /> Employer
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== LINKS ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Website Personal" href="#">
                              <i className="bi bi-link-45deg" /> Website Personal
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Website (Work)" href="#">
                              <i className="bi bi-link-45deg" /> Website (Work)
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="URL Mapped to SwiftCloud" href="#">
                              <i className="bi bi-link-45deg" /> URL Mapped to SwiftCloud
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="RSS (Follow)" href="#">
                              <i className="bi bi-rss-fill" /> RSS (Follow)
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Facebook" href="#">
                              <i className="bi bi-facebook" /> Facebook
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Google" href="#">
                              <i className="bi bi-google" /> Google
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Twitter" href="#">
                              <i className="bi bi-twitter" /> Twitter
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Linkedin" href="#">
                              <i className="bi bi-linkedin" /> Linkedin
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="GitHub" href="#">
                              <i className="bi bi-github" /> GitHub
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="YouTube" href="#">
                              <i className="bi bi-youtube" /> YouTube
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== CUSTOM FIELDS ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="customField" href="#">
                              <i className="bi bi-pencil-square" /> Custom Fields
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== RELATIONSHIPS ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Referrer" href="#">
                              <i className="bi bi-person-fill" /> Referred By
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="ReferredUsTo" href="#">
                              <i className="bi bi-person-fill" /> Referred us to
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="spouseAccount" href="#">
                              <i className="bi bi-person-fill" /> Spouse
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="BusinessPartner" href="#">
                              <i className="bi bi-person-fill" /> Business Partner
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="parentAccount" href="#">
                              <i className="bi bi-people-fill" /> Parent
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="childAccount" href="#">
                              <i className="bi bi-people-fill" /> Child
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="OtherRelationship" href="#">
                              <i className="bi bi-person-fill" /> Other Relationship
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="CustomRole" href="#">
                              <i className="bi bi-person-fill" /> CUSTOM ROLES SHOW HERE
                            </a>
                          </li>
                          <li>
                            <strong className="dropdown-item-text">== PUBLIC PROFILE ==</strong>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="PrimaryCategory" href="#">
                              <i className="bi bi-briefcase-fill" /> Category (Primary)
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="AdditionalCategory" href="#">
                              <i className="bi bi-briefcase-fill" /> Category (Additional)
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="Founded" href="#">
                              <i className="bi bi-person-fill" /> Founded
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="MeetingClients" href="#">
                              <i className="bi bi-calendar3" /> Meeting Clients
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="ServiceRadiusMiles" href="#">
                              <i className="bi bi-person-fill" /> Service Radius (Miles)
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="ServiceRadiusKm" href="#">
                              <i className="bi bi-person-fill" /> Service Radius (km)
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" data-type="LicenseNumber" href="#">
                              <i className="bi bi-person-fill" /> License Number
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-2 text-center privacy-col">
                      <div className="dropdown adbook-user-data-privacy">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-globe" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-eye-slash-fill" /> Hidden / Secret
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-people-fill" /> Connections only
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-globe" /> Public
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 inputbox">
                      <input
                        type="text"
                        name="customDetailName"
                        id="customDetailName"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2 inputbox">
                      <button
                        className="btn btn-primary"
                        id="saveDetails"
                        data-bs-toggle="tooltip"
                        data-bs-title="Save"
                      >
                        <i className="bi bi-check2" />
                      </button>
                      <button
                        className="btn btn-trash"
                        id="cancelDetails"
                        data-bs-toggle="tooltip"
                        data-bs-title="Cancel"
                      >
                        <i className="bi bi-x-lg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div className="row g-0 pt-5">
          <div className="col-lg-12 generalSettings">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => handleRedirect("business")}>
                  <i className="bi bi-briefcase-fill me-2" /> About Your Business
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => handleRedirect("access")}>
                  <i className="bi bi-box-arrow-in-right me-2" /> Accessing SwiftCloud
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header onClick={() => handleRedirect("language")}>
                  <i className="bi bi-globe me-2" /> Language & Location
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <i className="bi bi-lock-fill me-2" /> Privacy & Permissions
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <i className="bi bi-bullseye me-2" /> Radar & Social Media
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-lg-12 text-center mt-3">
            <button type="button" className="btn btn-primary btn-lg">
              <i className="bi bi-check2" /> Save Settings
            </button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Settings
