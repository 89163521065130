import React, { useEffect, useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import Axios from "axios"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import LoginFooter from "./loginFooter"
import TermsAndConditions from "./termsAndConditions"
import SocialMediaLogin from "./SocialMediaLogin"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../components/login.css"
import "@fontsource/noto-sans"
import AxiosInstance from "../services/axiosInstance"
import { loadStripe } from "@stripe/stripe-js"

const Signup = () => {
  const [errorMessage, setErrorMessage] = useState(""),
    [values, setValues] = useState({
      loading: false,
    })

  const { loading } = values
  const [cloneAccountType, setCloneAccountType] = useState([])
  const [formId, setFormId] = useState("")
  const [stripe, setStripe] = useState(null)
  const [elements, setElements] = useState(null)
  const [cardElement, setCardElement] = useState(null)
  const [isForItem, setIsForItem] = useState(false)
  const [productDetail, setProductDetail] = useState([])
  const [itemId, setItemId] = useState("")
  const [accountId, setAccountId] = useState("")

  const returnUrl = "https://swiftcloud.ai/support/welcome/?chat=newuserpwreset"
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHER_KEY)
  const cardElementRef = useRef(null)

  const handleCloneAccountChange = event => {
    const selectedValue = event.target.value
    setAccountId(selectedValue)
    const selectedAccount = cloneAccountType.find(account => account.id == selectedValue)
    if (selectedAccount) {
      setFormId(selectedAccount.formId)
    }
  }

  const getProductDetail = itemId => {
    Axios.get(`${process.env.GATSBY_API_URL}/signup/get-item`, {
      params: { item_id: itemId },
    })
      .then(response => {
        if (response.data) {
          setProductDetail(response.data.data)
        }
      })
      .catch(error => {
        if (error.response?.data?.is_redirect) {
          window.location.href = "/signup"
        }
      })
  }

  const handleSignup = async e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const name = formData.get("name")
    const email = formData.get("email")
    const referred_by = formData.get("referred_by") ? formData.get("referred_by") : null
    const account_type = formData.get("account_type")
    const sellerId = productDetail.sellerId
    const driveItemId = productDetail.drive_item_id
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!name) {
      alert("Please enter name.")
      return false
    }
    if (!email) {
      alert("Please enter email.")
      return false
    }

    if (emailRegex.test(email) === false) {
      alert("Please enter valid email address.")
      return false
    }

    setValues({
      ...values,
      loading: true,
    })

    if (!itemId) {
      if (!referred_by) {
        alert("Please enter referred by.")
        setValues({
          ...values,
          loading: false,
        })
        return false
      }

      await signupUser(name, email, referred_by, account_type, true, null, null)
      return true
    }

    if (!stripe || !elements) {
      setValues({
        ...values,
        loading: false,
      })
      return
    }

    const { token, error: stripeError } = await stripe.createToken(cardElement)
    if (stripeError) {
      setErrorMessage(stripeError.message)
      setValues({
        ...values,
        loading: false,
      })
      return
    }

    try {
      const urlParams = new URLSearchParams(window.location.search)
      const metadata = Object.fromEntries(urlParams.entries())
      const stripeTokenId = token.id

      const response = await Axios.post(
        process.env.GATSBY_API_URL + "/api/drive/form-handling/stripe-create-charge-item",
        {
          name,
          email,
          stripeTokenId,
          sellerId: sellerId,
          itemId: driveItemId,
          metadata,
          isOrderBump: false,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const createChargeId = response.data.data.id
      if (response.data.type !== "subscription") {
        const client_secret = response.data.data.client_secret

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name,
            },
          },
        })

        if (confirmError) {
          setErrorMessage(confirmError.message)
          return
        }

        if (paymentIntent.status === "succeeded") {
          await Axios.post(
            process.env.GATSBY_API_URL + "/api/drive/form-handling/update-latest-payment",
            {
              paymentIntentData: response.data.data,
              name: name,
              email: email,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
            .then(response => {
              if (response.data.status === "succeeded") {
                signupUser(
                  name,
                  email,
                  referred_by,
                  account_type,
                  false,
                  createChargeId,
                  productDetail.paymentType
                )
                window.location.href = returnUrl
              }
            })
            .catch(error => {
              setErrorMessage(error.response.data.message)
              setValues({
                ...values,
                loading: false,
              })
            })
        }
      } else {
        await signupUser(
          name,
          email,
          referred_by,
          account_type,
          false,
          createChargeId,
          productDetail.paymentType
        )
        window.location.href = returnUrl
      }
    } catch (error) {
      if (error.response.data.detail) {
        alert(error.response.data.detail)
      } else {
        alert(error.response?.data?.message)
      }
    } finally {
      setValues({
        ...values,
        loading: false,
      })
    }
    setErrorMessage("")
  }

  const signupUser = async (
    name,
    email,
    referred_by,
    account_type,
    referred_by_required,
    create_charge_id,
    payment_type
  ) => {
    if (name && email) {
      await Axios.post(process.env.GATSBY_API_URL + "/signup/", {
        name,
        email,
        referred_by,
        account_type,
        referred_by_required,
        create_charge_id,
        payment_type,
      })
        .then(response => {
          if (response.data.status) {
            if (!formId) {
              navigate("https://swiftcloud.ai/support/welcome/?chat=newuserpwreset")
              return response.data
            }

            const formData = {
              name,
              email_spam: email,
              email: "",
              referred_by_email: referred_by,
              formId: formId,
              browser: navigator.userAgent,
              trackingvars: window.location.href,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              language: window.navigator.userLanguage || window.navigator.language,
              capturepage: window.location.href,
              browserResolution: `${window.innerWidth}x${window.innerHeight}px`,
              referer: window.location.href,
            }

            AxiosInstance.post(`/drive/form-handling/formAdd`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then(response => {
                navigate("https://swiftcloud.ai/support/welcome/?chat=newuserpwreset")
              })
              .catch(error => {
                console.log(error)
                navigate("https://swiftcloud.ai/support/welcome/?chat=newuserpwreset")
              })
          }
          return response.data
        })
        .catch(error => {
          setErrorMessage(error.response.data.message)
          setValues({
            ...values,
            loading: false,
          })
        })
    }
  }

  const getIndustriesCloneAccount = () => {
    Axios.get(process.env.GATSBY_API_URL + "/industry/clone-account/list")
      .then(response => {
        if (response.data) {
          setCloneAccountType(response.data.data)
        }
      })
      .catch(error => {
        setErrorMessage(error.response.data.message)
      })
  }

  useEffect(() => {
    getIndustriesCloneAccount()
    const searchParams = new URLSearchParams(window.location.search)
    const urlItemId = searchParams.get("p")
    setItemId(urlItemId)

    const urlAccountId = searchParams.get("at")
    setAccountId(urlAccountId)

    if (urlItemId) {
      setIsForItem(true)
      getProductDetail(urlItemId)

      const initializeStripe = async () => {
        const stripeInstance = await stripePromise
        const elementsInstance = stripeInstance.elements()
        const cardElementInstance = elementsInstance.create("card")
        cardElementInstance.mount(cardElementRef.current)

        setStripe(stripeInstance)
        setElements(elementsInstance)
        setCardElement(cardElementInstance)

        return { stripe, elements, cardElement }
      }
      initializeStripe()
    }
    Object.assign(document.body.style, backgroundImageStyle)
  }, [])

  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.GATSBY_WRANGLER_URL}StaticImages/homeblur.png)`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <section className={`loginSection${isForItem ? " signupWithProduct" : ""}`}>
        <div className="row align-items-center">
          <div
            className={`${
              isForItem
                ? "signupContainer col-md-6 col-xs-12 text-center order-1 order-md-1 order-sm-2"
                : "loginContainer"
            }`}
          >
            <div className="row">
              <div className="col-md-12 col-xs-12 text-center">
                <div className="signup-or-separator">
                  <span className="signup-or-separator--text">Signup</span>
                  <hr />
                </div>
                <form onSubmit={handleSignup} id="loginForm" className="mt-4">
                  <div className="input-group mb-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Your Name</Tooltip>}>
                      <span
                        className="input-group-text"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                      >
                        <i className="bi bi-person-fill" />
                      </span>
                    </OverlayTrigger>
                    <input
                      id="username"
                      className="form-control"
                      type="text"
                      tabIndex="1"
                      name="name"
                      placeholder="Name"
                      required
                      autoFocus
                    />
                  </div>

                  <div className="input-group mb-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Email</Tooltip>}>
                      <span className="input-group-text">@</span>
                    </OverlayTrigger>
                    <input
                      id="username"
                      className="form-control formControlInput"
                      type="email"
                      tabIndex="1"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div className={`input-group mb-3 ${isForItem ? " d-none" : ""}`}>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Referred By</Tooltip>}>
                      <span className="input-group-text">
                        <i className="bi bi-person-plus" />
                      </span>
                    </OverlayTrigger>
                    <input
                      id="referred_by"
                      className="form-control formControlInput"
                      type="email"
                      tabIndex="1"
                      name="referred_by"
                      placeholder="Referred By"
                    />
                  </div>

                  <div className="input-group mb-3">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Optional: Load a Template-Account Starter Kit (Recommended unless you're a power
                          user!). You can still modify everything; this will get you setup faster.
                        </Tooltip>
                      }
                    >
                      <span className="input-group-text">
                        <i className="bi bi-question-circle" />
                      </span>
                    </OverlayTrigger>

                    <select
                      className="form-select mb-0"
                      name="account_type"
                      onChange={handleCloneAccountChange}
                      value={accountId}
                    >
                      <option value="">Account Type</option>
                      {cloneAccountType.map((account, index) => (
                        <option key={index} value={account.id}>
                          {account.industry}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    className={`mb-3 SCPaymentMethodContainer SCPaymentMethodCardContainer${
                      !isForItem ? " d-none" : ""
                    }`}
                  >
                    <div ref={cardElementRef} id="card-element-for-signup"></div>
                  </div>

                  {errorMessage && <div className="text-danger">{errorMessage}</div>}

                  <div className="mb-3 text-start form-inline communication-container">
                    <div className="from-check">
                      <input
                        type="checkbox"
                        name="Communication"
                        id="Communication"
                        className="css-checkbox"
                        defaultChecked={true}
                        value="1"
                      />
                      <label htmlFor="Communication" className="css-checkbox-label radGroup2">
                        <small>
                          Communication from SwiftCloud is ok
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Email, SMS, or Phone Calls; you can opt-out anytime on any channel. Required
                                in order to access your product(s).
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill mx-1" />
                          </OverlayTrigger>
                        </small>
                      </label>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="termsCondition">
                      By signing up or logging in, you are agreeing to our fascinating{" "}
                      <a className="dashedLinked" href="https://swiftcloud.ai/terms" target="_blank">
                        terms of use
                      </a>{" "}
                      &amp;{" "}
                      <a className="dashedLinked" href="https://swiftcloud.ai/privacy" target="_blank">
                        privacy policy
                      </a>
                      .
                    </p>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      disabled={loading}
                      id="loginModalButton"
                      className="btn btn-primary w-100"
                    >
                      <i className="bi bi-check2" /> Create Account{" "}
                      {loading && <i className="spinner-border spinner-border-sm" />}
                    </button>
                  </div>
                </form>
                <hr />
                <div className="text-center">
                  <p>
                    Already Hooked Up?{" "}
                    <Link to="/login" className="btn btn-secondary">
                      <i className="bi bi-arrow-right-circle" /> Login
                    </Link>
                  </p>
                </div>
                <TermsAndConditions />
              </div>
            </div>
          </div>
          {isForItem && (
            <div className="col-lg-6 col-md-6 col-xs-12 text-start px-0 brandLogoWithProduct  order-md-1 order-sm-1">
              <div className="row signup-products g-0 g-md-4">
                <img
                  className="companylogo companyLogoImage"
                  src={`${process.env.GATSBY_WRANGLER_URL}${"StaticImages/SwiftCloud-logo.png"}`}
                />
                <div className="col-lg-12 col-md-12 col-xs-12 text-start">
                  <div className="stepwizard mx-auto">
                    <div className="stepwizard-row setup-panel">
                      <div className="stepwizard-step">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Signup</Tooltip>}>
                          <a href="#" data-step="step-1" data-bs-toggle="tooltip" className="btn btn-round">
                            <i className="bi bi-power" />
                          </a>
                        </OverlayTrigger>
                      </div>
                      <div className="stepwizard-step">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Setup</Tooltip>}>
                          <a href="#" data-step="step-2" data-bs-toggle="tooltip" className="btn btn-round">
                            <i className="bi bi-plug-fill" />
                          </a>
                        </OverlayTrigger>
                      </div>
                      <div className="stepwizard-step">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Welcome</Tooltip>}>
                          <a href="#" data-step="step-3" data-bs-toggle="tooltip" className="btn btn-round">
                            <i className="bi bi-rocket-takeoff-fill" />
                          </a>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="text-start">
                    <h3 className="py-3 text-center">{productDetail.name}</h3>
                    <hr />
                    <div className="row">
                      <div className="col-7">Today: </div>
                      <div className="col-5 text-end">
                        $<span id="itemPriceSpan">{productDetail.price}</span>
                      </div>
                    </div>
                    {productDetail.paymentType === "recurring" && (
                      <>
                        <hr />
                        <div className="row">
                          <div className="col-7">Each {productDetail.paymentPeriod}:</div>
                          <div className="col-5 text-end">
                            $<span id="itemPriceSpan">{productDetail.price}</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row align-items-center pt-5">
                    <div className="col-sm-6 text-start sequrityInfo text-center">
                      <p className="mb-0">
                        <i className="bi bi-lock-fill" /> Secured SSL
                      </p>
                    </div>
                    <div className="col-sm-6 guaranteeSealImg text-center sequrityInfo">
                      <p className="mb-0">
                        <i className="bi bi-shield-fill" /> Privacy Assured
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <LoginFooter />
    </React.Fragment>
  )
}

export default Signup
