import React, { useEffect, useState } from "react"
import Country from "./countryDropdown"
import LanguageDropdown from "./languageDropdown"
import TimeZoneDropdown from "./timeZoneDropdown"
import CommonLanguageDropdown from "./commonLanguageDropdown"
import Currency from "./currency"
import AxiosInstance from "../../../services/axiosInstance"

const LanguageInfo = () => {
  const [country, setCountry] = useState(""),
    [language, setLanguage] = useState(""),
    [timeZone, setTimeZone] = useState(""),
    [timeFormat, setTimeFormat] = useState(""),
    [dateFormat, setDateFormat] = useState(""),
    [currency, setCurrency] = useState(""),
    [numberFormat, setNumberFormat] = useState(""),
    [phoneFormat, setPhoneFormat] = useState(""),
    [addLanguageInfo, setAddLanguageInfo] = useState(false),
    [loadLanguageList, setLoadLanguageList] = useState(false),
    [distance, setDistance] = useState("")

  const setLanguageDetails = () => {
    AxiosInstance.get(`/settings/personal-setting/language-data`)
      .then(response => {
        if (response.data.data.length == 0) {
          setAddLanguageInfo(true)
        }
        response.data.data.map(data => {
          if (data.type == "Country") {
            setCountry(data)
          }
          if (data.type == "Language") {
            setLanguage(data)
            localStorage.setItem("languageCode", data.value)
          }
          if (data.type == "TimeZone") {
            setTimeZone(data)
          }
          if (data.type == "Currency") {
            setCurrency(data)
          }
          if (data.type == "NumberFormat") {
            setNumberFormat(data)
          }
          if (data.type == "PhoneFormat") {
            setPhoneFormat(data)
          }
          if (data.type == "TimeFormat") {
            setTimeFormat(data)
          }
          if (data.type == "DateFormat") {
            setDateFormat(data)
          }
          if (data.type == "Distance") {
            setDistance(data)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (addLanguageInfo == true) {
      let url = "/settings/personal-setting/add"
      let addAllLanguageFields = [
        { type: "Country", value: "US" },
        { type: "Language", value: "en-us" },
        { type: "TimeZone", value: "America/New_York" },
        { type: "Currency", value: "USD" },
        { type: "NumberFormat", value: "X,XXX.yy" },
        { type: "PhoneFormat", value: "USA" },
        { type: "TimeFormat", value: "12 Hour Clock" },
        { type: "DateFormat", value: "MM/DD/YYYY" },
        { type: "Distance", value: "Kilometers" },
      ]
      addAllLanguageFields.map(languageFields => {
        AxiosInstance.post(url, {
          type: languageFields.type,
          value: languageFields.value,
          privacy: "public",
        })
          .then(response => {})
          .catch(function (error) {})
      })
      setAddLanguageInfo(false)
      setLoadLanguageList(true)
    }

    {
      if (addLanguageInfo === false && loadLanguageList === true) {
        setLoadLanguageList(false)
        setLanguageDetails()
      }
    }
  })

  useEffect(() => {
    setLanguageDetails()
  }, [])

  let timeFormattingOptions = [
    { value: "24 Hour Clock", label: "24 Hour Clock" },
    { value: "12 Hour Clock", label: "12 Hour AM & PM" },
  ]

  let dateFormattingOptions = [
    { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
    { value: "YYYY/MM/DD", label: "YYYY/MM/DD" },
  ]

  let numberFormattingOptions = [
    { value: "X,XXX.yy", label: "X,XXX.yy" },
    { value: "X.XXX,yy", label: "X.XXX,yy" },
  ]

  let phoneFormattingOptions = [
    { value: "USA", label: "XXX-XXX-XXXX" },
    { value: "Europe", label: "XXXX-XXX-XXX" },
    { value: "India", label: "XXXXX-XXXXX" },
  ]

  let distanceOptions = [
    { value: "Kilometers", label: "Kilometers" },
    { value: "Miles", label: "Miles" },
  ]

  return (
    <>
      <Country data={country} />

      <LanguageDropdown data={language} />

      <TimeZoneDropdown data={timeZone} />

      <CommonLanguageDropdown
        label={"Time Formatting"}
        selectName={"TimeFormat"}
        selectId={"TimeFormat"}
        options={timeFormattingOptions}
        data={timeFormat}
      />

      <CommonLanguageDropdown
        label={"Date Formatting"}
        selectName={"DateFormat"}
        selectId={"DateFormat"}
        options={dateFormattingOptions}
        data={dateFormat}
      />

      <Currency data={currency} />

      <CommonLanguageDropdown
        label={"Number Format"}
        selectName={"NumberFormat"}
        selectId={"NumberFormat"}
        options={numberFormattingOptions}
        data={numberFormat}
      />

      <CommonLanguageDropdown
        label={"Phone Format"}
        selectName={"PhoneFormat"}
        selectId={"PhoneFormat"}
        options={phoneFormattingOptions}
        data={phoneFormat}
      />

      <CommonLanguageDropdown
        label={"Distance"}
        selectName={"Distance"}
        selectId={"Distance"}
        options={distanceOptions}
        data={distance}
      />
    </>
  )
}

export default LanguageInfo
