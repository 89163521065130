import React, { useEffect, useState } from "react"
import AxiosInstance from "../../../../services/axiosInstance"

const MaillingAddress = ({ mailAddressData }) => {
  const [updateData, setUpdateData] = useState(true),
    [mailAddress, setMailAddress] = useState(""),
    [mailAddressId, setMailAddressId] = useState(null)

  const handleSubmit = e => {
    if (mailAddress === e.target.value) {
      return false
    }
    setMailAddress(e.target.value)

    let url = "/settings/personal-setting/add"
    if (mailAddressId) {
      url = `/settings/personal-setting/edit/${mailAddressId}`
    }

    AxiosInstance.post(url, {
      type: "MailingAddress",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setMailAddressId(response.data.id)
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (mailAddressId === null && mailAddressData) {
      setUpdateData(false)
      setMailAddressId(mailAddressData.id)
      setMailAddress(mailAddressData.value)
    }
  })

  return (
    <div className="row adbook-user-other-info align-items-center py-3 border-top border-bottom">
      <div className="col-md-4 col-sm-8 col text-md-end text-start">
        <i className="bi bi-envelope"></i> <label>Mailing Address</label>
      </div>
      <div className="col-md-2 col-sm-4 col-sm-2 col text-end text-sm-center privacy-col">
        <i className="bi bi-globe" />
      </div>
      <div className="col-md-4 inputbox">
        <div className="input-group usernameInputContainer">
          <input
            type="email"
            className="form-control usernameInput "
            placeholder="Mailing Address"
            name="username"
            defaultValue={mailAddress}
            onBlur={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default MaillingAddress
