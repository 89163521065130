import React, { useState, useRef, useEffect } from "react"

const ItemPresentation = ({
  states,
  setStates,
  updateData,
  setFileToUpload,
  setGalleryFiles,
  setToDeleteFiles,
}) => {
  const [gallerySelectedIndex, setGallerySelectedIndex] = useState(null),
    [imageSrc, setImageSrc] = useState([]),
    [singleImageSrc, setSingleImageSrc] = useState("")

  const productImageRef = useRef(null)

  const onImageChange = e => {
    if (gallerySelectedIndex !== null && states?.productDetailOpt === "gallery") {
      setGalleryFiles(prev => {
        prev[gallerySelectedIndex] = e.target.files[0]

        if (e.target.files[0]) {
          addFileToDelete(states.gallery[gallerySelectedIndex])
          const reader = new FileReader()
          reader.onload = () => {
            setImageSrc(prev => {
              return prev.map((src, i) => {
                if (i === gallerySelectedIndex) {
                  return reader.result
                }
                return src
              })
            })
          }
          reader.readAsDataURL(e.target.files[0])
        }
        return [...prev]
      })
      setGallerySelectedIndex(null)
    } else {
      setFileToUpload(e.target.files[0])

      if (states?.image !== "") {
        addFileToDelete(states.image)
      }

      if (e.target.files[0]) {
        const reader = new FileReader()
        reader.onload = () => {
          setSingleImageSrc(reader.result)
        }
        reader.readAsDataURL(e.target.files[0])
      }
    }
  }

  const addFileToDelete = filename => {
    setToDeleteFiles(prev => {
      return [...prev, filename]
    })
  }

  const triggerFileUpload = (e, index) => {
    e.preventDefault()
    productImageRef.current.click()

    if (index !== undefined) {
      setGallerySelectedIndex(index)
    }
  }

  const addImageToGallery = e => {
    e.preventDefault()
    const gallery = states?.gallery || []
    setImageSrc([...imageSrc, `${process.env.GATSBY_WRANGLER_URL}${"StaticImages/shopping_cart.png"}`])
    setStates({ ...states, gallery: [...gallery, ""] })
  }

  useEffect(() => {
    const source = states?.gallery?.map((image, i) => {
      if (imageSrc[i] !== undefined && imageSrc[i].startsWith("data:image")) {
        return imageSrc[i]
      }
      return `${process.env.GATSBY_WRANGLER_URL}${image ? image : "StaticImages/shopping_cart.png"}`
    })
    setImageSrc(source || [])
    setSingleImageSrc(
      `${process.env.GATSBY_WRANGLER_URL}${states?.image || "StaticImages/shopping_cart.png"}`
    )
  }, [states.gallery])

  return (
    <div className="col-sm-12">
      {states?.productDetailOpt === "image" && (
        <div className="productImgOpt">
          <img id="productImageSource" src={singleImageSrc} />
          <a
            href="#"
            title=""
            data-bs-toggle="tooltip"
            data-bs-original-title="Upload Image"
            aria-label="Upload Image"
            onClick={triggerFileUpload}
          >
            <i className="bi bi-pencil-fill"></i>
          </a>
        </div>
      )}

      {states?.productDetailOpt === "video" && (
        <div className="productVideoOpt">
          <div className="input-group mb-3">
            <span className="input-group-text">https://www.youtube.com/watch?v=</span>
            <input
              type="text"
              name="productVideo"
              id="productVideo"
              value={states?.productVideo || ""}
              className="form-control"
              onChange={e => updateData(e, "productVideo")}
            />
          </div>
        </div>
      )}

      {states?.productDetailOpt === "gallery" && (
        <div className="productGalleryOpt">
          {states?.gallery &&
            states.gallery.map((image, index) => {
              return (
                <div className="productGalleryBlock" key={index}>
                  <img src={imageSrc[index]} />
                  <a
                    href="#"
                    title=""
                    onClick={e => triggerFileUpload(e, index)}
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Upload Image"
                    aria-label="Upload Image"
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </a>
                </div>
              )
            })}

          <button className="btnAddNewGreenDashed btnAddNewGalleryItem" onClick={addImageToGallery}>
            <i className="bi bi-plus-lg"></i>
          </button>
        </div>
      )}
      <input type="file" ref={productImageRef} onChange={onImageChange} hidden />
    </div>
  )
}

export default ItemPresentation
