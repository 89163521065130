import React, { useState } from "react"
import { Accordion, Form } from "react-bootstrap"

import AxiosInstance from "../../../services/axiosInstance"

const ChangePassword = () => {
  const [errorMessage, setErrorMessage] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const currentPassword = formData.get("currentPassword")
    const newPassword = formData.get("newPassword")
    const repeatPassword = formData.get("repeatPassword")
    if (currentPassword && newPassword && repeatPassword) {
      return new Promise(async resolve => {
        await AxiosInstance.post(`/personal-setting/change-password/`, {
          currentPassword: currentPassword,
          newPassword: newPassword,
          repeatPassword: repeatPassword,
        })
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.status)
            }
            window.location = window.location.href.split("?")[0]
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              setErrorMessage(error.response.data)
            }
            {
              console.log(error)
            }
          })
      })
    }
  }

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>Password Change</Accordion.Header>
      <Accordion.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-8 col-sm-12 col-md-12 col-xs-12 mx-auto">
              <div className="mb-3 row">
                <label className="col-sm-4 col-form-label text-end">Current Password</label>
                <div className="col-sm-8">
                  <Form.Control
                    type="password"
                    className="form-control"
                    placeholder="Current password"
                    name="currentPassword"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-sm-4 col-form-label text-end">New Password</label>
                <div className="col-sm-8">
                  <Form.Control
                    type="password"
                    className="form-control"
                    placeholder="New password"
                    name="newPassword"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-sm-4 col-form-label text-end">Confirm Password</label>
                <div className="col-sm-8">
                  <Form.Control
                    type="password"
                    className="form-control"
                    placeholder="Confirm password"
                    name="repeatPassword"
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="mb-3 row">
                  <div className="col-sm-4" />
                  <div className="col-sm-8">{errorMessage}</div>
                </div>
              )}
              <div className="mb-3 row">
                <label className="col-sm-4 col-form-label text-end" />
                <div className="col-sm-8">
                  <button className="btn btn-default" type="submit">
                    <i className="bi bi-key-fill" /> Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default ChangePassword
