import React, { useState, useEffect } from "react"
import Layout from "../../Layout/layout"
import Seo from "../../seo"
import { Form } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import { getUserName } from "../Doc/Service/docHelper"
import Detail from "./detail"
import "./sku.css"
import { Link } from "gatsby"
import { getLoginUserId } from "../../../services/auth"
import { getInvoices } from "../../Global/apiHelpers"
import Installation from "./installation/installation"
import PanelToggle from "./panelToggle"
import MoreOptions from "./moreOptions/moreOptions"
import { getPrimaryEmail, getSkus, getSequences, getLanguages, getCurrencies } from "../../Global/apiHelpers"
import ItemModal from "../Popups/itemModal"
import { changeHypertextProtocol } from "../Doc/Service/docHelper"
import UserExperienceFlow from "../Doc/UserExperienceFlow"
import { setAdditionalOptionsArray, setAutomationOptionsArray } from "../Doc/Service/docHelper"
import DataHandlingAutomation from "../Doc/DataHandlingAutomation"

const Item = ({ fileUrl }) => {
  const [states, setStates] = useState({
    prices: [{ priceType: "default" }],
    paymentType: "",
    driveType: "item",
    dhAuto: { automation_option: [] },
  })

  const [fileToUpload, setFileToUpload] = useState(null),
    [galleryFiles, setGalleryFiles] = useState([]),
    [featuredImage, setFeaturedImage] = useState(null),
    [toDeleteFiles, setToDeleteFiles] = useState([]),
    [modalShow, setModalShow] = useState(false),
    [forwardUrl, setForwardUrl] = useState(""),
    [otherOption, setOtherOption] = useState(""),
    [selectedOption, setSelectedOption] = useState(""),
    [inPersonMode, setInPersonMode] = useState(""),
    [forwardDomain, setForwardDomain] = useState(""),
    [additionalOptions, setAdditionalOptions] = useState([]),
    [inPersonModeFileType, setInPersonModeFileType] = useState(""),
    [inPersonModeFileId, setInPersonModeFileId] = useState(""),
    [inPersonModeFileURL, setInPersonModeFileURL] = useState(""),
    [onPageDownloadFile, setOnPageDownloadFile] = useState(""),
    [toECommerceItem, setToECommerceItem] = useState(""),
    [DHAtuomationData, setDHAtuomationData] = useState([]),
    [initialAutomationOption, setInitialAutomationOption] = useState(""),
    [initialIncomingOpt, setInitialIncomingOpt] = useState(""),
    [initialFolder, setInitialFolder] = useState(""),
    [initialAsA, setInitialAsA] = useState(""),
    [displayInFeedTag, setDisplayInFeedTag] = useState([]),
    [displayInFeedTempTags, setDisplayInFeedTempTags] = useState([]),
    [contactTag, setContactTag] = useState([]),
    [contactTempTags, setContactTempTags] = useState([]),
    [previewUrl, setPreviewUrl] = useState(""),
    [compressUrl, setCompressUrl] = useState(false),
    [publicBaseURLSelection, setPublicBaseURLSelection] = useState(""),
    [slug, setSlug] = useState(""),
    [primaryEmail, setPrimaryEmail] = useState(""),
    [toScheduler, setToScheduler] = useState(""),
    [driveType, setDriveType] = useState("")

  const submitItem = async e => {
    e.preventDefault()
    let {
      productDetailOpt,
      paymentType,
      prices,
      name,
      description,
      productSalesPage,
      termsDesc,
      stripeProductUrl,
      productVideo,
      permission,
      calendarEvent,
      ezLinkDetail,
      moreOptions,
      note,
      stripePaymentPeriod,
      stripeProductId,
      orderBumpHeadline,
      orderBumpDesc,
      orderBumpPrice,
    } = states

    const paymentTypeArray = ["Stripe 1-Time", "Stripe Recurring", "Stripe Variable Billing"]
    const valueExists = paymentTypeArray.includes(paymentType)

    if (valueExists) {
      orderBumpHeadline = ""
      orderBumpDesc = ""
      orderBumpPrice = ""
    }

    const productDetails = {
      productDetailOpt,
      paymentType,
      prices,
      name,
      description,
      productSalesPage,
      termsDesc,
      stripeProductUrl,
      productVideo,
      calendarEvent,
      stripePaymentPeriod,
      stripeProductId,
      orderBumpHeadline,
      orderBumpDesc,
      orderBumpPrice,
    }

    try {
      const formData = new FormData(e.target)
      const ueFlowData = {
        other_option: formData.get("otherOption"),
        selected_option: formData.get("selectedOption"),
        forward_domain: formData.get("forward_domain"),
        forward_url: formData.get("forward_url"),
        in_person_mode: formData.get("in_person_mode"),
        in_person_mode_file_type: formData.get("in_person_mode_file_type"),
        in_person_mode_file_id: formData.get("in_person_mode_file_id"),
        in_person_mode_to_url: formData.get("in_person_mode_to_url"),
        on_page_download_file: formData.get("on_page_download_file"),
        to_ecommerce_item: formData.get("to_ecommerce_item"),
        to_scheduler: formData.get("to_scheduler"),
        additional_options: setAdditionalOptionsArray(formData),
      }
      const dhAutomationData = {
        initial_automation_option: formData.get("initial_automation_option"),
        initial_incoming_opt: formData.get("initial_incoming_opt"),
        initial_folder: formData.get("initial_folder"),
        initial_as_a: formData.get("initial_as_a"),
        automation_option: setAutomationOptionsArray(formData, displayInFeedTempTags, contactTempTags),
      }

      const skuResponse = await AxiosInstance.post(
        `/drive/item/${fileUrl}`,
        {
          productDetails,
          user_id: getLoginUserId(),
          itemName: states.itemName,
          images: galleryFiles.filter(image => {
            return image != undefined
          }),
          image: fileToUpload,
          toDeleteFiles,
          permission,
          ezLinkDetail,
          ueFlow: ueFlowData,
          dhAutomation: dhAutomationData,
          moreOptions,
          featured_image: featuredImage,
          note,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (skuResponse.status === 202) {
        console.log("Update success")
      } else {
        console.error("Unexpected status code:", skuResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const username = getUserName()
  const defaultDomain = `https://${changeHypertextProtocol(process.env.GATSBY_PREVIEW_URL)}`

  const seePreview = e => {}

  useEffect(() => {
    loadInitialData()
  }, [])

  useEffect(() => {
    setPreviewUrl(compressUrl === true ? checkUrl() : `${defaultDomain}/${getUserName()}/item/${fileUrl}`)
  })

  const checkUrl = () => {
    if (publicBaseURLSelection === changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}`)) {
      return `https://${publicBaseURLSelection}/${getUserName()}/${slug}`
    } else {
      return `https://${publicBaseURLSelection}/${slug}`
    }
  }

  const loadInitialData = async () => {
    try {
      const itemResponse = await AxiosInstance.get(`/drive/item/${fileUrl}`)
      if (itemResponse.status === 200) {
        setSkuInitialData(itemResponse.data.item)
      } else {
        console.error("Unexpected status code:", itemResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const setSkuInitialData = async data => {
    setCompressUrl(data?.ezLinkDetail?.compressUrl == "true")
    setSlug(data.drive.slug ?? "")
    setPublicBaseURLSelection(data?.ezLinkDetail?.publicBaseURLSelection ?? "")
    setStates({
      ...states,
      itemName: data.itemName,
      itemId: data.id,
      objectId: data.id,
      driveId: data.drive.id,
      driveSlug: data.drive.slug,
      permission: data.permission,
      parentId: data.drive.parentID,
      paymentType: data.productDetails.paymentType || "Digital Item (e-Delivery)",
      ...data.productDetails,
      uxFlow: data.driveForm?.ueFlow ?? [],
      dhAuto: data.driveForm?.dhAutomation ?? [],
      ezLinkDetail: data.driveForm?.ezLinkDetail ?? [],
      moreOptions: data.driveForm?.moreOptions ?? [],
      invoices: await getInvoices(),
      primaryEmails: await getPrimaryEmail(),
      skus: await getSkus(),
      sequences: await getSequences(),
      languages: await getLanguages(),
      currencies: await getCurrencies(),
      showForThemPanel: Object.keys(data.driveForm?.ueFlow ?? []).length !== 0,
      showDhaPanel: Object.keys(data.driveForm?.dhAutomation ?? []).length !== 0,
      showInstallationPanel: Object.keys(data.driveForm?.ezLinkDetail ?? []).length !== 0,
      showMoreDetailsPanel: Object.keys(data.driveForm?.moreOptions ?? []).length !== 0,
      dhaIntroText: "When someone purchases this, please...",
      note: data.note,
      // previewUrl: `${defaultDomain}/${username}/item/${fileUrl}`,
      previewUrl:
        data?.ezLinkDetail?.compressUrl == "true"
          ? `${defaultDomain}/${username}/${data.drive.slug}`
          : `${defaultDomain}/${username}/item/${fileUrl}`,
    })

    setOtherOption(data.driveForm.ueFlow.other_option ?? "")
    setSelectedOption(data.driveForm.ueFlow.selected_option ?? "")
    setForwardDomain(data.driveForm.ueFlow.forward_domain ?? "")
    setForwardUrl(data.driveForm.ueFlow.forward_url ?? "")
    setAdditionalOptions(data.driveForm.ueFlow.additional_options ?? "")
    setInPersonMode(data.driveForm.ueFlow.in_person_mode ?? "")
    setInPersonModeFileType(data.driveForm.ueFlow.in_person_mode_file_type ?? "")
    setInPersonModeFileId(data.driveForm.ueFlow.in_person_mode_file_id ?? "")
    setInPersonModeFileURL(data.driveForm.ueFlow.in_person_mode_to_url ?? "")
    setOnPageDownloadFile(data.driveForm.ueFlow.on_page_download_file ?? "")
    setToECommerceItem(data.driveForm.ueFlow.to_ecommerce_item ?? "")
    setToScheduler(data.driveForm.ueFlow.to_scheduler ?? "")
    setDHAtuomationData(data.driveForm.dhAutomation.automation_option ?? "")
    setInitialAutomationOption(data.driveForm.dhAutomation.initial_automation_option ?? "")
    setInitialIncomingOpt(data.driveForm.dhAutomation.initial_incoming_opt ?? "")
    setInitialAsA(data.driveForm.dhAutomation.initial_as_a ?? "")
    setInitialFolder(data.driveForm.dhAutomation.initial_folder ?? "")
    setDriveType(data.drive.type)
  }

  const updateName = e => {
    setStates({ ...states, itemName: e.target.value })
  }

  const createCopy = e => {
    e.preventDefault()

    setModalShow(true)
  }

  return (
    <Layout>
      <Seo title={states?.itemName ? states.itemName : ""} />
      <Form onSubmit={submitItem} id={"sku"}>
        <div id="right-section" className="h-100">
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row g-0">
                    <div className="col-sm-12 mb-3">
                      <div className="pageTitle_Editor">
                        <Link
                          to={`${states.parentId ? "/drive/folder/" + states.parentId : "/drive"}`}
                          className="goBack"
                        >
                          <i className="bi bi-arrow-90deg-left" />
                        </Link>
                        <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                          <i className="bi bi-cart-fill" />
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Untitled Item"
                          value={states?.itemName ?? ""}
                          onChange={updateName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-lg-12 mb-3">
                      <div className="row g-0">
                        <Detail
                          states={states}
                          setStates={setStates}
                          setFileToUpload={setFileToUpload}
                          setGalleryFiles={setGalleryFiles}
                          setToDeleteFiles={setToDeleteFiles}
                        />

                        <UserExperienceFlow
                          otherOption={otherOption}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          forwardDomain={forwardDomain}
                          setForwardDomain={setForwardDomain}
                          forwardUrl={forwardUrl}
                          setForwardUrl={setForwardUrl}
                          additionalOptions={additionalOptions}
                          setAdditionalOptions={setAdditionalOptions}
                          inPersonMode={inPersonMode}
                          setInPersonMode={setInPersonMode}
                          inPersonModeFileType={inPersonModeFileType}
                          setInPersonModeFileType={setInPersonModeFileType}
                          inPersonModeFileId={inPersonModeFileId}
                          inPersonModeFileURL={inPersonModeFileURL}
                          onPageDownloadFile={onPageDownloadFile}
                          toECommerceItem={toECommerceItem}
                          setOtherOption={setOtherOption}
                          primaryEmail={primaryEmail}
                          showForThemPanel={states.showForThemPanel}
                          setToECommerceItem={setToECommerceItem}
                          toScheduler={toScheduler}
                          setToScheduler={setToScheduler}
                        />

                        <DataHandlingAutomation
                          DHAtuomationData={DHAtuomationData}
                          setDHAtuomationData={setDHAtuomationData}
                          initialAutomationOption={initialAutomationOption}
                          initialIncomingOpt={initialIncomingOpt}
                          initialFolder={initialFolder}
                          initialAsA={initialAsA}
                          setInitialAsA={setInitialAsA}
                          contactTag={contactTag}
                          setContactTag={setContactTag}
                          contactTempTags={contactTempTags}
                          setContactTempTags={setContactTempTags}
                          displayInFeedTag={displayInFeedTag}
                          setDisplayInFeedTag={setDisplayInFeedTag}
                          displayInFeedTempTags={displayInFeedTempTags}
                          setDisplayInFeedTempTags={setDisplayInFeedTempTags}
                          primaryEmail={primaryEmail}
                          setPrimaryEmail={setPrimaryEmail}
                          driveType={driveType}
                          setInitialIncomingOpt={setInitialIncomingOpt}
                          setInitialFolder={setInitialFolder}
                          showDhaPanel={states.showDhaPanel}
                        />

                        {states.showInstallationPanel && (
                          <Installation
                            states={states}
                            setStates={setStates}
                            previewUrl={previewUrl}
                            fileUrl={fileUrl}
                          />
                        )}

                        {states.showMoreDetailsPanel && (
                          <MoreOptions
                            states={states}
                            setStates={setStates}
                            setFeaturedImage={setFeaturedImage}
                            setToDeleteFiles={setToDeleteFiles}
                          />
                        )}

                        <PanelToggle states={states} setStates={setStates} />

                        <div className="col-lg-12 text-center">
                          <div className="btn-group btnDropdownWithSeparater ">
                            <button type="submit" className="btn btn-primary btn-lg">
                              <i className="bi bi-check2" /> Save Changes
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#" onClick={createCopy}>
                                  <i className="bi bi-layers-half"></i> Save as Copy
                                </a>
                              </li>
                            </ul>
                          </div>{" "}
                          <div className="btn-group btnDropdownWithSeparater btnOtherServicePreview">
                            <a
                              href={previewUrl}
                              type="button"
                              className="btn btn-secondary btn-lg"
                              target="_blank"
                              onClick={seePreview}
                            >
                              <i className="bi bi-box-arrow-up-right" /> Preview
                            </a>
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ marginLeft: "1px" }}
                            >
                              <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a href={previewUrl} target="_blank" className="dropdown-item">
                                  <i className="bi bi-link-45deg"></i> in eSign / eDoc
                                </a>
                              </li>
                              <li>
                                <a href={previewUrl} target="_blank" className="dropdown-item">
                                  <i className="bi bi-link-45deg"></i> in Shopping Cart
                                </a>
                              </li>
                              <li>
                                <a href="#" target="_blank" className="dropdown-item">
                                  <i className="bi bi-link-45deg"></i> as Signup Link
                                </a>
                              </li>
                            </ul>
                          </div>{" "}
                          <button
                            type="button"
                            className="btn btn-default btn-lg btnOpenChatDock  d-inline-block"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="History"
                            aria-label="History"
                          >
                            <i className="bi bi-arrow-counterclockwise"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <ItemModal skuModalShow={modalShow} setSkuModalShow={setModalShow} from={states.itemId} />
    </Layout>
  )
}

export default Item
