import AxiosInstance from "../../../services/axiosInstance"
import { InvoiceStatuses } from "../Invoice/constants"

export const DRIVE_TYPE_PDF = "pdf"
export const DRIVE_TYPE_VIDEO = "video"
export const DRIVE_TYPE_AUDIO = "audio"
export const DRIVE_TYPE_IMAGE = "image"
export const DRIVE_TYPE_INVOICE = "invoice"
export const DRIVE_TYPE_SHEET = "sheet"
export const DRIVE_TYPE_FORM = "form"
export const DRIVE_TYPE_ENVELOPE = "envelope"
export const DRIVE_TYPE_ITEM = "item"
export const DRIVE_TYPE_SEQUENCE = "sequence"
export const DRIVE_TYPE_SCHEDULER_SERVICE = "scheduler_service"
export const DRIVE_TYPE_CODE = "code"
export const DRIVE_TYPE_E_SIGN = "e_sign"
export const DRIVE_TYPE_SCHEDULER = "scheduler"
export const DRIVE_TYPE_PHONE = "twilio_phone"

export const getFileExtension = file => {
  const regex = new RegExp("[^.]+$")
  return file.match(regex)[0]
}

export const rowClass = driveType => {
  switch (driveType) {
    case DRIVE_TYPE_PDF:
      return "pdfIconBg"
    case DRIVE_TYPE_VIDEO:
      return "videoIconBg"
    case DRIVE_TYPE_AUDIO:
      return "audioIconBg"
    case DRIVE_TYPE_SHEET:
      return "spreadSheetIconBg"
    default:
      return "btn-secondary"
  }
}

export const icon = driveType => {
  switch (driveType) {
    case DRIVE_TYPE_INVOICE:
      return "bi bi-list-ul"
    case DRIVE_TYPE_SHEET:
      return "bi bi-table"
    case DRIVE_TYPE_FORM:
      return "bi bi-card-checklist"
    case DRIVE_TYPE_ENVELOPE:
      return "bi bi-envelope-fill"
    case DRIVE_TYPE_ITEM:
      return "bi bi-cart-fill"
    case DRIVE_TYPE_SEQUENCE:
      return "bi bi-three-dots"
    case DRIVE_TYPE_VIDEO:
      return "bi bi-play-fill"
    case DRIVE_TYPE_AUDIO:
      return "bi bi-file-earmark-music-fill"
    case DRIVE_TYPE_PDF:
      return "bi bi-filetype-pdf"
    case DRIVE_TYPE_E_SIGN:
      return "bi bi-file-earmark-pdf-fill"
    case DRIVE_TYPE_SCHEDULER_SERVICE:
      return "bi bi-calendar"
    case DRIVE_TYPE_SCHEDULER:
      return "bi bi-calendar2-plus-fill"
    case DRIVE_TYPE_CODE:
      return "bi bi-code-slash"
    case DRIVE_TYPE_PHONE:
      return "bi bi-telephone-fill"
    default:
      return "bi bi-file-earmark-fill"
  }
}
export const listUrl = (driveType, driveId) => {
  switch (driveType) {
    case DRIVE_TYPE_INVOICE:
      return `/drive/invoice/${driveId}`
    case DRIVE_TYPE_SHEET:
      return `/drive/spreadsheet/${driveId}`
    case DRIVE_TYPE_FORM:
      return `/drive/form/${driveId}`
    case DRIVE_TYPE_ENVELOPE:
      return "bi bi-envelope-fill"
    case DRIVE_TYPE_ITEM:
      return `/drive/item/${driveId}`
    case DRIVE_TYPE_SEQUENCE:
      return `/drive/sequence/${driveId}`
    case DRIVE_TYPE_VIDEO:
      return `/drive/file/video/${driveId}`
    case DRIVE_TYPE_AUDIO:
      return `/drive/file/audio/${driveId}`
    case DRIVE_TYPE_PDF:
      return `/drive/file/pdf/${driveId}`
    case DRIVE_TYPE_IMAGE:
      return `/drive/file/image/${driveId}`
    case DRIVE_TYPE_SCHEDULER_SERVICE:
      return "bi bi-calendar"
    case DRIVE_TYPE_CODE:
      return `/drive/code/${driveId}`
    case DRIVE_TYPE_E_SIGN:
      return `/drive/pdf/${driveId}`
    case DRIVE_TYPE_SCHEDULER:
      return `/drive/scheduler/${driveId}`
    case DRIVE_TYPE_PHONE:
      return `/drive/phone/${driveId}`
    default:
      return `/drive/doc/${driveId}`
  }
}
export const installationFormat = (driveType, ownerId, driveId) => {
  switch (driveType) {
    case DRIVE_TYPE_INVOICE:
      return `Using your Invoice ${ownerId}I${driveId}`
    case DRIVE_TYPE_SHEET:
      return `Using your Spreadsheet ${ownerId}S${driveId}`
    case DRIVE_TYPE_CODE:
      return `Using your Code ${ownerId}C${driveId}`
    case DRIVE_TYPE_SCHEDULER:
      return `Using this Scheduler ${ownerId}S${driveId}`
    case DRIVE_TYPE_AUDIO:
      return `Using your audio ${ownerId}A${driveId}`
    case DRIVE_TYPE_VIDEO:
      return `Using your video ${ownerId}V${driveId}`
    case DRIVE_TYPE_IMAGE:
      return `Using your image ${ownerId}I${driveId}`
    case DRIVE_TYPE_PDF:
      return `Using your pdf ${ownerId}P${driveId}`
    default:
      return `Using your Doc ${ownerId}F${driveId}`
  }
}
export const onGrabData = (currentPage, pages_count, status, selectedDocId, searchTerm) => {
  return new Promise(async resolve => {
    let data = {
      items: [],
      pages_count: pages_count,
    }

    if (searchTerm) {
      currentPage = 1
      pages_count = 0
    }

    const searchParams = new URLSearchParams(window.location.search)
    const searchData = searchParams.get("search")

    if (pages_count === null || currentPage <= pages_count || searchTerm) {
      data = await AxiosInstance.get(`/drive/list`, {
        params: {
          page: currentPage,
          status: status,
          parent_id: selectedDocId,
          search: searchData ?? "",
        },
      }).then(response => {
        return response.data
      })
    }
    resolve(data, pages_count)
  })
}

export const onDelete = id => {
  return new Promise(async () => {
    await AxiosInstance.delete(`/drive/` + id).then(response => {
      window.location.reload()
      return response
    })
  })
}

export const onDownload = (e, id, fileName, extension) => {
  const regex = new RegExp("[^.]+$")
  const fileExtension = fileName.match(regex)[0]

  if (fileExtension !== extension) {
    fileName += "." + extension
  }

  e.preventDefault()
  return new Promise(async () => {
    await AxiosInstance.get(`/drive/download-file/` + id, { responseType: "blob" })
      .then(response => response.data)
      .then(blob => {
        const fileurl = window.URL.createObjectURL(new Blob([blob]))

        const link = document.createElement("a")
        link.href = fileurl
        link.setAttribute("download", `${fileName}`)

        document.body.appendChild(link)

        link.click()
        link.remove()
      })
  })
}

export const getInvoiceStatus = status => {
  switch (status) {
    case InvoiceStatuses.DUE:
      return "Due"
    case InvoiceStatuses.PAID_PARTIAL:
      return "Paid Partial"
    case InvoiceStatuses.PAID:
      return "PAID"
    case InvoiceStatuses.CANCELED:
      return "Canceled"
    case InvoiceStatuses.REFUNDED:
      return "Refunded"
    case InvoiceStatuses.UNPAID_OVERDUE:
      return "Unpaid Overdue"
    case InvoiceStatuses.NOT_YET_COMMITTED:
      return "Not Yet Committed"
    case InvoiceStatuses.COMMITTED:
      return "Committed"
    default:
      return ""
  }
}
