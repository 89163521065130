import * as React from "react"
import { useEffect, useState } from "react"
import { Collapse } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import { save } from "../AboutYourBusiness/helper"

const IncomingMoneySettings = ({ showIncomingSettings, setShowIncomingSettings }) => {
  const [value, setValue] = useState("")
  const [id, setId] = useState("")

  const handleSave = () => {
    save({ id, type: "StatementDescriptor", value, setValue, setId })
    setShowIncomingSettings(false)
  }

  const getSettingsData = async () => {
    AxiosInstance.get("/settings/general-setting/unique-data").then(response => {
      response.data.uniqueData.map(data => {
        if (data.type === "StatementDescriptor") {
          setId(data.id)
          setValue(data.value)
        }
      })
    })
  }

  useEffect(() => {
    getSettingsData()
  }, [])

  return (
    <div className="row">
      <Collapse in={showIncomingSettings}>
        <div className="col-lg-7 col-md-10 mb-3 mx-auto addNewPaymentAccountContainer">
          <div id="FrmAddNewfinancialAccount" className="form-horizontal">
            <div className="row ml-0 mr-0">
              <div className="col-lg-12 colHeading">
                <strong>
                  <i className="bi bi-box-arrow-in-right" /> Incoming Money Settings
                </strong>
              </div>
              <div className="col-lg-12 dh-block-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 addNewGrayRoundBorder">
                      <div className="row align-items-center">
                        <label htmlFor="statement_descriptor" className="col-sm-4 col-form-label">
                          <i className="bi bi-credit-card-2-back-fill" /> Statement Descriptor{" "}
                          <span
                            data-bs-toggle="tooltip"
                            title="This will show on credit card statements and should ideally be your web URL or company name."
                          >
                            <i className="bi bi-question-circle-fill" />
                          </span>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            name="statement_descriptor"
                            id="statement_descriptor"
                            maxLength="22"
                            className="form-control"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label className="col-sm-4" />
                      <div className="col-sm-8">
                        <button
                          type="button"
                          className="btn btn-default btnCancelAddNewAccount"
                          onClick={() => setShowIncomingSettings(!showIncomingSettings)}
                        >
                          <i className="bi bi-x-lg" /> Cancel
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-primary btnAddAccount"
                          onClick={() => handleSave()}
                        >
                          <i className="bi bi-check2" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default IncomingMoneySettings
