import React, { useState, useEffect } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import AxiosInstance from "../../../../services/axiosInstance"
import "../settings.css"

const FullNameRow = ({ firstNameData, lastNameData }) => {
  const [updateData, setUpdateData] = useState(true),
    [firstName, setFirstName] = useState(""),
    [firstNameId, setFirstNameId] = useState(null),
    [lastName, setLastName] = useState(""),
    [lastNameId, setLastNameId] = useState(null),
    [fullName, setFullName] = useState(""),
    [errorMessage, setErrorMessage] = useState("")

  const handleSubmit = e => {
    setErrorMessage("")
    const updatedName = e.target.value.trim()

    if (fullName === updatedName) {
      return false
    }

    const fullNameArray = updatedName.split(" ")
    const firstName = fullNameArray[0]
    const lastName = fullNameArray[1]

    if (firstName) {
      let firstNameUrl = "/settings/personal-setting/add"
      if (firstNameId) {
        firstNameUrl = `/settings/personal-setting/edit/${firstNameId}`
      }

      AxiosInstance.post(firstNameUrl, {
        type: "FirstName",
        value: firstName,
        privacy: "public",
      })
        .then(response => {
          setFirstNameId(response.data.id)
          setFirstName(firstName)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setErrorMessage("First name can not be too short.")
          }
        })
    }

    if (lastName) {
      let lastNameUrl = "/settings/personal-setting/add"
      if (lastNameId) {
        lastNameUrl = `/settings/personal-setting/edit/${lastNameId}`
      }

      AxiosInstance.post(lastNameUrl, {
        type: "LastName",
        value: lastName,
        privacy: "public",
      })
        .then(response => {
          setLastNameId(response.data.id)
          setLastName(lastName)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setErrorMessage("Last name can not be too short.")
          }
        })
    }
    window.localStorage.setItem("FirstName", firstName)
    window.localStorage.setItem("LastName", lastName)
    setFullName(`${firstName} ${lastName}`.trim())
  }

  useEffect(() => {
    if (firstNameId === null && firstNameData) {
      setUpdateData(false)
      setFirstNameId(firstNameData.id)
      setFirstName(firstNameData.value)
    }
    if (lastNameId === null && lastNameData) {
      setUpdateData(false)
      setLastNameId(lastNameData.id)
      setLastName(lastNameData.value)
    }

    setFullName(`${firstName} ${lastName}`.trim())
  })

  return (
    <div className="row g-0">
      <div className="col-sm-9 ">
        <div className="pageTitle_Editor pb-3">
          <a href="/home" className="goBack">
            <i className="bi bi-arrow-90deg-left"></i>
          </a>
          <OverlayTrigger placement="right" overlay={<Tooltip>Switch to Company</Tooltip>}>
            <Link to="/settings/business" className="CD_UserType isCompany">
              <i className="bi bi-person-fill" />
            </Link>
          </OverlayTrigger>
          <input
            type="text"
            className={`form-control`}
            name="fullName"
            placeholder="Your Real Name - Verifiable"
            defaultValue={fullName}
            onBlur={handleSubmit}
          />
          {errorMessage && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="ms-1 d-inline-block">{errorMessage}</Tooltip>}
            >
              <span className="validationErrorMsg">
                <i className="bi bi-exclamation-circle" />
              </span>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  )
}

export default FullNameRow
