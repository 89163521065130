import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import Accordion from "react-bootstrap/Accordion"
import { Form } from "react-bootstrap"
import Layout from "../../../components/Layout/layout"
import Seo from "../../seo"
import MoreOptions from "./moreOptions"
import NotFoundPage from "../../../pages/404"
import {
  DRIVE_TYPE_AUDIO,
  DRIVE_TYPE_IMAGE,
  DRIVE_TYPE_PDF,
  DRIVE_TYPE_VIDEO,
  getFileExtension,
  onDownload,
} from "../Service/driveListHelper"
import AxiosInstance from "../../../services/axiosInstance"
import VideoPlayer from "./videoPlayer"
import AudioPlayer from "./audioPlayer"
import InstallationOptions from "./InstallationOptions"
import { getUserName, changeHypertextProtocol } from "../Doc/Service/docHelper"
import { PRIVACY_PUBLIC } from "../Doc/Service/moreOprtionsHelper"

const File = ({ selectedDocId, type }) => {
  const [fileName, setFileName] = useState(""),
    [parentDoc, setParentDoc] = useState(""),
    [file, setFile] = useState(""),
    [fileExtension, setFileExtension] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [docPrivacy, setDocPrivacy] = useState(""),
    [privacyPassword, setPrivacyPassword] = useState(""),
    [saveLoading, setSaveLoading] = useState(false),
    [filePath, setFilePath] = useState(""),
    [seoDescription, setSeoDescription] = useState(""),
    [featureImg, setFeatureImg] = useState(""),
    [audioFileType, setAudioFileType] = useState(""),
    [driveId, serDriveId] = useState(""),
    [driveType, setDriveType] = useState(""),
    [ownerId, setOwnerId] = useState(""),
    [fileUrl, setFileUrl] = useState(""),
    [previewUrl, setPreviewUrl] = useState(""),
    [compressUrl, setCompressUrl] = useState(false),
    [publicBaseURLSelection, setPublicBaseURLSelection] = useState(""),
    [slug, setSlug] = useState(""),
    [showInstallationPanel, setShowInstallationPanel] = useState(false),
    [showProcessSection, setShowProcessSection] = useState(false),
    [showSetupFailed, setShowSetupFailed] = useState(false),
    [showEsignOption, setShowEsignOption] = useState(true)

  const checkUrl = () => {
    if (publicBaseURLSelection === changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}`)) {
      return `https://${publicBaseURLSelection}/${getUserName()}/${slug}`
    } else {
      return `https://${publicBaseURLSelection}/${slug}`
    }
  }

  const handleEsignOption = async (e, type) => {
    e.preventDefault()

    if (type === 4) {
      await AxiosInstance.post(`/drive/update-esign-option/${selectedDocId}`)
      getFileData(selectedDocId)
      return true
    }

    setShowProcessSection(true)

    return new Promise(async () => {
      const routeName = type === 2 ? "generate-doc-from-pdf" : "generate-image-from-pdf"
      await AxiosInstance.post(`/drive/storage/${routeName}/${selectedDocId}`, {
        user_id: localStorage.getItem("user_id"),
        parent_id: parentDoc,
        file_url: filePath,
      })
        .then(function (responseData) {
          setShowProcessSection(false)
          AxiosInstance.post(`/drive/update-esign-option/${selectedDocId}`).then(updateResponse => {
            getFileData(selectedDocId)
          })
          if (responseData.data?.driveId) {
            const redirectTo = type === 2 ? "doc" : "pdf"
            navigate(`/drive/${redirectTo}/${responseData.data.driveId}`)
            return false
          }
        })
        .catch(function (error) {
          setShowProcessSection(false)
          setShowSetupFailed(true)
        })
    })
  }

  useEffect(() => {
    getFileData(selectedDocId).then(r => r)
  }, [])

  useEffect(() => {
    if (docPrivacy === PRIVACY_PUBLIC) {
      setShowInstallationPanel(true)
    } else {
      setShowInstallationPanel(false)
    }
  }, [docPrivacy])

  useEffect(() => {
    setPreviewUrl(
      compressUrl === true
        ? checkUrl()
        : `${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/${driveType}/${fileUrl}`
    )
  })

  if (![DRIVE_TYPE_VIDEO, DRIVE_TYPE_AUDIO, DRIVE_TYPE_IMAGE, DRIVE_TYPE_PDF].includes(type)) {
    return <NotFoundPage />
  }

  const getFileData = async selectedDocId => {
    await AxiosInstance.get(`/drive/storage/detail/${selectedDocId}`).then(response => {
      fillFileData(response.data.storage)
    })
  }

  const fillFileData = fileDetail => {
    setFileName(fileDetail.driveAll.nameDisplay)
    setFile(fileDetail.file)
    setParentDoc(fileDetail.driveAll.parentID)
    setPrivacyPassword(fileDetail.driveForm.moreOptions.privacy_password ?? "")
    setTempTags(fileDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setFileExtension(getFileExtension(fileDetail.file))
    setFilePath(process.env.GATSBY_WRANGLER_URL + fileDetail.file)
    setSeoDescription(fileDetail.driveForm.moreOptions.seo_description ?? "")
    setFeatureImg(fileDetail.driveForm.moreOptions.feature_img ?? "")
    setAudioFileType(fileDetail.driveForm.moreOptions.audio_file_type ?? "")
    setDocPrivacy(fileDetail.driveForm.moreOptions.doc_privacy ?? "")
    serDriveId(fileDetail.driveAll.id)
    setDriveType(fileDetail.driveAll.type)
    setOwnerId(fileDetail.driveAll.ownerID)
    setFileUrl(fileDetail.driveAll.fileURL)
    setCompressUrl(fileDetail.driveForm.ezLinkDetail.compressUrl == "true")
    setPublicBaseURLSelection(fileDetail.driveForm.ezLinkDetail.publicBaseURLSelection ?? "")
    setSlug(fileDetail.driveAll.slug ?? "")

    if (
      fileDetail.driveForm.metaJson?.isShowEsignOption === false ||
      fileDetail.driveForm.metaJson?.isUploadFile !== true
    ) {
      setShowEsignOption(false)
    }
  }

  const icon = type => {
    switch (type) {
      case DRIVE_TYPE_PDF:
        return "bi bi-file-earmark-pdf-fill"
      case DRIVE_TYPE_AUDIO:
        return "bi bi-file-music-fill"
      case DRIVE_TYPE_IMAGE:
        return "bi bi-file-image"
      case DRIVE_TYPE_VIDEO:
        return "bi bi-file-earmark-play-fill"
    }
  }

  const submitStorage = e => {
    e.preventDefault()
    if (saveLoading) {
      return false
    }
    setSaveLoading(true)
    const formData = new FormData(e.target)
    let data = {
      userName: window.localStorage.getItem("userName"),
      docName: formData.get("storageName"),
      ownerID: formData.get("ownerID"),
      parentDoc: formData.get("parentDoc"),
      form: {
        moreOptions: {
          doc_private_tags: tempTags,
          privacy_password: formData.get("privacy_password"),
          feature_img: featureImg,
          seo_description: formData.get("seo_description"),
          audio_file_type: formData.get("audio_file_type"),
          doc_privacy: docPrivacy,
        },
        ezLinkDetail: {
          compressUrl: formData.get("compressUrl"),
          publicBaseURLSelection: formData.get("public_base_url_selection"),
        },
      },
    }
    saveStorageData(data).then(r => r)
  }

  const saveStorageData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/storage/edit/${selectedDocId}`, data)
        .then(function (response) {
          setSaveLoading(false)
          console.log(response.data)
        })
        .catch(function (error) {
          setSaveLoading(false)
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }

  return (
    <Layout>
      <Seo title={`${fileName} ${type.charAt(0).toUpperCase() + type.slice(1)}`} />
      <Form onSubmit={submitStorage} id={"storageForm"}>
        <div id="right-section" className={`h-100 asset-type-${type}`}>
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row g-0">
                    <div className="col-sm-12 mb-3">
                      <div className="pageTitle_Editor">
                        <Link
                          to={`${parentDoc ? "/drive/folder/" + parentDoc : "/drive"}`}
                          className="goBack"
                        >
                          <i className="bi bi-arrow-90deg-left" />
                        </Link>
                        <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                          <i className={`${icon(type)}`} />
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={fileName}
                          defaultValue={fileName}
                          name="storageName"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3">
                      <div className={`row g-0`}>
                        {driveType === "pdf" && showEsignOption && (
                          <>
                            <div className="col-lg-12 text-center">
                              <div className="alert alert-warning convertToeSignContainer" role="alert">
                                <p className=" fs-3">
                                  <i className="bi bi-file-earmark-pdf" /> This looks to be a doc. Do you want
                                  us to...
                                  <br />
                                </p>
                                <div className="eSignOptList text-start">
                                  <div className="cw_button" onClick={e => handleEsignOption(e, 1)}>
                                    <input type="radio" id="pdf_edoc_type1" name="pdf_edoc_type" />
                                    <label className="btn" htmlFor="pdf_edoc_type1">
                                      <i className="bi bi-pencil-square" /> I just need to sign it myself
                                    </label>
                                  </div>
                                  <div className="cw_button" onClick={e => handleEsignOption(e, 2)}>
                                    <input type="radio" id="pdf_edoc_type2" name="pdf_edoc_type" />
                                    <label className="btn" htmlFor="pdf_edoc_type2">
                                      <i className="bi bi-files" /> Convert to Smart Doc / Phone Friendly
                                      e-Doc for e-Sign / Completion
                                    </label>
                                  </div>
                                  <div className="cw_button" onClick={e => handleEsignOption(e, 3)}>
                                    <input type="radio" id="pdf_edoc_type3" name="pdf_edoc_type" />
                                    <label className="btn" htmlFor="pdf_edoc_type3">
                                      <i className="bi bi-filetype-pdf" /> Convert to Formatting-Specific
                                      e-Sign PDF
                                    </label>
                                  </div>
                                  <div className="cw_button" onClick={e => handleEsignOption(e, 4)}>
                                    <input type="radio" id="pdf_edoc_type4" name="pdf_edoc_type" />
                                    <label className="btn" htmlFor="pdf_edoc_type4">
                                      <i className="bi bi-file-pdf-fill" /> Do nothing: Just store as is,
                                      unchanged
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={`col-lg-12 text-center${!showProcessSection ? " d-none" : ""}`}>
                              <div className="alert alert-warning" role="alert">
                                <div className="mb-3 d-flex align-items-center justify-content-center gap-2">
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                  <span className="fs-3">
                                    Hang tight - we're getting your PDF ready. You can check back in a few
                                    minutes.
                                  </span>
                                </div>
                                <p className="mb-0">
                                  If the server is really busy, or this is a very long PDF, this may take even
                                  a few hours, but in most cases it will be under 12 minutes.
                                </p>
                              </div>
                            </div>

                            <div className={`col-lg-12 text-center${!showSetupFailed ? " d-none" : ""}`}>
                              <div className="alert alert-warning" role="alert">
                                <p className="fs-3">
                                  <i className="bi bi-exclamation-triangle-fill" /> Setup Failed.
                                </p>
                                <p className="mb-0">
                                  Please email your PDF to{" "}
                                  <a href="SwiftCloudHelp@Gmail.com">SwiftCloudHelp@Gmail.com</a> so we can
                                  investigate and help you get set up.
                                </p>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-lg-12 mb-3" id="asset_panel">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className="panel panel-white">
                              <Accordion.Header>
                                <span className="panel-heading-icon">
                                  <i className={`${icon(type)}`} />
                                </span>
                                <span className="panel-heading-title">{fileName}</span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="row">
                                  {type === "image" && (
                                    <div className="col-lg-12 text-center py-3">
                                      <img src={filePath} alt={file} width={"100%"} />
                                    </div>
                                  )}
                                  {type === "pdf" && (
                                    <div className="col-lg-12 text-center py-3">
                                      <div className="ratio ratio-16x9">
                                        <embed
                                          src={filePath}
                                          className="embed-responsive-item"
                                          type="application/pdf"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {type === "audio" && <AudioPlayer url={filePath} />}
                                  {type === "video" && <VideoPlayer url={filePath} />}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>

                        <InstallationOptions
                          ownerId={ownerId}
                          driveId={driveId}
                          previewUrl={previewUrl}
                          fileUrl={fileUrl}
                          compressUrl={compressUrl}
                          publicBaseURLSelection={publicBaseURLSelection}
                          slug={slug}
                          showInstallationPanel={showInstallationPanel}
                          type={type}
                        />

                        <MoreOptions
                          type={type}
                          tags={tags}
                          setTags={setTags}
                          tempTags={tempTags}
                          setTempTags={setTempTags}
                          docPrivacy={docPrivacy}
                          setDocPrivacy={setDocPrivacy}
                          privacyPassword={privacyPassword}
                          seoDescription={seoDescription}
                          featureImg={featureImg}
                          setFeatureImg={setFeatureImg}
                          audioFileType={audioFileType}
                          setAudioFileType={setAudioFileType}
                          previewUrl={previewUrl}
                        />

                        <div className="col-lg-12 text-center">
                          <button type="submit" className="btn btn-primary btn-lg">
                            <i className="bi bi-check2" /> Save Changes
                          </button>{" "}
                          <a
                            className={`btn btn-secondary btn-lg${!showInstallationPanel ? " d-none" : ""}`}
                            href={previewUrl}
                            target="_blank"
                          >
                            <i className="bi bi-box-arrow-up-right" /> Preview
                          </a>{" "}
                          <button
                            type="button"
                            className="btn btn-secondary btn-lg"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Download"
                            aria-label="Download"
                            onClick={e => onDownload(e, selectedDocId, fileName, fileExtension)}
                          >
                            <i className="bi bi-cloud-arrow-down-fill" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Layout>
  )
}
export default File
