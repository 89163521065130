import React from "react"
import AxiosInstance from "../../../../services/axiosInstance"
import { Link } from "gatsby"
import TagListView from "./tagListView"

const ListParentChild = ({ listData, setAddToTeam, getAccessManagementList, getParentChildList }) => {
  const uploadedFilePath = "StaticImages/avatar.gif"

  const handleRemoveContact = id => {
    AxiosInstance.post(`settings/access-management/delete-contact/${id}`)
      .then(response => {
        getAccessManagementList()
        getParentChildList()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const renderAccessManagement = accessManagement => {
    return (
      <li key={accessManagement.id}>
        {accessManagement.name}
        <div className="teamUserInfoContainer">
          <div className="teamUserInfo">
            <div className="teamUserInfoAction">
              <a
                href="#"
                className="btnTeamUserInfoActionTrash"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Remove Role / Position"
                aria-label="Remove Role / Position"
              >
                <i className="bi bi-trash-fill" />
              </a>
              <a
                href="#"
                className="btnTeamUserInfoActionSettings"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Settings & Permissions"
                aria-label="Settings & Permissions"
              >
                <i className="bi bi-gear-fill" />
              </a>
              <a
                href="#"
                className="btnTeamUserInfoActionReportingRole"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Add Staff Position / Reporting Role Below"
                aria-label="Add Staff Position / Reporting Role Below"
              >
                <i className="bi bi-arrow-90deg-down" />
              </a>
            </div>

            {accessManagement.contact?.id && (
              <div className="teamUsername">
                <ul className="user-card-list">
                  <li>
                    <a
                      href="#"
                      className="btnChatDockRemovePhoneCallMerge"
                      onClick={() => {
                        handleRemoveContact(accessManagement.id)
                      }}
                    >
                      <i className="bi bi-x-lg" />
                    </a>
                    <Link to={`/contacts/detail/${accessManagement.contact.id}`}>
                      <img
                        className="user_avatar"
                        src={`${process.env.GATSBY_WRANGLER_URL}/${
                          accessManagement.contact.profilePic
                            ? accessManagement.contact.profilePic
                            : uploadedFilePath
                        }`}
                      />
                      {accessManagement.contact.name}
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            {accessManagement.tags.length > 0 && (
              <TagListView tags={accessManagement.tags} id={accessManagement.id} />
            )}
          </div>
        </div>

        {accessManagement.children && accessManagement.children.length > 0 && (
          <ul>{accessManagement.children.map(renderAccessManagement)}</ul>
        )}
      </li>
    )
  }

  return (
    <div className="row">
      <div className="col-lg-10 mx-auto">
        <div className="row">
          <div className="col-md-12">
            <div className="bg-white border p-3">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="d-inline">
                    <i className="bi bi-people-fill" /> User Admin (IAM - ID &amp; Access Management)
                  </h4>
                  <div className="float-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="btnAddNewUserAdmin"
                      onClick={() => setAddToTeam(true)}
                    >
                      <i className="bi bi-person-fill-add" /> Add Role
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <ul id="userAdminTree">{listData.map(renderAccessManagement)}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListParentChild
