import React, { useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout/layout"
import Seo from "../../seo"
import "bootstrap-tagsinput/src/bootstrap-tagsinput.css"
import "bootstrap-select/dist/css/bootstrap-select.css"
import "../../../css/datepicker.css"
import "../../../css/bootcomplete.css"
import "../../../css/swiftcloud_global.css"
import "../../../css/fileinput.css"
import "../../../css/chat-dock.css"
import "../../../css/edocs.css"
import { Accordion, Form } from "react-bootstrap"
import InstallationOptions from "../Doc/InstallationOptions"
import MoreOptions from "./moreOptions"
import { setAdditionalOptionsArray, setAutomationOptionsArray, getUserName } from "../Doc/Service/docHelper"
import { Link } from "gatsby"
import Spreadsheet from "react-spreadsheet"
import AxiosInstance from "../../../services/axiosInstance"
import { onDownload } from "../Service/driveListHelper"

const Detail = ({ selectedDocId }) => {
  const [sheetData, setSheetData] = useState([
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    ]),
    [loadDocDetails, setLoadDocDetails] = useState(true),
    [docData, setDocData] = useState(""),
    [docName, setDocName] = useState(""),
    [spamReduction, setSpamReduction] = useState(""),
    [driveId, serDriveId] = useState(""),
    [driveType, setDriveType] = useState(""),
    [ownerId, setOwnerId] = useState(""),
    [parentDoc, setParentDoc] = useState(""),
    [fileUrl, setFileUrl] = useState(""),
    [docType, setDocType] = useState(""),
    [esignMode, setEsignMode] = useState(""),
    [pdfGeneration, setPDFGeneration] = useState(""),
    [signAuth, setSignAuth] = useState(""),
    [docPrivacy, setDocPrivacy] = useState(""),
    [whoCanModify, setWhoCanModify] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [displayInFeedTempTags, setDisplayInFeedTempTags] = useState([]),
    [contactTempTags, setContactTempTags] = useState([]),
    [privacyPassword, setPrivacyPassword] = useState(""),
    [whoCanCopy, setWhoCanCopy] = useState(""),
    [updateData, setUpdateData] = useState(""),
    [emailVerification, setEmailVerification] = useState(""),
    [sendSMSVerification, setSendSMSVerification] = useState(""),
    [signInFG, setSignInFG] = useState(""),
    [uploadGOVId, setUploadGOVId] = useState(""),
    [geoLocation, setGEOLocation] = useState(""),
    [photoSignor, setPhotoSignor] = useState(""),
    [language, setLanguage] = useState(""),
    [docPrint, setDocPrint] = useState(""),
    [fontSize, setFontSize] = useState(""),
    [docComments, setDocComments] = useState(""),
    [esignSaveData, setEsignSaveData] = useState(""),
    [appendDoc, setAppendDoc] = useState(""),
    [appendUpload, setAppendUpload] = useState(""),
    [applyStamp, setApplyStamp] = useState(""),
    [commentVisibility, setCommentVisibility] = useState(""),
    [outputPdfFileType, setOutputPdfFileType] = useState(""),
    [saveLoading, setSaveLoading] = useState(false)
  const fillSheetData = sheetDetail => {
    let data = sheetData
    if (sheetDetail.dataJson.length > 0) {
      data = sheetDetail.dataJson
      data.forEach(item => {
        item.forEach(childItem => {
          if (childItem.value === "=====>") {
            childItem.value = "------"
          }
        })
      })
    }
    setDocData(sheetDetail)
    setDocName(sheetDetail.name)
    setSheetData(data)
    setOwnerId(sheetDetail.driveAll.ownerID)
    setParentDoc(sheetDetail.driveAll.parentID)
    serDriveId(sheetDetail.driveAll.id)
    setDriveType(sheetDetail.driveAll.type)
    setContactTempTags(sheetDetail.driveForm.dhAutomation.conatct_feed_tag ?? [])
    setDocType(sheetDetail.driveForm.moreOptions.doc_type ?? "")
    setEsignMode(sheetDetail.driveForm.moreOptions.esign_mode ?? "")
    setTempTags(sheetDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setTags(sheetDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setPDFGeneration(sheetDetail.driveForm.moreOptions.pdf_generation ?? "")
    setSignAuth(sheetDetail.driveForm.moreOptions.authentication ?? "")
    setDocPrivacy(sheetDetail.driveForm.moreOptions.doc_privacy ?? "")
    setWhoCanModify(sheetDetail.driveForm.moreOptions.who_can_modify ?? "")
    setPrivacyPassword(sheetDetail.driveForm.moreOptions.privacy_password ?? "")
    setWhoCanCopy(sheetDetail.driveForm.moreOptions.who_can_copy ?? "")
    setUpdateData(sheetDetail.driveForm.moreOptions.update_data ?? "")
    setEmailVerification(sheetDetail.driveForm.moreOptions.email_verification ?? "")
    setSendSMSVerification(sheetDetail.driveForm.moreOptions.send_sms_verification ?? "")
    setSignInFG(sheetDetail.driveForm.moreOptions.sign_in_fg ?? "")
    setUploadGOVId(sheetDetail.driveForm.moreOptions.upload_gov_id ?? "")
    setGEOLocation(sheetDetail.driveForm.moreOptions.geo_location ?? "")
    setPhotoSignor(sheetDetail.driveForm.moreOptions.photo_signor ?? "")
    setLanguage(sheetDetail.driveForm.moreOptions.language ?? "")
    setDocPrint(sheetDetail.driveForm.moreOptions.doc_print ?? "")
    setFontSize(sheetDetail.driveForm.moreOptions.font_size ?? "")
    setDocComments(sheetDetail.driveForm.moreOptions.doc_comments ?? "")
    setEsignSaveData(sheetDetail.driveForm.moreOptions.esign_save_data ?? "")
    setAppendDoc(sheetDetail.driveForm.moreOptions.append_doc ?? "")
    setAppendUpload(sheetDetail.driveForm.moreOptions.append_upload ?? "")
    setApplyStamp(sheetDetail.driveForm.moreOptions.apply_stamp ?? "")
    setCommentVisibility(sheetDetail.driveForm.moreOptions.comments_visibility ?? "")
    setOutputPdfFileType(sheetDetail.driveForm.moreOptions.output_pdf_file_type ?? "")
  }
  const previewPage = () => {
    window.open(`/${getUserName()}/spreadsheet/${fileUrl}`)
  }

  const submitDoc = e => {
    e.preventDefault()
    setSaveLoading(true)
    const formData = new FormData(e.target)
    let data = {
      userName: window.localStorage.getItem("userName"),
      name: formData.get("docName"),
      ownerID: formData.get("ownerID"),
      dataJson: sheetData,
      form: {
        ueFlow: {
          other_option: formData.get("otherOption"),
          selected_option: formData.get("selectedOption"),
          forward_domain: formData.get("forward_domain"),
          forward_url: formData.get("forward_url"),
          in_person_mode: formData.get("in_person_mode"),
          in_person_mode_file_type: formData.get("in_person_mode_file_type"),
          in_person_mode_file_id: formData.get("in_person_mode_file_id"),
          in_person_mode_to_url: formData.get("in_person_mode_to_url"),
          on_page_download_file: formData.get("on_page_download_file"),
          to_ecommerce_item: formData.get("to_ecommerce_item"),
          additional_options: setAdditionalOptionsArray(formData),
        },
        dhAutomation: {
          initial_automation_option: formData.get("initial_automation_option"),
          initial_incoming_opt: formData.get("initial_incoming_opt"),
          initial_folder: formData.get("initial_folder"),
          initial_as_a: formData.get("initial_as_a"),
          automation_option: setAutomationOptionsArray(formData, displayInFeedTempTags, contactTempTags),
        },
        moreOptions: {
          doc_type: formData.get("docType"),
          esign_mode: formData.get("esign_mode"),
          pdf_generation: formData.get("pdf_generation"),
          authentication: formData.get("authentication"),
          doc_private_tags: tempTags,
          doc_privacy: formData.get("doc_privacy"),
          who_can_modify: formData.get("who_can_modify"),
          privacy_password: formData.get("privacy_password"),
          who_can_copy: formData.get("who_can_copy"),
          update_data: formData.get("update_data"),
          email_verification: formData.get("email_verification"),
          send_sms_verification: formData.get("send_sms_verification"),
          sign_in_fg: formData.get("sign_in_fg"),
          upload_gov_id: formData.get("upload_gov_id"),
          geo_location: formData.get("geo_location"),
          photo_signor: formData.get("photo_signor"),
          language: formData.get("language"),
          doc_print: formData.get("doc_print"),
          font_size: formData.get("font_size"),
          doc_comments: formData.get("doc_comments"),
          esign_save_data: formData.get("esign_save_data"),
          append_doc: formData.get("append_doc"),
          append_upload: formData.get("append_upload"),
          apply_stamp: formData.get("apply_stamp"),
          comments_visibility: formData.get("comments_visibility"),
          output_pdf_file_type: formData.get("output_pdf_file_type"),
          spam_reduction: formData.get("spam_reduction"),
        },
      },
    }
    saveDocData(data)
  }

  const getSheetData = async selectedDocId => {
    await AxiosInstance.get(`/drive/spreadsheet/detail/${selectedDocId}`).then(response => {
      fillSheetData(response.data.sheet)
    })
  }

  useEffect(() => {
    if (loadDocDetails) {
      setLoadDocDetails(false)
      getSheetData(selectedDocId)
    }
  })
  const saveDocData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/spreadsheet/edit/${selectedDocId}`, data)
        .then(function (response) {
          setSaveLoading(false)
          console.log(response.data)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }
  return (
    <Layout>
      <Seo title={docName} />
      <Form onSubmit={submitDoc} id={"docForm"}>
        <div id="right-section" className="h-100">
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row g-0">
                <div className="col-lg-8 col-sm-12 mb-3">
                  <div className="pageTitle_Editor">
                    <Link to={`${parentDoc ? "/drive/folder/" + parentDoc : "/drive"}`} className="goBack">
                      <i className="bi bi-arrow-90deg-left" />
                    </Link>
                    <button
                      type="button"
                      className="CD_UserType isHuman"
                      data-bs-toggle="tooltip"
                      title="Workroom"
                    >
                      <i className="bi bi-table" />
                    </button>
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Title / Summary"
                      name="docName"
                      defaultValue={docName}
                    />
                    <Form.Control type="hidden" defaultValue={ownerId} name="ownerID" />
                    <Form.Control type="hidden" defaultValue={parentDoc} name="parentDoc" />
                  </div>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-lg-12 mb-3">
                  <div className="col-lg-12 position-relative">
                    <div className="eDocTabsContainer" />
                  </div>

                  <div className="row g-0">
                    {/*session(s)*/}
                    <div className="col-lg-12 mb-3 d-none" id="session_panel">
                      <div className="accordion-item panel panel-grey">
                        <div
                          className="accordion-button"
                          data-bs-toggle="collapse"
                          data-bs-target="#session_container"
                          aria-expanded="true"
                          aria-controls="session_container"
                        >
                          <i className="bi bi-person-fill me-2" /> User Session(s)
                        </div>
                        <div
                          id="session_container"
                          className="accordion-collapse collapse show"
                          aria-labelledby="session_container"
                        >
                          <div className="accordion-body">
                            <div
                              className="r
                                                    ow"
                            >
                              <div className="col-lg-12">
                                <div className="float-end">
                                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                                    <li className="nav-item">
                                      <button className="btn btn-primary">
                                        <i className="bi bi-person-plus-fill" />
                                        New Session
                                      </button>
                                    </li>
                                    <li className="nav-item nav-item-trash" role="presentation">
                                      <a
                                        className="nav-link"
                                        href="#"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title="Trash"
                                      >
                                        <i className="bi bi-trash-fill" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <table
                                  id="tblDocSession"
                                  className="table dataTable"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <thead>
                                    <tr>
                                      <th width="12%">Last Edited</th>
                                      <th width="20%">Name(s)</th>
                                      <th width="50%" className="d-none d-md-table-cell">
                                        Session Data Excerpt
                                      </th>
                                      <th width="10%" className="d-none d-md-table-cell">
                                        Status
                                      </th>
                                      <th width="5%" className="text-end d-none d-lg-table-cell"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <time className="timeago" dateTime="2022-05-01"></time>
                                      </td>
                                      <td className="session_user">
                                        <a href="#">
                                          <img src="images/bachchan-amitabh-image.jpg" />
                                          Amitabh Bachchan
                                        </a>
                                      </td>
                                      <td className="d-none d-md-table-cell">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry
                                      </td>
                                      <td className="d-none d-md-table-cell">Pending</td>
                                      <td className="text-end tableColAction d-none d-lg-table-cell">
                                        <a
                                          href="#"
                                          className="btn btn-delete"
                                          data-bs-toggle="tooltip"
                                          title="Delete"
                                        >
                                          <i className="bi bi-trash-fill" />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3" id="sheet_panel">
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <Accordion.Item eventKey="0" className="panel panel-white">
                          <Accordion.Header>
                            <span className="panel-heading-icon">
                              <i className="bi bi-table" />
                            </span>
                            <span className="panel-heading-title">Submission Details</span>
                          </Accordion.Header>
                          <Accordion.Body style={{ overflowX: "scroll" }}>
                            <Spreadsheet data={sheetData} onChange={setSheetData} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>

                    <React.Fragment>
                      {/*Installation Options*/}
                      <InstallationOptions driveType={driveType} ownerId={ownerId} driveId={driveId} />

                      {/*More Options*/}
                      <MoreOptions
                        driveType={driveType}
                        spamReduction={spamReduction}
                        docType={docType}
                        esignMode={esignMode}
                        setEsignMode={setEsignMode}
                        tags={tags}
                        setTags={setTags}
                        tempTags={tempTags}
                        setTempTags={setTempTags}
                        pdfGeneration={pdfGeneration}
                        setPDFGeneration={setPDFGeneration}
                        signAuth={signAuth}
                        setSignAuth={setSignAuth}
                        docPrivacy={docPrivacy}
                        setDocPrivacy={setDocPrivacy}
                        whoCanModify={whoCanModify}
                        setWhoCanModify={setWhoCanModify}
                        privacyPassword={privacyPassword}
                        whoCanCopy={whoCanCopy}
                        setWhoCanCopy={setWhoCanCopy}
                        updateData={updateData}
                        emailVerification={emailVerification}
                        setEmailVerification={setEmailVerification}
                        sendSMSVerification={sendSMSVerification}
                        setSendSMSVerification={setSendSMSVerification}
                        signInFG={signInFG}
                        setSignInFG={setSignInFG}
                        uploadGOVId={uploadGOVId}
                        setUploadGOVId={setUploadGOVId}
                        geoLocation={geoLocation}
                        setGEOLocation={setGEOLocation}
                        photoSignor={photoSignor}
                        setPhotoSignor={setPhotoSignor}
                        language={language}
                        docPrint={docPrint}
                        fontSize={fontSize}
                        docComments={docComments}
                        setDocComments={setDocComments}
                        esignSaveData={esignSaveData}
                        setEsignSaveData={setEsignSaveData}
                        appendDoc={appendDoc}
                        setAppendDoc={setAppendDoc}
                        appendUpload={appendUpload}
                        setAppendUpload={setAppendUpload}
                        applyStamp={applyStamp}
                        setApplyStamp={setApplyStamp}
                        commentVisibility={commentVisibility}
                        setCommentVisibility={setCommentVisibility}
                        outputPdfFileType={outputPdfFileType}
                      />
                    </React.Fragment>
                    <div className="col-lg-12 text-center footer-action">
                      <div className="btn-group btnDropdownWithSeparater">
                        <button type="submit" className="btn btn-primary btn-lg">
                          <i className="bi bi-check2" /> Save &nbsp;
                          <i className={saveLoading ? "spinner-border spinner-border-sm" : ""} />
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-files" /> Save as Copy
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-subtract" />
                              Clone a Template
                            </a>
                          </li>
                        </ul>
                      </div>{" "}
                      <button type="button" className="btn btn-secondary btn-lg" onClick={previewPage}>
                        <i className="bi bi-box-arrow-up-right" /> Preview
                      </button>{" "}
                      <button type="button" className="btn btn-default btn-lg">
                        <i className="bi bi-cloud-arrow-up-fill" /> Publish
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-secondary btn-lg"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Download"
                        aria-label="Download"
                        onClick={e => onDownload(e, selectedDocId, docName, "csv")}
                      >
                        <i className="bi bi-cloud-arrow-down-fill" />
                      </button>
                      {/*SOCIALIZER POPUP*/}
                      <button
                        type="button"
                        className="btn btn-default btn-lg btnOpenChatDock"
                        data-bs-toggle="tooltip"
                        title="History"
                      >
                        <i className="bi bi-arrow-counterclockwise" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Layout>
  )
}
export default Detail
