import React, { useEffect, useState } from "react"
import { savePersonalProfile } from "../AboutYourBusiness/helper"
import axios from "axios"

const TimeZoneDropdown = ({ data }) => {
  const [timezoneList, setTimezoneList] = useState([])

  const [value, setValue] = useState(""),
    [id, setId] = useState(null)

  const handleSubmit = e => {
    savePersonalProfile({ id, type: "TimeZone", value: e.target.value, setValue, setId })
  }

  const getTimeZone = () => {
    const uninterceptedAxiosInstance = axios.create()
    uninterceptedAxiosInstance.get(`${process.env.GATSBY_API_URL}/timezone/list`).then(response => {
      setTimezoneList(response.data.data)
    })
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }
  })

  useEffect(() => {
    getTimeZone()
  }, [])

  return (
    <div className="row g-0 block-content align-items-center border-bottom">
      <div className="col-6">
        <h5 className="block-title">Time Zone</h5>
      </div>
      <div className="col-6 text-end pr-15">
        <select
          name="TimeZone"
          id="TimeZone"
          className="form-select"
          key={value}
          value={value}
          onChange={handleSubmit}
        >
          {timezoneList.map(timezone => {
            return (
              <option value={timezone.timezone} key={`timezone_${timezone.label}`}>
                {timezone.label}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default TimeZoneDropdown
