import React, { useState, useEffect } from "react"

import AxiosInstance from "../../../../services/axiosInstance"
import "../settings.css"

const SloganRow = ({ sloganData }) => {
  const [updateData, setUpdateData] = useState(true),
    [slogan, setSlogan] = useState(""),
    [sloganId, setSloganId] = useState(null)

  const handleSubmit = e => {
    if (slogan === e.target.value) {
      return false
    }
    setSlogan(e.target.value)

    let url = "/settings/personal-setting/add"
    if (sloganId) {
      url = `/settings/personal-setting/edit/${sloganId}`
    }

    AxiosInstance.post(url, {
      type: "Slogan",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setSloganId(response.data.id)
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (sloganId === null && sloganData) {
      setUpdateData(false)
      setSloganId(sloganData.id)
      setSlogan(sloganData.value)
    }
  })

  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto mb-3 inputbox">
        <input
          type="text"
          className="form-control form-control-lg text-center slogan"
          name="overview"
          placeholder="Slogan / Mantra / Overview : I help people..."
          defaultValue={slogan}
          onBlur={handleSubmit}
        />
      </div>
    </div>
  )
}

export default SloganRow
