import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../../services/axiosInstance"
import Tags from "./tags"
import { getLoginUserEmail } from "../../../../services/auth"
import Axios from "axios"
import ListParentChild from "./ListParentChild"
import "./settings_company.css"

const AccessManagement = () => {
  const [addToTeam, setAddToTeam] = useState(false),
    [userAdminType, setUserAdminType] = useState(""),
    [addTag, setAddTag] = useState(false),
    [newTag, setNewTag] = useState(""),
    [tempTags, setTempTags] = useState([]),
    [removeTagIndex, setRemoveTagIndex] = useState(null),
    [existingContactId, setExistingContactId] = useState(""),
    [userSearchResult, setUserSearchResult] = useState([]),
    [existingUserDefaultName, setExistingUserDefaultName] = useState(""),
    [existingUserDefaultId, setExistingUserDefaultId] = useState(""),
    [existingUserDefaultImage, setExistingUserDefaultImage] = useState(""),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [accessManagementData, setAccessManagementData] = useState([]),
    [existingUserDefaultEmail, setExistingUserDefaultEmail] = useState(""),
    [addSubUser, setAddSubUser] = useState(false),
    [parentChildList, setParentChildList] = useState([])

  const contactSearchData = e => {
    let searchUser = e.target.value
    if (searchUser.length < 2) {
      setUserSearchResult([])
      setExistingContactId("")
      return false
    }

    AxiosInstance.get(`/contact/`, {
      params: {
        page: 1,
        search: searchUser ?? "",
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
    })
  }

  const handleUserAdminType = e => {
    setUserAdminType(e.target.value)

    if (e.target.value && e.target.value !== "AI Agent") {
      setAddSubUser(true)
    } else {
      setAddSubUser(false)
    }
  }

  const saveAccessManagement = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    if (!formData.get("useradmin_title")) {
      alert("Please enter job title.")
      return false
    }

    const formAdminType = formData.get("useradmin_type")
    const requestData = {
      title: formData.get("useradmin_title"),
      contact: formAdminType !== "AI Agent" ? existingUserDefaultId : 0,
      parent: formData.get("useradmin_parent_role"),
      type: formAdminType,
      addSubUser: addSubUser,
      agentName: formAdminType === "AI Agent" ? formData.get("AI_Agent_Name") : "",
      agentEngine: formAdminType === "AI Agent" ? formData.get("AI_Agent_Engine") : "",
      agentDescription: formAdminType === "AI Agent" ? formData.get("AI_Agent_Desc") : "",
      tags: tempTags,
      name: existingUserDefaultName,
      email: existingUserDefaultEmail,
      referred_by: getLoginUserEmail(),
      timeclockSettings: addSubUser ? formData.get("timeclock_settings") : "",
      managementOversight: addSubUser ? formData.get("management_oversight") : "",
    }

    return new Promise(async () => {
      await AxiosInstance.post(`settings/access-management/add`, requestData)
        .then(response => {
          refreshAccessManagementData(e)
        })
        .catch(function (error) {
          refreshAccessManagementData(e)
        })
    })
  }

  const getAccessManagementList = () => {
    AxiosInstance.get(`/settings/access-management/list`).then(response => {
      setAccessManagementData(response.data.data)
    })
  }

  const getParentChildList = () => {
    AxiosInstance.get(`/settings/access-management/list-parent-child`).then(response => {
      setParentChildList(response.data.data)
    })
  }

  const handleSearchUserSelect = searchUserData => {
    const defaultImageUrl = `${process.env.GATSBY_WRANGLER_URL}/${
      searchUserData.profilePic ? searchUserData.profilePic : uploadedFilePath
    }`
    setExistingContactId(searchUserData.id)
    setExistingUserDefaultName(searchUserData.name)
    setExistingUserDefaultImage(defaultImageUrl)
    setUserSearchResult([])
    setExistingUserDefaultId(searchUserData.id)

    const contactPrimaryEmail = searchUserData.emails.find(data => data.type == "email_primary")
    if (contactPrimaryEmail?.email) {
      setExistingUserDefaultEmail(contactPrimaryEmail.email)
    }
  }

  const refreshAccessManagementData = e => {
    e.target.reset()
    setTempTags([])
    setExistingUserDefaultName("")
    setExistingUserDefaultId("")
    setExistingUserDefaultImage("")
    getAccessManagementList()
    getParentChildList()
  }

  useEffect(() => {
    getAccessManagementList()
    getParentChildList()
  }, [])

  return (
    <Accordion.Item eventKey="12">
      <Accordion.Header>
        <i className="bi bi-people-fill me-2" />
        Team Admin (IAM - ID & Access Management)
      </Accordion.Header>
      <Accordion.Body className="p-3">
        <div className="row">
          <div
            className={`col-lg-6 col-md-8 mx-auto py-4${!addToTeam ? " display-none" : ""} `}
            id="addNewUserAdminContainer"
          >
            <form id="accessManagement" onSubmit={saveAccessManagement} method="POST">
              <div className="row">
                <div className="col-lg-12 colHeading">
                  <strong>
                    <i className="bi bi-people-fill" /> Add to your Team
                  </strong>
                </div>
                <div className="col-lg-12 dh-block-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row mb-3 align-items-center">
                            <div className="col-sm-4">Position / Job Title: </div>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                name="useradmin_title"
                                id="useradmin_title"
                                className="form-control"
                                maxLength="150"
                              />
                            </div>
                          </div>
                          <Tags
                            addTag={addTag}
                            setAddTag={setAddTag}
                            newTag={newTag}
                            setNewTag={setNewTag}
                            tempTags={tempTags}
                            setTempTags={setTempTags}
                            removeTagIndex={removeTagIndex}
                            setRemoveTagIndex={setRemoveTagIndex}
                          />
                          <div className="row mb-3 align-items-center">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className="ms-1 d-inline-block">
                                  Department Lead / Role this reports to (optional)
                                </Tooltip>
                              }
                            >
                              <div className="col-sm-4">Optional: Reports to:</div>
                            </OverlayTrigger>

                            <div className="col-sm-8">
                              <select
                                name="useradmin_parent_role"
                                id="useradmin_parent_role"
                                className="form-select"
                              >
                                <option value="">Me / Direct</option>
                                {accessManagementData.length > 0 &&
                                  accessManagementData.map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.title}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3 align-items-center">
                            <div className="col-sm-4">Type: </div>
                            <div className="col-sm-8">
                              <select
                                name="useradmin_type"
                                id="useradmin_type"
                                className="form-select"
                                onChange={e => handleUserAdminType(e)}
                              >
                                <option value="">== SELECT ==</option>
                                <option value="In-house direct hire">In-house direct hire</option>
                                <option value="Vendor / Outsourced (Direct)">
                                  Vendor / Outsourced (Direct)
                                </option>
                                <option value="Staffing / Temp / Agency Hire">
                                  Staffing / Temp / Agency Hire
                                </option>
                                <option value="AI Agent">AI Agent</option>
                              </select>
                            </div>
                          </div>

                          <div
                            className={`row mb-3 align-items-center useradmin_opt_field useradmin_human_field${
                              userAdminType == "AI Agent" || userAdminType == "" ? " d-none" : ""
                            }`}
                          >
                            <div className="col-sm-12">
                              <input
                                type="checkbox"
                                name="useradmin_add_subuser"
                                id="useradmin_add_subuser"
                                defaultValue={1}
                                className="css-checkbox"
                                checked={addSubUser}
                                onChange={() => setAddSubUser(!addSubUser)}
                              />
                              <label htmlFor="useradmin_add_subuser" className="css-checkbox-label">
                                Add Sub-User / Grant SwiftCloud Access
                              </label>
                            </div>
                          </div>
                          <div
                            className={`row mb-3 align-items-center useradmin_opt_field useradmin_subuser_field${
                              userAdminType === "AI Agent" ? " d-none" : ""
                            }`}
                          >
                            <div className="col-sm-4">Name:</div>
                            <div className="col-sm-8">
                              <div className="bc-wrapper">
                                <input
                                  type="hidden"
                                  id="useradmin_add_subuser_nameId"
                                  name="useradmin_add_subuser_nameId"
                                  defaultValue=""
                                />
                                <input
                                  type="text"
                                  className={`form-control ${existingContactId ? "userSelectedInput" : ""} `}
                                  name="contactSearch"
                                  placeholder=""
                                  autoComplete="off"
                                  onKeyUp={contactSearchData}
                                  data-query={existingUserDefaultName}
                                  value={existingUserDefaultName}
                                  onChange={e => setExistingUserDefaultName(e.target.value)}
                                />

                                {userSearchResult.length > 0 && (
                                  <div className="bc-menu list-group" style={{ display: "block" }}>
                                    {userSearchResult.map((searchUserData, searchUserIndex) => {
                                      return (
                                        <a
                                          // href="#"
                                          className="list-group-item"
                                          data-id={searchUserData.id}
                                          data-label={searchUserData.name}
                                          key={searchUserIndex}
                                          onClick={() => {
                                            handleSearchUserSelect(searchUserData)
                                          }}
                                        >
                                          <img
                                            className="bc-user-avatar"
                                            src={`${process.env.GATSBY_WRANGLER_URL}/${
                                              searchUserData.profilePic
                                                ? searchUserData.profilePic
                                                : uploadedFilePath
                                            }`}
                                          />
                                          {searchUserData.name}
                                        </a>
                                      )
                                    })}
                                  </div>
                                )}

                                {existingContactId && (
                                  <img
                                    className="selectedUserAvatar"
                                    src={existingUserDefaultImage}
                                    alt={existingUserDefaultName}
                                  />
                                )}
                                <div className="bc-menu list-group" />
                              </div>
                            </div>
                          </div>

                          {addSubUser && (
                            <>
                              <div className="row mb-3 align-items-center useradmin_opt_field useradmin_human_field">
                                <div className="col-sm-4">Timeclock Settings:</div>
                                <div className="col-sm-8">
                                  <select name="timeclock_settings" className="form-select">
                                    <option value="Time & Attendance (Hourly)">
                                      Time &amp; Attendance (Hourly)
                                    </option>
                                    <option value="Time Billing">Time Billing</option>
                                    <option value="None / Other">None / Other</option>
                                    <option value="Agency Worker: Rebilled Only">
                                      Agency Worker: Rebilled Only
                                    </option>
                                    <option value="Agency Worker: Hourly + Rebilling">
                                      Agency Worker: Hourly + Rebilling
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="row mb-3 align-items-center useradmin_opt_field useradmin_human_field">
                                <div className="col-sm-4">Timeclock Oversight:</div>
                                <div className="col-sm-8">
                                  <select name="management_oversight" className="form-select">
                                    <option value="None">None</option>
                                    <option value="See All">See All</option>
                                    <option value="Department / Downstream Reports">
                                      Department / Downstream Reports
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </>
                          )}

                          <div
                            className={`row mb-3 align-items-center useradmin_opt_field useradmin_AI_Agent_field${
                              userAdminType == "AI Agent" ? "" : " d-none"
                            }`}
                          >
                            <div className="col-sm-4">AI Agent Name </div>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                name="AI_Agent_Name"
                                id="AI_Agent_Name"
                                className="form-control"
                                maxLength="150"
                              />
                            </div>
                          </div>
                          <div
                            className={`row mb-3 align-items-center useradmin_opt_field useradmin_AI_Agent_field${
                              userAdminType == "AI Agent" ? "" : " d-none"
                            }`}
                          >
                            <div className="col-sm-4">Engine: </div>
                            <div className="col-sm-8">
                              <select name="AI_Agent_Engine" id="AI_Agent_Engine" className="form-select">
                                <option value="Open AI">Open AI</option>
                                <option value="ChatGPT">ChatGPT / Open AI</option>
                                <option value="Claude">Claude by Anthropic</option>
                                <option value="Gemini">Gemini by Google</option>
                                <option value="Llama">Llama by Facebook / Meta</option>
                                <option value="Grok">Grok by X / Twitter</option>
                                <option value="Gemini">Gemini</option>
                                <option value="Claude">Claude</option>
                              </select>
                            </div>
                          </div>
                          <div
                            className={`row mb-3 align-items-center useradmin_opt_field useradmin_AI_Agent_field ${
                              userAdminType == "AI Agent" ? "" : " d-none"
                            }`}
                          >
                            <div className="col-sm-4">Role Description:</div>
                            <div className="col-sm-8">
                              <textarea
                                name="AI_Agent_Desc"
                                id="AI_Agent_Desc"
                                className="form-control"
                                rows={4}
                                defaultValue={""}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 text-end">
                              <button
                                type="button"
                                name="btnCancel"
                                id="btnCancelUserAdmin"
                                className="btn btn-default"
                                onClick={() => setAddToTeam(false)}
                              >
                                <i className="bi bi-x-lg" /> Cancel
                              </button>{" "}
                              <button
                                type="submit"
                                name="btnSave"
                                id="btnSaveUserAdmin"
                                className="btn btn-primary"
                              >
                                <i className="bi bi-check2" /> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-8 mx-auto py-4 display-none" id="addPersonToRoleContainer">
            <div className="row">
              <div className="col-lg-12 colHeading">
                <strong>
                  <i className="bi bi-person-fill-add" /> Add / Invite Person to Role
                </strong>
              </div>
              <div className="col-lg-12 dh-block-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 BC_Multiple_User">
                        <div className="mb-3 row">
                          <div className="col-sm-12">
                            <input
                              type="text"
                              name="useradmin_input"
                              id="useradmin_input"
                              className="form-control addUserAutoSearch"
                              placeholder="Any Email or Phone"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row align-items-center addNewUserField display-none">
                          <div className="col-sm-6">
                            <input
                              name="memberName"
                              id="memberName"
                              className="form-control"
                              type="text"
                              placeholder="Name"
                            />
                          </div>
                          <div className="col-sm-6">
                            <input
                              name="memberEmail"
                              id="memberEmail"
                              className="form-control"
                              type="text"
                              placeholder="Email or Phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelAddPersonToRole"
                          className="btn btn-default"
                        >
                          <i className="bi bi-x-lg" /> Cancel
                        </button>
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveAddPersonToRole"
                          className="btn btn-primary"
                        >
                          <i className="bi bi-check2" /> Invite / Request <i className="bi bi-send-fill" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ListParentChild
          listData={parentChildList}
          setAddToTeam={setAddToTeam}
          getAccessManagementList={getAccessManagementList}
          getParentChildList={getParentChildList}
        />
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default AccessManagement
