import React, { useEffect, useState, useRef } from "react"
import { getUserName } from "../../Doc/Service/docHelper"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../../services/axiosInstance"
import { changeHypertextProtocol } from "../../Doc/Service/docHelper"

const Installation = ({ states, setStates, previewUrl, fileUrl }) => {
  const username = getUserName()
  const ownerId = localStorage.getItem("user_id")
  const defaultDomain = `https://${changeHypertextProtocol(process.env.GATSBY_PREVIEW_URL)}`

  const customUrlRef = useRef(null)
  const compressUrlInputRef = useRef(null)

  const [itemId, setItemId] = useState(""),
    [isCustomized, setIsCustomized] = useState(false),
    [customUrl, setCustomUrl] = useState("customUrl"),
    [tmpCustomUrl, setTmpCustomUrl] = useState("customUrl"),
    [copyStatus, setCopyStatus] = useState("Copy"),
    [selectedDomain, setSelectedDomain] = useState(defaultDomain),
    [slugErrorMessage, setSlugErrorMessage] = useState(""),
    [emptyCustomUrlStyle, setEmptyCustomUrlStyle] = useState({ display: "none" }),
    [changeFromDb, setChangeFromDb] = useState(false)

  useEffect(() => {
    setItemId(`${ownerId}P${states.driveId}`)
    setSelectedDomain()

    if (states.mappedUrls === undefined) {
      loadMappedUrl()
    }

    if (states?.ezLinkDetail && states?.ezLinkDetail?.compressUrl == "true") {
      setSelectedDomain(states.ezLinkDetail?.publicBaseURLSelection)
      setChangeFromDb(true)
      setIsCustomized(true)
      setStates({
        ...states,
        previewUrl: `${states.ezLinkDetail.publicBaseURLSelection}/${states.driveSlug}`,
      })
    }

    if (states?.driveSlug) {
      setCustomUrl(states.driveSlug)
      setTmpCustomUrl(states.driveSlug)
    }
  }, [states.itemId])

  const updateCustomizationStatus = e => {
    setIsCustomized(e.target.checked)
    let ezLinkDetail = {
      compressUrl: false,
      publicBaseURLSelection: null,
    }

    if (e.target.checked) {
      setSelectedDomain(defaultDomain)
      ezLinkDetail = {
        compressUrl: true,
        publicBaseURLSelection: defaultDomain,
      }
      setChangeFromDb(false)

      if (customUrl || states.driveSlug) {
        previewUrl = `${defaultDomain}/${username}/${customUrl || states.driveSlug}`
      }
    } else {
      previewUrl = `${defaultDomain}/${username}/item/${fileUrl}`
    }
    setStates({ ...states, ezLinkDetail, previewUrl })
  }

  useEffect(() => {
    if (isCustomized && changeFromDb === false) {
      customUrlRef.current?.focus()
    }
  }, [isCustomized, changeFromDb])

  const updateCustomUrl = e => {
    const previewUrl = `${defaultDomain}/${username}/${e.target.value}`
    setStates({ ...states, previewUrl })

    if (!selectedDomain) {
      setSelectedDomain(defaultDomain)
    }

    setEmptyCustomUrlStyle({ display: e.target.value === "" ? "block" : "none" })
    setTmpCustomUrl(e.target.value)
  }

  const triggerCopyUrl = e => {
    e.preventDefault()

    setCopyStatus("Copied")
    setTimeout(() => {
      setCopyStatus("Copy")
    }, 1200)

    navigator.clipboard.writeText(states.previewUrl)
  }

  const doNothing = e => {
    e.preventDefault()
  }

  const loadMappedUrl = () => {
    AxiosInstance.get("/settings/general-setting/unique-data")
      .then(response => {
        const mappedUrls = response.data.uniqueData.filter(url => url.type == "URLMappedTo")
        setStates({ ...states, mappedUrls })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const updateSelectedDomain = e => {
    setSelectedDomain(e.target.value)

    let regex = /^(https?:\/\/)?([^\/]+)/
    let previewUrl = states.previewUrl.replace(regex, (match, p1) => {
      return (p1 ? p1 : "") + e.target.value
    })
    const ezLinkDetail = {
      compressUrl: true,
      publicBaseURLSelection: e.target.value,
    }
    setStates({ ...states, ezLinkDetail, previewUrl })
  }

  const validateAndSaveSlug = e => {
    if (
      customUrl !== "" &&
      customUrl !== null &&
      compressUrlInputRef.current.checked &&
      customUrl !== e.target.value
    ) {
      AxiosInstance.post(`/drive/save-slug/${states.driveId}`, {
        slug: e.target.value,
      })
        .then(response => {
          if (response.data.status == false) {
            setSlugErrorMessage(response.data.message)
          } else {
            setSlugErrorMessage("")
            setCustomUrl(e.target.value)
            const previewUrl = `${selectedDomain}/${username}/${e.target.value}`
            setStates({ ...states, previewUrl })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const renderCopyButton = () => {
    return (
      <button
        className="btnIOCopyCustomURL"
        data-bs-toggle="tooltip"
        data-bs-title={copyStatus}
        onClick={triggerCopyUrl}
      >
        <OverlayTrigger placement="top" overlay={<Tooltip>{copyStatus}</Tooltip>}>
          <i className="bi bi-files" />
        </OverlayTrigger>
      </button>
    )
  }

  return (
    <div className="col-lg-12 mb-3" id="installation_panel">
      <div className="accordion" id="installationPanelAccordion">
        <div className="accordion-item panel panel-beige">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#installation_container"
              aria-expanded="true"
              aria-controls="installation_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-code-slash"></i>
              </span>
              <span className="panel-heading-title">Installation Options Product {itemId}</span>
            </div>
          </div>
          <div
            id="installation_container"
            className="accordion-collapse collapse show"
            aria-labelledby="installation_container"
          >
            <div className="accordion-body">
              <strong>
                Here is your product URL{" "}
                <span data-bs-toggle="tooltip" title="Link your website, emails, etc. to this URL">
                  <i className="bi bi-question-circle-fill"></i>
                </span>
              </strong>

              <div className="customURLContainer py-5 text-center">
                {!isCustomized && (
                  <div className="IO_OriginalURL d-inline-block">
                    <a className="dashedLinked" href="#" onClick={doNothing}>
                      {states.previewUrl}
                    </a>
                    <a
                      href={states.previewUrl}
                      target="_blank"
                      className="btnIOPreviewCustomURLView IO_New_URL"
                      data-bs-toggle="tooltip"
                      data-bs-title="Preview"
                    >
                      <i className="bi bi-box-arrow-up-right"></i>
                    </a>
                    {renderCopyButton()}
                  </div>
                )}

                {isCustomized && (
                  <div className="IO_url_edit">
                    <div
                      className="alert alert-danger mb-3 display-none emptyWebsiteHashError"
                      style={emptyCustomUrlStyle}
                    >
                      <i className="bi bi-exclamation-triangle-fill"></i> Heads up! This will save as your
                      mapped-domain root view.
                    </div>

                    <div className="input-group mb-1">
                      <span className="input-group-text">https://</span>
                      <select
                        name="public_base_url_selection"
                        id="publicBaseURLSelection"
                        className="form-select"
                        value={changeHypertextProtocol(selectedDomain || defaultDomain)}
                        onChange={updateSelectedDomain}
                      >
                        <option
                          value={changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}/${username}`)}
                        >
                          {changeHypertextProtocol(`${process.env.GATSBY_PREVIEW_URL}/${username}`)}
                        </option>
                        {states.mappedUrls?.map((url, index) => {
                          return (
                            <option key={index} value={changeHypertextProtocol(url.value)}>
                              {changeHypertextProtocol(url.value)}
                            </option>
                          )
                        })}
                      </select>
                      <span className="input-group-text">/</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Change to anything you like. Must be unique.</Tooltip>}
                      >
                        <input
                          ref={customUrlRef}
                          type="text"
                          name="link_your_website_hash"
                          id="link_your_website_hash"
                          className="form-control"
                          placeholder="customurl"
                          value={tmpCustomUrl}
                          data-bs-toggle="tooltip"
                          data-bs-title="Change to anything you like. Must be unique."
                          onChange={updateCustomUrl}
                          onBlur={validateAndSaveSlug}
                        />
                      </OverlayTrigger>
                    </div>
                    <div>
                      <span style={{ color: "red" }} className="input-group d-flex justify-content-end">
                        {slugErrorMessage}
                      </span>
                    </div>
                    <a
                      href={states.previewUrl}
                      target="_blank"
                      className="btnIOPreviewCustomURLText IO_New_URL dashedLinked fs-5"
                    >
                      {states.previewUrl}
                    </a>
                    <a
                      href={states.previewUrl}
                      target="_blank"
                      className="btnIOPreviewCustomURLView IO_New_URL"
                      data-bs-toggle="tooltip"
                      data-bs-title="Preview"
                    >
                      <i className="bi bi-box-arrow-up-right"></i>
                    </a>
                    {renderCopyButton()}
                  </div>
                )}

                <div className="d-inline-block ms-2 compressUrlBox">
                  <div className="toggleSwitch">
                    <input
                      type="checkbox"
                      value="1"
                      name="compressUrl"
                      id="compressUrl"
                      checked={isCustomized}
                      onChange={updateCustomizationStatus}
                      ref={compressUrlInputRef}
                    />

                    <label>Custom URL?</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <ul className="IO_Action_Btns">
                    <li>
                      <a href="#" onClick={doNothing} className="btnIOShare">
                        <i className="bi bi-share-fill"></i> Share
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={doNothing}>
                        <i className="bi bi-wordpress"></i> Wordpress
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={doNothing} className="btnIOEmailCode">
                        <i className="bi bi-envelope-fill"></i> Email code
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={doNothing}>
                        <i className="bi bi-wrench-adjustable"></i> Order installation
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-12 pt-5 IOShareContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-share-fill"></i> Share
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="row">
                      <div className="col-lg-12 text-center shareAction colAction">
                        <ul>
                          <li className="black-bg">
                            <a
                              href="#"
                              onClick={doNothing}
                              className="btnGlobalURLQRCode"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Scan QR Code"
                            >
                              <i className="bi bi-qr-code"></i>
                            </a>
                          </li>
                          <li className="paper-plane-bg">
                            <a
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Email This Page"
                              href="mailto:?subject=You would like this&amp;body=Hello%0A%0AYou%20will%20like%20this%3A%0Ahttps://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride"
                              target="_blank"
                            >
                              <i className="bi bi-send-fill"></i>
                            </a>
                          </li>
                          <li className="fb-bg">
                            <a
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Share On Facebook"
                              href="https://www.facebook.com/sharer/sharer.php?u=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride"
                              target="_blank"
                            >
                              <i className="bi bi-facebook"></i>
                            </a>
                          </li>
                          <li className="twitter-bg">
                            <a
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Tweet This Page"
                              href="https://twitter.com/share?url=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride&amp;text=Hello%0A%0AYou%20will%20like%20this%3A%0A"
                              target="_blank"
                            >
                              <i className="bi bi-twitter-x"></i>
                            </a>
                          </li>
                          <li className="pinterest-bg">
                            <a
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Share This Page on Pinterest"
                              href="https://www.pinterest.com/pin/create/button/?url=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride&amp;media=https://swiftcrm.com/images/user_/"
                              target="_blank"
                            >
                              <i className="bi bi-pinterest"></i>
                            </a>
                          </li>
                          <li className="linkedin-bg">
                            <a
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Share This Page on LinkedIn"
                              href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride&amp;title=You%20will%20like%20this&amp;summary=Hello%0A%0AYou%20will%20like%20this%3A%0A%0A"
                              target="_blank"
                            >
                              <i className="bi bi-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Installation
