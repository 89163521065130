export const ESIGN_MODE_SOCIAL = "social"
export const ESIGN_MODE_HIGH_SPEED = "highspeed"
export const PDF_GENERATION_SEND_PDF = "sendPDF"
export const PDF_GENERATION_SEND_DATA = "sendData"
export const PRIVACY_NOT_SHARED = "notShared"
export const PRIVACY_PRIVATE = "Private"
export const PRIVACY_PAY_WALLED = "payWalled"
export const PRIVACY_PASSWORD_PROTECTED = "passwordProtected"
export const PRIVACY_UNLISTED = "Unlisted"
export const PRIVACY_PUBLIC = "Public"
export const WHO_CAN_MODIFY_JUST_ME = "JustMe"
export const WHO_CAN_MODIFY_THESE_USERS = "TheseUsers"
export const SIGNER_AUTHENTICATION_NO = "no"
export const SIGNER_AUTHENTICATION_YES = "yes"
export const COMMENTS_VISIBILITY_PRIVATE = "Private"
export const COMMENTS_VISIBILITY_PUBLIC = "Public"
export const ESIGN_MODE = [
  {
    value: ESIGN_MODE_SOCIAL,
    label: "Social Mode (Create user, allow greater interaction)",
    checked: true,
    toolTip:
      "Social mode will create a shared workroom with signer(s), best for high value docs and can email you a PDF. Example: New worker onboarding, sales proposals.",
    id: "esign_social_mode",
  },
  {
    value: ESIGN_MODE_HIGH_SPEED,
    label: "High Volume Petition / Waiver Mode (No PDF unless Escalated Manually)",
    checked: false,
    toolTip:
      "High Speed mode will just record the signature &amp; legal intent, but will not generate a PDF unless needed. Example: Liability waivers.",
    id: "esign_highspeed_mode",
  },
]

export const PDF_GENERATION = [
  {
    value: PDF_GENERATION_SEND_PDF,
    label: "Send me PDFs as people sign",
    checked: true,
    id: "pdf_generation_send_pdf",
  },
  {
    value: PDF_GENERATION_SEND_DATA,
    label: "Just send data, I’ll login to generate a PDF if needed for a specific user",
    checked: false,
    id: "pdf_generation_send_data",
  },
]

export const ALL_PRIVACY = [
  {
    value: PRIVACY_NOT_SHARED,
    label: "Me Only; not shared",
    icon: "bi bi-incognito",
    checked: true,
  },
  {
    value: PRIVACY_PRIVATE,
    label: "Invitation Only. User must login.",
    icon: "bi bi-lock-fill",
    checked: true,
  },
  {
    value: PRIVACY_PAY_WALLED,
    label: "Paywalled / Capture Wall",
    icon: "bi bi-person-fill-check",
    checked: true,
  },
  {
    value: PRIVACY_PASSWORD_PROTECTED,
    label: "Password Protected.",
    icon: "bi bi-key-fill",
    checked: false,
  },
  {
    value: PRIVACY_UNLISTED,
    label: "Hidden: Anyone with URL",
    icon: "bi bi-link-45deg",
    checked: false,
  },
  {
    value: PRIVACY_PUBLIC,
    label: "Public & Shown in Search",
    icon: "bi bi-globe",
    checked: false,
  },
]
export const WHO_CAN_MODIFY = [
  {
    value: WHO_CAN_MODIFY_JUST_ME,
    label: "Just me",
    icon: "bi bi-person-fill",
    checked: true,
  },
  {
    value: WHO_CAN_MODIFY_THESE_USERS,
    label: "These users:",
    icon: "bi bi-people-fill",
    checked: false,
  },
]
export const WHO_CAN_CLONE = [
  {
    value: "Nobody",
    label: "Nobody (Private)",
    icon: "bi bi-incognito",
    checked: true,
  },
  {
    value: "Anyone with the Password",
    label: "Anyone with the Password",
    icon: "bi bi-key-fill",
    checked: false,
  },
  {
    value: "Anyone with Payment",
    label: "Anyone with Payment",
    icon: "bi bi-currency-dollar",
    checked: false,
  },
  {
    value: "Anyone",
    label: "Anyone (Public Template)",
    icon: "bi bi-globe",
    checked: false,
  },
]

export const SIGNER_AUTHENTICATION = [
  {
    value: SIGNER_AUTHENTICATION_NO,
    label: "No. Keep it simple &amp; easy for the signer(s)",
    checked: true,
  },
  {
    value: SIGNER_AUTHENTICATION_YES,
    label: "Yes. Proof of identity is required",
    checked: false,
  },
]

export const COMMENTS_VISIBILITY = [
  {
    value: COMMENTS_VISIBILITY_PUBLIC,
    id: "comments_public",
    label: "Public, in a single discussion (Web Style)",
    checked: true,
  },
  {
    value: COMMENTS_VISIBILITY_PRIVATE,
    id: "comments_private",
    label: "Private individual discussions (Chat Style)",
    checked: false,
  },
]
