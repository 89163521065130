import React, { useState, useEffect, useRef } from "react"
import Layout from "../../Layout/layout"
import "../../../css/timeclock.css"
import ListTimeclock from "./listTimeClock"
import AxiosInstance from "../../../services/axiosInstance"
import ContactForm from "./contactForm"

const Timeclock = ({}) => {
  const [states, setStates] = useState({
    showContactForm: false,
    showContactSearchResult: false,
    searchTerm: "",
    currentPage: 1,
  })
  const [timeclocks, setTimeclocks] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [userSearchResult, setUserSearchResult] = useState([])
  const [selectedContact, setSelectedContact] = useState({})
  const [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif")
  const [contactSearchTerm, setContactSearchTerm] = useState("")

  const contactFormRef = useRef(null)

  const initialFormState = {
    totalMin: 0,
    timeType: "Minutes",
    description: "",
    quickAddName: "",
    id: null,
  }

  const initializeTimeclockForm = () => {
    setFormData(initialFormState)
    setSelectedContact({})
    setContactSearchTerm("")
    setStates(prevStates => ({ ...prevStates, showContactForm: false }))
  }

  const loadData = async page => {
    page = page ?? 1

    let queryString = ""
    if (page !== 1) {
      queryString += `?page=${page}`
    }

    if (states.searchTerm?.length > 0) {
      queryString += `${queryString.length > 0 ? "&" : "?"}search=${states.searchTerm}`
    }

    try {
      const response = await AxiosInstance.get(`/timeclock${queryString}`)
      if (response.status === 200) {
        setTimeclocks(response.data.items)
        setStates(prevStates => ({
          ...prevStates,
          pageCount: response.data.pages_count,
          totalItems: response.data.total_items,
          currentPage: page,
        }))

        setIsLoading(false)
        return
      }
      console.error("Unexpected response status: ", response.status)
    } catch (error) {
      console.error("Error when deleting timeclock: ", error)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const [formData, setFormData] = useState(initialFormState)

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const addTimeClock = async () => {
    if (states.showContactForm && !selectedContact.id) {
      await contactFormRef.current.saveContact(saveTimeClock)

      return
    }

    await saveTimeClock()
  }

  const saveTimeClock = async contact => {
    if (contact) {
      selectContact(contact)
    }
    const totalMin = formData.totalMin
    const timeType = formData.timeType
    const description = formData.description

    if (!description || !totalMin || (!selectedContact.id && !contact?.id)) {
      alert("Please fill out all required fields before submitting the form.")
      return false
    }

    const dateTimeIn = formData.id ? formData.dateTimeIn : new Date()

    let duration = parseInt(totalMin, 10)
    if (timeType === "Hours") {
      duration = duration * 60
    }

    const dateTimeOut = new Date(dateTimeIn.getTime() + duration * 60000)

    const taskDoneJson = description.split("\n").filter(task => task.trim() !== "")

    const formatDateTime = date => {
      const year = date.getUTCFullYear()
      const month = String(date.getUTCMonth() + 1).padStart(2, "0")
      const day = String(date.getUTCDate()).padStart(2, "0")
      const hours = String(date.getUTCHours()).padStart(2, "0")
      const minutes = String(date.getUTCMinutes()).padStart(2, "0")
      return `${year}-${month}-${day} ${hours}:${minutes}`
    }

    const payload = {
      status: "started",
      dateTimeIn: formatDateTime(dateTimeIn),
      dateTimeOut: formatDateTime(dateTimeOut),
      taskDoneJson,
      contact: contact?.id ?? selectedContact.id,
    }

    try {
      const response = await AxiosInstance.post(`/timeclock/${formData.id ?? ""}`, payload)
      if (response.status === 201 || response.status === 200) {
        loadData()
        initializeTimeclockForm()
        return
      }
      console.error("Unexpected response status: ", response.status)
    } catch (error) {
      console.error("Error when adding timeclock: ", error)
    }
  }

  const contactSearchData = e => {
    let searchUser = e.target.value
    setContactSearchTerm(searchUser)

    var requestParameter = {
      page: 1,
    }

    if (searchUser.length > 0) {
      requestParameter.search = searchUser
    }

    AxiosInstance.get(`/contact/`, {
      params: requestParameter,
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
      setStates({ ...states, showContactSearchResult: true })
    })
  }

  const showContactForm = e => {
    e.preventDefault()
    setStates({ ...states, showContactForm: true, showContactSearchResult: false })
    setContactSearchTerm("Create New User")
  }

  const selectContact = contact => {
    setSelectedContact(contact)
    setContactSearchTerm(contact?.name ?? "")
    setStates({ ...states, showContactForm: false, showContactSearchResult: false })
  }

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col">
            <div className="row topTimeLoginContainer">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <div className="row quickAddCol">
                  <div className="col-lg-12 text-center mb-2">
                    <strong>Quick Add</strong>
                  </div>
                  <div className="col-lg-12 mb-3 BC_Multiple_User">
                    <div className="row socialSearchModal">
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <div className="bc-wrapper">
                          <input
                            type="text"
                            name="quickAddName"
                            id="quick_add_name"
                            placeholder="Select Client"
                            className={`form-control${selectedContact.id ? " userSelectedInput" : ""}${
                              states.showContactForm ? " invisible_field" : ""
                            }`}
                            autoComplete="off"
                            value={contactSearchTerm}
                            onChange={e => setContactSearchTerm(e.target.value)}
                            onKeyUp={contactSearchData}
                            onClick={contactSearchData}
                          />

                          <div
                            className="bc-menu list-group"
                            style={{ display: states.showContactSearchResult ? "block" : "none" }}
                          >
                            {userSearchResult.map(contact => {
                              return (
                                <a
                                  key={`contact-${contact.id}`}
                                  href="#"
                                  className="list-group-item"
                                  data-id={contact.id}
                                  data-label={contact.name}
                                  onClick={e => {
                                    e.preventDefault()
                                    selectContact(contact)
                                  }}
                                >
                                  <img
                                    className="bc-user-avatar"
                                    src={`${process.env.GATSBY_WRANGLER_URL}/${
                                      contact.profilePic ? contact.profilePic : uploadedFilePath
                                    }`}
                                  />
                                  {contact.name}
                                </a>
                              )
                            })}

                            <a
                              href="#"
                              onClick={showContactForm}
                              className="list-group-item"
                              data-id="0"
                              data-label="Create New User"
                            >
                              <img
                                className="bc-user-avatar"
                                src={`${process.env.GATSBY_WRANGLER_URL}${uploadedFilePath}`}
                              />
                              Create New User
                            </a>
                          </div>

                          {states.showContactForm && (
                            <div className="createNewUserAvatar">
                              <i className="bi bi-person-fill-add"></i>
                            </div>
                          )}

                          {selectedContact.id && (
                            <img
                              className="selectedUserAvatar"
                              src={`${process.env.GATSBY_WRANGLER_URL}/${
                                selectedContact.profilePic ? selectedContact.profilePic : uploadedFilePath
                              }`}
                              alt={selectedContact.name}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-group">
                          <input
                            type="number"
                            name="totalMin"
                            className="form-control"
                            value={formData.totalMin}
                            onChange={handleInputChange}
                          />
                          <select
                            className="form-select"
                            name="timeType"
                            value={formData.timeType}
                            onChange={handleInputChange}
                          >
                            <option value="Hours">Hours</option>
                            <option value="Minutes">Minutes</option>
                          </select>
                        </div>
                      </div>
                      {states.showContactForm && (
                        <ContactForm ref={contactFormRef} callBack={saveTimeClock} />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <textarea
                      name="description"
                      cols="3"
                      rows="5"
                      placeholder="What did you work on?"
                      className="form-control"
                      value={formData.description}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="col-lg-12">
                    <button className="btn btn-primary" onClick={addTimeClock}>
                      <i className="bi bi-plus-lg"></i> {formData.id ? "Edit timeclock" : "Add to Timesheet"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0 d-none">
                <div className="recentActivity">
                  <div className="row">
                    <div className="col-lg-12 text-center mb-3">
                      <strong>Recent Activity</strong>
                    </div>
                    <div className="col-lg-12 recent_timeclock_activity text-center">
                      <div className="iconTextBoxContainer contactBox">
                        <div className="iconTextBox">
                          <span className="iconTextBoxIcon">
                            <img src="images/swift_cloud_flag.jpg" />
                          </span>
                          <a href="contact_detail.html" className="iconTextBoxText">
                            SwiftCloud
                          </a>
                        </div>
                      </div>{" "}
                      <div className="iconTextBoxContainer contactBox">
                        <div className="iconTextBox">
                          <span className="iconTextBoxIcon">
                            <img src="images/roger.jpg" />
                          </span>
                          <a href="contact_detail.html" className="iconTextBoxText">
                            Roger V.
                          </a>
                        </div>
                      </div>{" "}
                      <div className="iconTextBoxContainer contactBox">
                        <div className="iconTextBox">
                          <span className="iconTextBoxIcon">
                            <img src="images/avatar.gif" />
                          </span>
                          <a href="contact_detail.html" className="iconTextBoxText">
                            Bruce
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 BC_Multiple_User d-none">
                <div className="mb-2 text-center fw-bold">Login to Client Billing</div>
                <div className="mb-3">
                  <div className="bc-wrapper">
                    <input type="hidden" id="client_nameId" name="client_nameId" value="" />
                    <input
                      type="text"
                      name="client_name"
                      id="client_name"
                      placeholder="Select Client"
                      className="form-control"
                      autoComplete="off"
                    />
                    <div className="bc-menu list-group"></div>
                  </div>
                </div>
                <div className="mb-3 timeClockCounter d-none"></div>
                <div className="mt-2 addNewUserField display-none">
                  <div className="row socialSearchModal">
                    <div className="col-lg-9">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <div className="input-group">
                              <button
                                className="btn addContactUserType isHuman"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="Human"
                                aria-label="Human"
                              >
                                <i className="bi bi-person-fill"></i>
                              </button>
                              <input type="text" className="form-control" placeholder="Contact Name" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <button
                                  className="btn btn-default dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-phone-fill"></i> <span>Mobile</span>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-telephone-fill"></i> Main
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-phone-fill"></i> Mobile
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-telephone-fill"></i> Personal
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-telephone-fill"></i> Business
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-phone-fill"></i> iPhone
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-printer"></i> Fax
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <input
                                type="text"
                                className="form-control contact_phone_us"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 addContactUserTypeContactRow d-none">
                        <div className="input-group">
                          <div className="input-group-text">
                            <img src="images/no-image.jpg" />
                            <input
                              type="file"
                              accept="image/*"
                              className="contactPhotoFile"
                              style={{ visibility: "hidden", position: "absolute", left: "-10000px" }}
                            />
                            <a
                              href="#"
                              className="btnAddCompanyPhoto"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Contact Photo"
                              aria-label="Contact Photo"
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </a>
                          </div>
                          <input
                            type="text"
                            className="form-control CD_input_info"
                            placeholder="Contact Name"
                          />
                        </div>
                      </div>
                      <div className="mb-3 addContactUserTypeCompanyRow">
                        <div className="row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <div className="input-group">
                              <div className="input-group-text">
                                <img src="images/no-image.jpg" />
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="companyPhotoFile"
                                  style={{ visibility: "hidden", position: "absolute", left: "-10000px" }}
                                />
                                <a
                                  href="#"
                                  className="btnAddCompanyPhoto"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  data-bs-original-title="Company Photo"
                                  aria-label="Company Photo"
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </a>
                              </div>
                              <input
                                type="text"
                                className="form-control CD_input_info contact_company"
                                placeholder="Company"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <button
                                  className="btn btn-default dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-briefcase-fill"></i> <span>Home</span>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Mailing
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Billing
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Work
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Other
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <input type="text" className="form-control" placeholder="Job Title" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <button
                                  className="btn btn-default dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                  <span>Home</span>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-envelope-fill"></i> Primary
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-envelope-fill"></i> Personal
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-envelope-fill"></i> Work
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-envelope-fill"></i> Other
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <input
                                type="text"
                                className="form-control CD_input_info contact_email"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <button
                                  className="btn btn-default dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-house-door-fill"></i> <span>Home</span>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Mailing
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Billing
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Work
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="bi bi-house-door-fill"></i> Other
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <input type="text" className="form-control" placeholder="Address or city" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="addNewContactAvatar d-inline-block">
                        <img src="images/avatar.gif" alt="Contact avatar" className="contact_pic img-fluid" />
                        <a href="#" className="btnEditAvatar">
                          <span
                            className="d-block"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Contact avatar"
                            aria-label="Contact avatar"
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" id="btnLogin" className="btn btn-primary">
                  <i className="bi bi-clock"></i> Clock In
                </button>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                <ListTimeclock
                  states={states}
                  setStates={setStates}
                  timeclocks={timeclocks}
                  isLoading={isLoading}
                  loadData={loadData}
                  setFormData={setFormData}
                  selectContact={selectContact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Timeclock
