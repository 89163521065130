import React, { useRef, useState, useEffect } from "react"

import { getHumanPhoto } from "../../../../services/settingsHelper"
import { getLoginUserId } from "../../../../services/auth"
import AxiosInstance from "../../../../services/axiosInstance"

import "../settings.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const HumanPhotoRow = () => {
  const [profileUpload, setProfileUpload] = useState(null)
  const [profileImgAdd, setProfileImgAdd] = useState(null)
  const [uploadProfile, setUploadProfile] = useState(false)

  let profileImage = getHumanPhoto()
  if (!profileImage) {
    profileImage = "StaticImages/avatar.gif"
  }
  const handleProfileImage = e => {
    setProfileUpload(e.target.files[0])
    setUploadProfile(true)
  }
  const handleSubmit = () => {
    let formData = new FormData()
    formData.append("HumanPhoto", profileUpload)
    formData.append("user_id", getLoginUserId())
    formData.append("parent_id", "0")
    AxiosInstance.post(
      `${process.env.GATSBY_BACKEND_API_URL}/settings/personal-setting/upload-human-photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then(response => {
        localStorage.setItem("HumanPhoto", response.data.humanPhoto.value)
        setProfileImgAdd(response.data.url)
        document.getElementsByClassName("rounded-circle")[0].src = response.data.url
        inputFile.current.value = ""
      })
      .catch(function (error) {
        inputFile.current.value = ""
        if (error.response?.status === 400) {
          if (error.response.data?.message.length > 0) {
            alert(error.response.data?.message[0])
          } else {
            alert(error.response.data?.message?.HumanPhoto)
          }
        }
      })
  }
  const inputFile = useRef(null)
  useEffect(() => {
    if (uploadProfile) {
      setUploadProfile(false)
      handleSubmit()
    }
  })

  const openSelectImageBox = () => {
    inputFile.current.click()
  }

  return (
    <div className="row g-0">
      <div className="col p-3 position-relative">
        <div className="CD_Avatar">
          <div className="adbook-profile-pic">
            <img
              className="main_profile_pic img-fluid"
              src={`${process.env.GATSBY_WRANGLER_URL}${profileImage}`}
              alt=""
            />
            <button
              type="button"
              data-target="#global_image_tool_container_images"
              className="adbook-profile-pic-edit-btn"
              onClick={openSelectImageBox}
            >
              <OverlayTrigger placement="left" overlay={<Tooltip>Update your avatar</Tooltip>}>
                <span data-bs-toggle="tooltip" data-bs-title="Update your avatar">
                  <i className="bi bi-pencil-fill" />
                </span>
              </OverlayTrigger>
            </button>
            <input type="file" id="openSelectBox" ref={inputFile} onChange={handleProfileImage} hidden />
          </div>
        </div>
        <div className="CD_Info_Row position-relative w-75">
          <div className="row g-0">
            <div className="col">
              <div className="CD_Info_Row">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HumanPhotoRow
