import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Privacy from "./privacy"
import Tags from "../Doc/MoreOptions/tags"
import "../../../css/bootstrap-tagsinput.css"
import "./assetTypes.css"
import FeaturedImage from "../Doc/MoreOptions/featuredImage"
import SEODescription from "../Doc/MoreOptions/seoDescription"

const MoreOptions = ({
  type,
  tags,
  setTags,
  tempTags,
  setTempTags,
  docPrivacy,
  setDocPrivacy,
  privacyPassword,
  seoDescription,
  featureImg,
  setFeatureImg,
  audioFileType,
  setAudioFileType,
  previewUrl,
}) => {
  return (
    <div className="col-lg-12 mb-3" id="more_panel">
      <Accordion>
        <Accordion.Item eventKey="1" className="panel panel-grey">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-three-dots" />
            </span>
            <span className="panel-heading-title">More Options</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  {type === "pdf" && (
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-pencil-fill" /> e-Signature Overlay
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="toggleSwitch">
                          <input type="checkbox" value="1" name="eSignOverlay" id="eSignOverlay" />
                          <label>e-Signature Overlay</label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="docPrivacyContainer mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-lock-fill" /> Privacy
                      </strong>
                    </div>
                    <Privacy
                      docPrivacy={docPrivacy}
                      setDocPrivacy={setDocPrivacy}
                      privacyPassword={privacyPassword}
                      previewUrl={previewUrl}
                    />
                  </div>

                  <div className={`mb-3 docAdvSettingsContainer${type !== "audio" ? " d-none" : ""}`}>
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-music-note" /> Audio File Type
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="row row-cols-md-auto g-2 align-items-center">
                        <div className="col-12">This doc is a...</div>
                        <div className="col-12">
                          <select
                            name="audio_file_type"
                            className="form-select audioType"
                            value={audioFileType}
                            onChange={e => setAudioFileType(e.target.value)}
                          >
                            <option value="General Audio File">General Audio File</option>
                            <option value="Podcast Episode">Podcast Episode</option>
                            <option value="Voicemail (Incoming)">Voicemail (Incoming)</option>
                            <option value="Voicemail (Outgoing by you)">Voicemail (Outgoing by you)</option>
                            <option value="Phone Call Recording">Phone Call Recording</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-tags-fill" /> Tags
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-file-image" /> Featured Image
                      </strong>
                    </div>
                    <FeaturedImage featureImg={featureImg} setFeatureImg={setFeatureImg} />
                  </div>

                  <SEODescription seoDescription={seoDescription} />
                  <div className="col-lg-12">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-chat-fill" /> Comments
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      Comments are
                      <div className="toggleSwitch">
                        <input type="checkbox" value="1" name="asset_comments" id="asset_comments" />
                        <label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default MoreOptions
